import Clock from "@/pages/App/pages/Schedule/pages/Pomodoro/comopnents/Clock";
import {Formik, useFormik} from "formik";
import PomodoroPeriodCard from "@/pages/App/pages/Schedule/pages/Pomodoro/comopnents/PomodoroPeriodCard";
import {FaFire} from "react-icons/fa6";
import {BsFillCupHotFill} from "react-icons/bs";
import FormSubmitButton from "@/components/forms/core/FormSubmitButton";
import {usePomodoro} from "@/context/ClockContext";
import PomodoroControls from "@/pages/App/pages/Schedule/pages/Pomodoro/comopnents/PomodoroControls";

export default function Pomodoro() {

    const { startPomodoro, values, skip, timeLeft, toggleRunning, hasStarted, reset, status} = usePomodoro()

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                ...values
            }} onSubmit={(values, helpers) => {
            if(!hasStarted) startPomodoro(values)
            helpers.setSubmitting(false)
        }}>
            {
                ({values}) =>(
                    <div className="w-full flex flex-col gap-3 mx-auto max-w-[600px] mt-10">
                        <Clock time={hasStarted ? timeLeft : (values.times?.[values.status] * 60)}></Clock>
                        <div className="w-full flex gap-3 flex-col lg:flex-row box-border">
                            <PomodoroPeriodCard
                                icon={<FaFire size={22}/>}
                                label="Concentración"
                                name="times.focus"
                                color="red"
                                optionName="status"
                                option="focus"
                                disabled={hasStarted}
                            >
                            </PomodoroPeriodCard>
                            <PomodoroPeriodCard
                                icon={<BsFillCupHotFill size={22}/>}
                                label="Descanso"
                                name="times.rest"
                                color="blue"
                                optionName="status"
                                option="rest"
                                disabled={hasStarted}
                            >
                            </PomodoroPeriodCard>
                        </div>
                        {
                            !hasStarted && <FormSubmitButton className="!rounded-lg">¡Empezar pomodoro!</FormSubmitButton>
                        }
                        {
                            hasStarted && <PomodoroControls></PomodoroControls>
                        }
                    </div>
                )
            }
        </Formik>
    )
}