import { useState } from 'react';
import TaskCard from './components/TaskCard';
import TaskView from './components/TasksView';
import { format, addDays, subDays, eachDayOfInterval } from 'date-fns';
import { es } from 'date-fns/locale';
import {  useTasks } from '@/api/tasks/useTasks';
import FetchLayout from '@/components/layout/FetchLayout';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import Button from '@/components/ui/Button';
import RestDaysDialog from './components/RestDaysDialog';
import TaskCreateForm from "@/pages/App/pages/Schedule/pages/Tasks/components/TaskCreateForm";
import { useExams } from '@/api/exams/useExams';


export default function Tasks() {
    const {data: tasks, status} = useTasks();
    const [startDate, setStartDate] = useState(new Date());
    const [isRestDaysDialogOpen, setIsRestDaysDialogOpen] = useState<boolean>(false)
    const [task, setTask] = useState<boolean>(false)
    const endDate = addDays(startDate, 6);
    const { data: exams, status: ExamStatus } = useExams();
    const handlePreviousWeek = () => {
        setStartDate(subDays(startDate, 7));
    }

    const handleNextWeek = () => {
        setStartDate(addDays(startDate, 7));
    }

    const getTasksOfDate = (date : Date) => {
        return tasks?.[format(date, "yyyy-MM-dd")] || []
    }

    return (
        <>
        <div className="flex flex-col gap-3">
            <div className='flex flex-col md:flex-row justify-between gap-3 '>
                <div className="flex w-full md:max-w-80 box-border flex-row items-center justify-between h-10 bg-gray-100 text-gray-800 rounded-xl overflow-hidden ">
                    <div className="h-full w-fit px-2 hover:bg-gray-200 flex justify-center items-center cursor-pointer" onClick={handlePreviousWeek}>
                        <BiChevronLeft  />
                    </div> 
                    <h4 style={{ margin: '0 5px'}}>
                        {format(startDate, 'dd MMMM', { locale: es })} - {format(endDate, 'dd MMMM', { locale: es })}
                    </h4>
                    <div className="h-full w-fit px-2 hover:bg-gray-200 flex justify-center items-center cursor-pointer" onClick={handleNextWeek} >
                        <BiChevronRight />
                    </div> 
                </div>
                <div className="flex gap-3 w-full md:max-w-[300px]">
                    <Button className='bg-gray-100 hover:bg-gray-200 !text-text-color w-full' onClick={() => setIsRestDaysDialogOpen(true)}>
                        Ajustes
                    </Button>
                    <Button className='bg-brand_secondary-500 hover:text-brand_secondary-700 !text-white w-full' onClick={() => setTask(true)}>
                        Crear tarea
                    </Button>
                </div>
            </div>
            <FetchLayout status={status} className="flex gap-4 flex-col">
                {eachDayOfInterval({start: startDate, end: endDate}).map((date) => (
                    <TaskView 
                        key={date.toString()}
                        date={date} 
                        numberOfTasks={getTasksOfDate(date)?.length || 0} 
                        empty={getTasksOfDate(date)?.length === 0}
                        exams={exams?.filter(exam => format(new Date(exam.fecha_fin), "yyyy-MM-dd") === format(date, "yyyy-MM-dd"))}
                    >
                        <div className="grid grid-cols-1 gap-3 mt-0 mb-2">
                            {getTasksOfDate(date)?.map((task, i) => (
                                <div key={i + date.toString()} className="w-full max-w-full">
                                <TaskCard 
                                    key={i+date.toString()}
                                    task={task}
                                    isToday={date.toDateString() === new Date().toDateString()}
                                />
                                </div>
                            ))}
                        </div>
                    </TaskView>
                ))}
            </FetchLayout>
        </div>
            <RestDaysDialog open={isRestDaysDialogOpen} onClose={() => setIsRestDaysDialogOpen(false)} />
            {task && <TaskCreateForm isOpen={task} setIsOpen={setTask} />}
        </>
    )
}
