import { Exam, Flashcard } from '@/types/types'
import { useEffect, useState } from 'react'
import Icon from './icons/Icon'
import ExamPercentage from '@/components/ui/ExamPercentage'
import { Card } from '@/types/db/card'

interface ExamCardProps {
    exam: Exam | null
    seeExam: (
        datos: {
            numFlashcardsTotal: number,
            numFlashcardsStudied: number,
            numFlashcardsToday: number,
            numFlashcardsLearning: number,
            arrayEstudio: Card[],
            arrayRepaso: Card[]
        }
      ) => void
      estudio: (tarjetas: Card[], modo: number, time:number, examen: Exam) => void 
}

export default function ExamCard ({exam, seeExam, estudio}:ExamCardProps) {

    const [loading, setLoading] = useState(true)
	const [numFlashcardsTotal,setNumFlashcardsTotal] = useState<number>(0)
    const [numFlashcardsStudied,setNumFlashcardsStudied] = useState<number>(0)
    const [numFlashcardsToday,setNumFlashcardsToday] = useState<number>(0)
    const [numFlashcardsLearning,setNumFlashcardsLearning] = useState<number>(0)
    const [arrayEstudio, setArrayEstudio] = useState<Card[]>([])
    const [arrayRepaso, setArrayRepaso] = useState<Card[]>([])

    useEffect(() => {
        const obtenerDatos = async () => {
            if (exam) {
                const mazosQuery = exam.id_mazos.join(',');
                const idQuery = exam.id
                const dateQuery= exam.fecha_fin
                const fetchPromises = await fetch( 
                    `${import.meta.env.VITE_API_URL}/card/get_for_exams?id=${idQuery}&mazos=${mazosQuery}&fecha=${dateQuery}`, 
                      {
                        method: 'GET',
                        headers: { 'Content-Type': 'application/json', },
                        credentials: 'include'
                      }
                    )
                try {
                    let mazosData = await fetchPromises.json();
                    //console.log(mazosData)
                    //console.log(mazosData.ArrayEstudio)
                    setNumFlashcardsTotal(Number(mazosData.totalLength) || 0);
                    setNumFlashcardsStudied(Number(mazosData.totalLearned) || 0);
                    setNumFlashcardsToday(Number(mazosData.totalLengthEst) || 0);
                    setNumFlashcardsLearning(Number(mazosData.totalLearning) || 0);
                    setArrayEstudio(mazosData.ArrayEstudio || []);
                    setArrayRepaso(mazosData.ArrayRepaso || []);
                    if (Number(mazosData.totalFinalRevision) > 0) {
                        setNumFlashcardsToday(Number(mazosData.totalFinalRevision) || 0);
                    }
                    setLoading(false)
                } catch (error) {
                    console.log(fetchPromises)
                    console.error('Error obteniendo datos de examen:', error);
                }
            }
        };
        obtenerDatos();
    }, []);

    const truncatedPercentage = 100 * numFlashcardsStudied / numFlashcardsTotal;
    let backgroundColor;
    if (truncatedPercentage < 30) {
        backgroundColor = 'var(--color-delete)';
    } else if (truncatedPercentage >= 30 && truncatedPercentage < 70) {
        backgroundColor = '#F7D26E';
    } else {
        backgroundColor = 'var(--color-secondary)';
    }


    const estudioDiario = () => {
        if(exam){
            if(numFlashcardsToday > 0 && arrayEstudio.length==0){ 
                let numero=numFlashcardsToday
                let arrayEstudio:Card[] = []
                for(let i=0; i<arrayRepaso.length; i++){
                    if(numero==0){
                        break
                    }
                    if(arrayRepaso[i].final_bool==false){
                        arrayEstudio.push(arrayRepaso[i])
                        numero--
                    }
                }
                estudio(arrayEstudio,2,0,exam)
            } else if(numFlashcardsToday > 0 && arrayEstudio.length>0){
                estudio(arrayEstudio,1,0,exam)
            }
        }
    }


    return (

        <div
        className={`w-[300px] sm:w-[340px] h-[130px] rounded-[var(--border-radius)] bg-[var(--color-background)] p-4 sm:p-5 flex flex-row justify-between items-start overflow-hidden relative transition-all duration-300 ease-in-out border border-transparent cursor-pointer`}
        style={{
            transition: 'border-color 0.3s ease-in-out, background-color 0.3s ease-in-out, all 300ms ease', // Añadido para la transición suave
        }}
        onMouseEnter={(e) => {
            e.currentTarget.style.transform = 'scale(1.05)';
        }}
        onMouseLeave={(e) => {
            e.currentTarget.style.transform = 'scale(1)';
        }}
        onClick={() => {
            if (!loading) {
                const datos = {
                    numFlashcardsTotal,
                    numFlashcardsStudied,
                    numFlashcardsToday,
                    numFlashcardsLearning,
                    arrayEstudio: arrayEstudio,
                    arrayRepaso: arrayRepaso
                }
                // Llama a seeExam pasando el objeto datos
                seeExam(datos)
            }
        }}
    >
            {!loading && <div style={{
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '8px',
                backgroundColor: backgroundColor
            }}></div>}
            <div style={{ width: '70%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                <h3 style={{ color: '#000000', margin: 0, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%' }}>
                    {exam?.name}
                </h3>                
                <p style={{ fontSize: '14px', color: '#5F5F5F', margin: 0 }}>{exam?.fecha_fin?.toString()}</p>
                <hr style={{ width: '100%', marginLeft: 0 }} />
                {!loading && 
                <>
                    <p style={{ fontSize: '14px', color: '#5F5F5F', margin: 0 }}>
                        <span style={{ fontWeight: 'bold' }}>{exam?.id_mazos.length}</span> Temas
                    </p><p style={{ fontSize: '14px', color: '#5F5F5F', margin: 0 }}>
                        <span style={{ fontWeight: 'bold' }}>{numFlashcardsTotal - numFlashcardsStudied}</span>/{numFlashcardsTotal} Tarjetas restantes
                    </p><p style={{ fontSize: '14px', color: '#5F5F5F', margin: 0 }}>
                        <span style={{ fontWeight: 'bold' }}>{numFlashcardsToday}</span> Tarjetas para repasar hoy
                    </p>
                </>
                }
            </div>
            <div style={{ width: '30%',height:'100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                <ExamPercentage percentage={100*numFlashcardsStudied/numFlashcardsTotal} loadingData={loading}/>

                <div style={{
                    color: '#fff',
                    padding: '10px 9px 10px 11px',
                    width: '15px',
                    height: '15px',
                    borderRadius: '50%',
                    backgroundColor: numFlashcardsToday > 0 ? 'var(--color-secondary)' : 'lightgray',
                    borderColor: 'var(--color-background)',
                    cursor: 'pointer',
                    transition: 'all 300ms ease', // Añadido para la transición suave
                    alignSelf: 'flex-end',
                    transform: 'scale(1)', // Estado inicial
                }}
                onMouseEnter={(e) => {
                    if (numFlashcardsToday > 0) {
                    e.currentTarget.style.transform = 'scale(1.1)';
                    }
                }} // Crece al pasar el ratón si no está deshabilitado
                onMouseLeave={(e) => {
                    if (numFlashcardsToday > 0) {
                    e.currentTarget.style.transform = 'scale(1)';
                    }
                }} // Vuelve al tamaño original al quitar el ratón si no está deshabilitado
                onClick={() => estudioDiario()}
                >
                    <Icon iconName='play' size='15'/>
                </div>
            </div>
        </div>
    )
}
