import Button, {ButtonProps} from "@/components/ui/Button";
import Spinner from "@/components/Spinner";
import {useFormikContext} from "formik";
import ConfirmationDialog, {ConfirmationDialogProps} from "@/components/ConfirmationDialog";
import {useState} from "react";
import {tv} from "tailwind-variants";
import {InputOptions} from "@/components/forms/core/types";

interface SubmitButtonProps extends ButtonProps, InputOptions {
    disabled?: boolean,
    icon : React.ReactNode,
    confirmationDialog?: {
        title: string,
        description : string
    },
    dialog?: boolean,

}

const styles = tv({
    base: "flex justify-center items-center text-lg  !m-0 !rounded-lg",
    variants: {
        size: {
            "xs" : "!h-[25px] !w-[25px] !p-[0.3rem]",
            "sm" : "!h-[29px] !w-[29px] !p-[0.38rem]",
            "md": "!h-[33px] !w-[33px] !p-[0.5rem]",
            "lg":  "!h-[37px] !w-[37px] !p-[0.5rem]"
        },
        variant: {
            "primary": "!bg-gray-100 !text-gray-500 hover:!bg-gray-200",
            "secondary": "!bg-white !text-gray-500 hover:!bg-gray-100",
            "brand_primary":"!bg-brand_primary-500 !text-white hover:!bg-brand_primary-700",
            "brand_secondary":"!bg-brand_secondary-500 !text-white hover:!bg-brand_secondary-700",
        }
    }
})

const spinnerStyles = tv({
    variants: {
        variant: {
            "primary": "!fill-brand_secondary-500 !text-gray-300",
            "secondary": "!fill-brand_secondary-500",
            "brand_primary":"!fill-white",
            "brand_secondary":"!fill-white",
        }
    }
})

export function FormIconButton({disabled, icon, confirmationDialog, size="md", variant="primary", ...props} : SubmitButtonProps) {
    const {submitForm, isSubmitting, errors} = useFormikContext();
    const hasErrors = errors && Object.keys(errors).length > 0;
    const [openDialog, setOpenDialog] = useState(false);

    return (
        <>
            <input type="submit" hidden/>
            <Button
                onClick={() => {
                    if(!confirmationDialog) {
                        submitForm()
                        return
                    }
                    setOpenDialog(true)
                }}
                type="submit"
                disabled={hasErrors || isSubmitting}
                className={styles({size, variant})}
            >
                { isSubmitting && !hasErrors &&
                    <Spinner size={18} color="white" bgColor="rgba(1, 1, 1, 0.2)" className={spinnerStyles({ variant})}></Spinner>
                }
                {
                    !isSubmitting && icon
                }
            </Button>
            {
                confirmationDialog && openDialog && (
                    <ConfirmationDialog
                        {...confirmationDialog}
                        open={openDialog}
                        onClose={() => setOpenDialog(false)}
                        onConfirm={submitForm}
                        isPending={isSubmitting}
                    >
                    </ConfirmationDialog>
                )
            }

        </>
    );
}