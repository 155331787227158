import FormInput from '@/components/forms/core/FormInput'
import FormSelect from '@/components/forms/core/FormSelect'
import FormTextarea from '@/components/forms/core/FormTextarea'
import FormCheckbox from '@/components/forms/core/FormCheckbox'
import FormDatalist from '@/components/forms/core/FormDatalist'
import { EducationType } from '@/types/db/user'
import locationsData from '@/data/poblaciones.json'
import degreesData from '@/data/gradosUniversitarios.json'
import universitiesData from '@/data/universidades.json'
import { useFormikContext } from 'formik'

const educationOptions = [
    { value: 'degree', label: 'Grado' },
    { value: 'vocationalTraining', label: 'Formación Profesional' },
    { value: 'civilServiceExam', label: 'Oposición' },
    { value: 'others', label: 'Otros' },
]

export default function RegisterSecondForm() {
    const { values } = useFormikContext<{ education : EducationType }>()

    const fetchLocation = async (query: string) => { 
        return locationsData.filter((option) => option.label.toLowerCase().includes(query.toLowerCase())).map((option) => option.label)
    }
    const fetchDegree = async (query: string) => { 
        return degreesData.filter((option) => option.label.toLowerCase().includes(query.toLowerCase())).map((option) => option.label)
    }
    const fetchUniversity = async (query: string) => { 
        return universitiesData.filter((option) => option.label.toLowerCase().includes(query.toLowerCase())).map((option) => option.label)
    }

    return (
        <div className="grid gap-4">
            <div className='w-full grid md:grid-cols-2 gap-4'>
                <FormDatalist
                    placeholder='Localidad...' label='Localidad' name='location'
                    fetchData={fetchLocation} debounceTime={300}
                />
                <FormSelect label='Tipo de Estudios' name='education' options={educationOptions}/>
            </div>
            <div className='w-full grid md:grid-cols-2 gap-4'>
                { values.education === 'degree' ? 
                    <>
                        <FormDatalist 
                            placeholder='Tu Universidad...' label='Universidad' name='institution'
                            fetchData={fetchUniversity} debounceTime={100}
                        />
                        <FormDatalist 
                            placeholder='Tu Carrera...' label='Grado' name='qualification'
                            fetchData={fetchDegree} debounceTime={100}
                        />
                    </> :
                    <>
                        <FormInput
                            placeholder="Centro de estudios..."
                            label='Centro de Estudios'name='institution' 
                        />
                        <FormInput
                            placeholder="Tu titulación..."
                            label='Titulación' name='qualification' 
                        />
                    </>
                }
            </div>
            <FormTextarea
                placeholder="Pon tus objetivos..."
                label='Objetivo' name='goal' className='h-32' />
            <FormCheckbox name="termsAccepted">
                <p>
                    He leído y acepto los <a href="/terminos" target="_blank" rel="noopener noreferrer" className="font-semibold cursor-pointer text-brand_primary-500 hover:text-brand_primary-900">términos y condiciones</a>
                </p>
            </FormCheckbox>
        </div>
    )
}
