import { Dialog as ArkDialog, DialogRootProps, Portal } from '@ark-ui/react';
import { IoClose } from 'react-icons/io5';

/**
 * For DialogRootProps @see https://ark-ui.com/react/docs/components/dialog#api-reference
 */
export interface DialogProps extends DialogRootProps {
  open?: boolean;
  title?: string;
  description?: string;
  className?: string;
  closeIcon?: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
}

const Dialog = ({ 
  open,
  title,
  description,
  className, 
  closeIcon=true,
  onClose,
  children,
  ...props
}: DialogProps) => {
  return (
    <ArkDialog.Root open={open} onOpenChange={(e) => !e.open && onClose && onClose()} {...props}>
      <Portal>
        <ArkDialog.Backdrop className='fixed inset-0 bg-gray-500 bg-opacity-15 backdrop-blur-sm' />
        <ArkDialog.Positioner className='fixed inset-0 flex items-center justify-center'>
          <ArkDialog.Content className={`relative bg-white rounded-3xl p-7 w-full max-w-[500px] m-2 ${className}`}>
            { title &&
              <ArkDialog.Title className='text-lg font-semibold'>{title}</ArkDialog.Title>
            }
            { description &&
              <ArkDialog.Description className='mt-2'>{description}</ArkDialog.Description>
            }
            { closeIcon && 
              <ArkDialog.CloseTrigger className='absolute top-3 right-3'>
                <IoClose className='w-6 h-6' />
              </ArkDialog.CloseTrigger>
            }
            {children}
          </ArkDialog.Content>
        </ArkDialog.Positioner>
      </Portal>
    </ArkDialog.Root>
  );
}

export default Dialog;
