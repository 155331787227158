import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { apiClient } from '@/api/client';
import { AxiosError } from 'axios';
import { Subscription } from '@/types/types';

type SubscriptionResponseData = Subscription | null;

export const getSubscriptionData = async (): Promise<SubscriptionResponseData> => {
    const response = await apiClient.get<SubscriptionResponseData>('/user/subscription');
    return response.data;
};

export const useSubscription = (options?: UseQueryOptions<SubscriptionResponseData, AxiosError>) => {
    return useQuery<SubscriptionResponseData, AxiosError>({
        queryKey: ['subscription'],
        queryFn: getSubscriptionData,
        ...options,
    });
};
