import { useState, useEffect } from 'react';
import * as pdfjsLib from 'pdfjs-dist';

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.mjs`;

export const usePdf = (file: File) => {
    const [pdfDoc, setPdfDoc] = useState<pdfjsLib.PDFDocumentProxy | null>(null);
    const [numPages, setNumPages] = useState<number | null>(null);
    const [fileUrl, setFileUrl] = useState<string>('');
    const [status, setStatus] = useState<'pending' | 'success' | 'failed'>('pending');
    const [isFetching, setIsFetching] = useState<boolean>(false);

    useEffect(() => {
        const loadPdf = async () => {
            try {
                setIsFetching(true);
                setStatus('pending');
                const arrayBuffer = await file.arrayBuffer();
                const objectUrl = URL.createObjectURL(new Blob([arrayBuffer], { type: 'application/pdf' }));
                setFileUrl(objectUrl);
                const pdf = await pdfjsLib.getDocument(objectUrl).promise;
                setPdfDoc(pdf);
                setNumPages(pdf.numPages);
                setStatus('success');
            } catch (error) {
                setStatus('failed');
            } finally {
                setIsFetching(false);
            }
        };
        loadPdf();
        return () => {
            if (fileUrl) {
                URL.revokeObjectURL(fileUrl);
            }
        };
    }, [file]);

    return { pdfDoc, numPages, fileUrl, status, isFetching };
};
