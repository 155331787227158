import React, {useState} from 'react'
import {useAsyncDebouncedCallback} from '@/hooks/useAsyncDebouncedCallback'
import FormInput from './FormInput'

interface DatalistProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label: string
    name: string
    fetchData: (query: string) => Promise<string[]>
    startLength?: number // Longitud mínima de la query para empezar a buscar
    debounceTime?: number // Tiempo de espera antes de realizar la búsqueda
}

export default function FormDatalist({
    label, 
    name, 
    fetchData, 
    startLength = 2, 
    debounceTime = 500, 
    ...props 
} : DatalistProps) {
    const [options, setOptions] = useState<string[]>([])
    const [query, setQuery] = useState<string>('')
    const datalistId = `${name}-list`

    const debouncedFetchData = useAsyncDebouncedCallback(async (value: string) => {
        const data = await fetchData(value)
        setOptions(data)
    }, debounceTime)

    const handleInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setQuery(value)
        if (value.length > startLength) {
          debouncedFetchData(value)
        } else {
          setOptions([])
        }
    }

    return (
        <FormInput 
            name={name}
            label={label} 
            list={datalistId}
            value={query}
            onChange={handleInputChange}
            {...props} 
            datalist={
                <datalist id={datalistId}>
                    {options.map((option) => (
                        <option key={option} value={option} />
                    ))}
                </datalist>
            }
        />
    )
}
