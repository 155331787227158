import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { apiClient } from '@/api/client';
import { AxiosError } from 'axios';
import { Subject } from '@/types/db/subject';

type SubjectsResponseData = Subject[];

export const getSubjectsData = async (): Promise<SubjectsResponseData> => {
    const response = await apiClient.get<SubjectsResponseData>('/folder/get_from_user');
    return response.data;
};

export const useSubjects = (options?: UseQueryOptions<SubjectsResponseData, AxiosError>) => {
    return useQuery<SubjectsResponseData, AxiosError>({
        queryKey: ['subjects'],
        queryFn: getSubjectsData,
        ...options,
    });
};
