import { emailValidation, passwordValidation, confirmPasswordValidation } from '../../validations'
import { existUserWithEmail } from '@/api/auth/register/validators'
import { debounce } from '@/api/utils'
import * as Yup from 'yup'

const debouncedCheckEmail = debounce(existUserWithEmail, 500);

export const registerSchema = Yup.object({
  password: passwordValidation,
  confirmPassword: confirmPasswordValidation,
  email: emailValidation
  .test('emial-exists', 'Este correo ya está registrado', async (value) => {
    if (!value) return true
    return !(await debouncedCheckEmail(value));
  }),
})