import ConfirmationDialog from "@/components/ConfirmationDialog";
import {useDeleteCard} from "@/api/cards/useDeleteCard";
import {queryClient} from "@/api/client";
import {useToast} from "@/components/ui/ToastContext";
import {CardType} from "@/types/db/card";
import {useParams} from "react-router-dom";

interface DeleteCardDialogProps {
    cardId: string,
    cardType: CardType,
    open: boolean,
    onClose: () => void
}
    
export default function DeleteCardDialog({cardId, cardType, open, onClose}: DeleteCardDialogProps) {
    const {createToast} = useToast();
    const {deck_id, subject_id} = useParams<{ deck_id: string, subject_id: string }>();

    const {mutateAsync, isPending} = useDeleteCard({
        onSuccess: async () => {
            await queryClient.refetchQueries({queryKey: ['cards', deck_id]})
            await queryClient.refetchQueries({queryKey: ['decks', subject_id]})
            await queryClient.refetchQueries({queryKey: ['deck', deck_id]})

            onClose()
            createToast({
                title: 'Carta eliminada', 
                description: 'La carta ha sido eliminada correctamente', 
                type: 'success'
            })
        },
        onError: () => {
            onClose()
            createToast({
                title: 'Error', 
                description: 'Ha ocurrido un error al intentar eliminar la carta', 
                type: 'error'}
            )
        }
    });

    if (open) return (
        <ConfirmationDialog
            open={true}
            onClose={onClose}
            title={'Eliminar Carta'}
            description={'¿Estás seguro de que deseas eliminar esta carta?'}
            confirmText='Eliminar'
            onConfirm={async () => await mutateAsync({
                cardType: cardType, 
                cardId: cardId
            })}
            isPending={isPending}
        />
    )
}