import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {apiClient} from '@/api/client'
import {AxiosResponse} from 'axios'
import {CardInit, CardType} from '@/types/db/card'

export type ModifyCardRequestData = Omit<CardInit, 'mazo_id'> & {
  id: string
  type: CardType
  oldType: CardType
}

interface ModifyCardResponseData {
    message : string
}

interface ModifyCardErrorData{
    error: string
}

export const modifyCardRequest = async (modifyCardData: ModifyCardRequestData) => {
    try{
        const response = await apiClient.patch<ModifyCardResponseData, AxiosResponse<ModifyCardResponseData, ModifyCardErrorData>>(
            '/card/edit', {perteneceAExamen: 'no', ...modifyCardData} // Cambiar perteneceAExamen
        )
        return response.data
    } catch(error : any){
        throw error.response.data
    }
}

export const useModifyCard = (options: UseMutationOptions<ModifyCardResponseData, ModifyCardErrorData, ModifyCardRequestData, null>) =>{
    return useMutation<ModifyCardResponseData, ModifyCardErrorData, ModifyCardRequestData, null>({
        mutationFn: modifyCardRequest,
        ...options
    })
}