import {useNavigate, useParams} from "react-router-dom";
import {BiArrowBack, BiSearch, BiSolidHand} from "react-icons/bi";
import QuestionPreviewCard from "@/pages/App/pages/Library/pages/DetailedDeck/components/QuestionPreviewCard";
import {useDeck} from "@/api/decks/useDeck";
import {useCards} from "@/api/cards/useCards";
import NoCardsBanner from "@/pages/App/pages/Library/pages/DetailedDeck/components/NoCardsBanner";
import React, {useState} from "react";
import PageLayout from "@/components/layout/PageLayout";
import AddFlashcardMenu from "@/pages/App/pages/Library/pages/DetailedDeck/components/AddFlashcardMenu";
import FetchLayout from "@/components/layout/FetchLayout";
import { Card, FlashCard, OcultarCard, TestCard } from "@/types/db/card";
import { FloatingPlayButton } from "@/components/ui/FloatingPlayButton";
import { useStudy } from "@/context/StudyContext";

function DetailedDeck() {
    const { deck_id } = useParams<{ deck_id: string }>();
    const { data: deck, status : deckStatus} = useDeck(deck_id || "");
    const navigate = useNavigate();
    const { setData } = useStudy();
    const { data: cards, status} = useCards(deck_id || "");
    const [nameFilter, setNameFilter] = useState("")
    const filteredCards = cards?.filter((card) => {
        if (card.type === 'flashcard') {
            return (card as FlashCard).pregunta?.toLowerCase().includes(nameFilter.toLowerCase()) ||
                (card as FlashCard).respuesta?.toLowerCase().includes(nameFilter.toLowerCase());
        } else if (card.type === 'ocultar') {
            return (card as OcultarCard).text?.toLowerCase().includes(nameFilter.toLowerCase());
        } else if (card.type === 'test') {
            return (card as TestCard).pregunta?.toLowerCase().includes(nameFilter.toLowerCase()) || 
            (card as TestCard).respuestas?.some((x) => x.toLowerCase().includes(nameFilter.toLowerCase()))
        }
        return false;
    })

    const CallStudy = () => {//mas crimenes de pirulo, habra juicios muy pronto
        let numEstudiar=0
        if(deck){
            if (deck.modoEstudio == 4) {
                numEstudiar=(deck.Array.length)
            } else if (deck.modoEstudio == 5) {
                numEstudiar=(0)
            } else if (deck.modoEstudio == 2) {
                let tarjetas=0
                let numero=deck.numEstudiar
                for(let i=0; i<deck.Array.length; i++){
                if(deck.Array[i].final_bool==false && numero>0){
                    tarjetas++
                    numero--
                }
                }
                numEstudiar=(Math.min(deck.numEstudiar,tarjetas))
            } else if (deck.modoEstudio == 1) {
                numEstudiar=(deck.numEstudiar)
            }

            const modoEstudiar = deck.modoEstudio
            const flashcards = deck.Array.map((card: Card) => ({...card}));
            if(modoEstudiar == 4 || modoEstudiar == 5){
                setData({ cartas: flashcards, modo: modoEstudiar, time: null, onClickReturn: () => {} })
            } else if(modoEstudiar == 2){
                let tarjetas=[]
                let numero=numEstudiar
                for(let i=0; i<flashcards.length; i++){
                    if(flashcards[i].final_bool==false && numero>0){
                        tarjetas.push(flashcards[i])
                        numero--
                    }
                }
                setData({ cartas: tarjetas, modo: modoEstudiar, time: null, onClickReturn: () => {} })
            } else if(modoEstudiar == 1){
                let tarjetas: any[]=[]
                function obtenerFechaActual() {
                const fecha = new Date();
                
                const año = fecha.getFullYear();
                const mes = ('0' + (fecha.getMonth() + 1)).slice(-2); // getMonth() devuelve de 0 a 11, por eso sumamos 1
                const dia = ('0' + fecha.getDate()).slice(-2);
                
                return `${año}-${mes}-${dia}`;
                }
                let hoy = obtenerFechaActual();
                flashcards.forEach(flashcard => {
                flashcard.exam_date.forEach((date: string, index: string | number) => {
                    if (date === hoy && !flashcard.exam_bool[index]) {
                    tarjetas.push(flashcard)
                    }
                });
                });
                setData({ cartas: tarjetas, modo: modoEstudiar, time: null, onClickReturn: () => {} })
            }
            navigate(`/study`)
        }
        }

    return (
        <FetchLayout status={deckStatus}>
            <PageLayout title={deck?.name} isDetailed>
                <div className="flex gap-3 justify-between items-center mt-8">
                    <div className="w-[230px] box-border  bg-gray-100 rounded-lg flex items-center px-2">
                        <BiSearch className="text-gray-500"></BiSearch>
                        <input value={nameFilter} onFocus={e => e.target.select()}
                               onChange={e => setNameFilter(e.target.value)}
                               className="w-full bg-transparent box-border outline-none border-0 shadow-none px-3 py-2 rounded-lg"
                               placeholder="Busca tus flashcards..."/>
                    </div>
                    <div className="flex flex-row gap-3">
                        <AddFlashcardMenu/>
                    </div>
                </div>
                <FetchLayout emptyComponent={<NoCardsBanner/>}
                             status={status}
                             isEmpty={1 > (cards?.length ?? 0)}
                             isEmptySearch={0 < (cards?.length ?? 0) && (filteredCards?.length ?? 0) < 1}
                             className="grid md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3  mt-5 gap-3">
                        {
                            (filteredCards?.map((card) =>
                                <QuestionPreviewCard
                                    key={card.id}
                                    card={card}
                                />
                            ))
                        }
                </FetchLayout>
            </PageLayout>
            { deck?.Array?.length !== 0 &&
                <FloatingPlayButton text={(deck?.modoEstudio==5?'Repasar ':'Estudiar ')+deck?.name} onClickPlay={()=>CallStudy()}/>
            }
        </FetchLayout>
    );
}

export default DetailedDeck;