import { Menu } from '@ark-ui/react';
import React from 'react';
import {BsStars} from "react-icons/bs";
import {PiCardsThreeFill} from "react-icons/pi";
import {useNavigate} from "react-router-dom";

function AddFlashcardMenu() {
    const navigate = useNavigate();

    return (
        <Menu.Root>
            <Menu.Trigger className="text-nowrap flex gap-2 bg-brand_primary-500 text-white p-3 py-2 font-semibold cursor-pointer rounded-lg">
                Añadir temario
            </Menu.Trigger>
            <Menu.Positioner>
                <Menu.Content  className="shadow-[3px_4px_38px_0px_rgba(0,_0,_0,_0.06)] outline-0 bg-white p-3 w-[230px] rounded-xl text-gray-700">
                    <Menu.Item onClick={() => navigate("card")} value="react" className="flex items-center text-sm font-semibold gap-2 p-2 hover:bg-gray-100 rounded-xl cursor-pointer">
                        <PiCardsThreeFill className="text-brand_secondary-500"></PiCardsThreeFill>
                        Añadir manualmente
                    </Menu.Item>
                    <Menu.Item onClick={() => navigate("card/ai")} value="solid" className="flex items-center text-sm font-semibold gap-2 p-2 hover:bg-gray-100 rounded-xl cursor-pointer">
                        <BsStars className="text-brand_secondary-500"></BsStars>
                        Generar con IA
                    </Menu.Item>
                </Menu.Content>
            </Menu.Positioner>
        </Menu.Root>
    );
}

export default AddFlashcardMenu;