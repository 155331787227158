import UserSetupFirstForm from './components/UserSetupFirstForm'
import UserSetupSecondForm from './components/UserSetupSecondForm'
import RegisterResult from './components/RegisterResult'
import { registerFirstFormSchema, registerSecondFormSchema } from './schemas'
import UserSetupForm from './components/UserSetupForm'
import { StepProps } from './types'
import RegisterDecoration from "@/pages/App/pages/UserSetup/components/RegisterDecoration";
import { useAuth } from "@/context/auth/useAuth";
import Spinner from '@/components/ui/Spinner'

const steps = [
  {
    component: (props : StepProps) => <UserSetupFirstForm key={0}></UserSetupFirstForm>,
    validationSchema: registerFirstFormSchema
  },
  {
    component: (props : StepProps) => <UserSetupSecondForm key={1}></UserSetupSecondForm>,
    validationSchema: registerSecondFormSchema,
    submitForm: true
  },
  {
    component: (props: StepProps) => <RegisterResult status={props.status} goBack={props.goBack} key={2}></RegisterResult>,
    hideControls: true
  }
]

export default function UserSetup() {
  const { isAuth, isPending, redirectTo } = useAuth()

  if (isPending) return <Spinner />
	else if (isAuth && redirectTo == '/user_setup') return (
      <div className="grid md:grid-cols-5 h-[100vh] ">
          <div className="col-span-3 h-[100vh]  flex justify-center md:items-center">
              <div className="max-w-[800px] w-full p-4">
                  <UserSetupForm steps={steps} />
              </div>
          </div>
          <div className='hidden relative overflow-hidden col-span-2 md:flex w-full bg-background-color bg-brand_primary-500 flex-row items-center justify-center'>
              <RegisterDecoration />
          </div>
      </div>
  )
  else window.open('/', '_self')
}
