import Icon from "@/components/icons/Icon"

interface PopupProps {
    open: boolean
    closeIcon?: boolean
    closeOnBlur?: boolean
    width?: string // in px format
    height?: string // in px format
    backdropBlur?: number // in px format
    className?: string
    onClose?: () => void
    children: React.ReactNode
}

export default function Popup({
  open, 
  closeIcon = true, 
  closeOnBlur = true, 
  backdropBlur = 0, 
  width = '35%', 
  height = 'auto', 
  className, 
  onClose, 
  children
}: PopupProps) {

  if ((closeIcon || closeOnBlur) && !onClose) {
    throw new Error('Popup: onClose prop is required when using closeIcon or closeOnBlur')
  }

  if (open) return (
    <>
      <div 
        className='fixed inset-0 bg-gray-500 bg-opacity-50 z-50'
        style={{ backdropFilter: `blur(${backdropBlur}px)`, WebkitBackdropFilter: `blur(${backdropBlur}px)`}}
        onClick={closeOnBlur ? onClose : undefined}
      ></div>
      <div 
        style={{ transform: 'translate(-50%, -50%)', top: '50%', left: '50%', width: width, height: height}}
        className={`fixed md:min-w-80 p-3 bg-white text-[var(--color-text)] rounded-[30px] overflow-hidden z-[150] max-w-[85vw] ${className}`}
      >
        { closeIcon && <Icon 
          className='text-[var(--color-text-light)] hover:text-[var(--color-secondary)] absolute top-3 right-3'
          iconName='xmark' 
          size={'30'} 
          onClick={onClose} 
        />}
        {children}
      </div>
    </>
  )
}
