import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import PricingCard from './PricingCard';
import CancelSubscriptionDialog from './CancelSubscriptionDialog';
import RenewSubscriptionDialog from './RenewSubscriptionDialog';
import LoadingButton from '@/components/ui/LoadingButton';
import Button from '@/components/ui/Button';
import {useRequestSubscription} from '@/api/pricing/useRequestSubscription';
import {useToast} from '@/components/ui/ToastContext';
import {useAuth} from '@/context/auth/useAuth';
import {pricingPlans} from "@/data/pricingPlans";
import {Subscription} from '@/types/types';

interface PricingPlansProps {
    subscription: Subscription;
}

export default function PricingPlans({subscription}: PricingPlansProps) {
    const {isAuth} = useAuth();
    const navigate = useNavigate();
    const {createToast} = useToast();
    const {mutateAsync: requestSubscription} = useRequestSubscription({
        onSuccess: async (url) => {
            window.location.href = url;
        },
        onError: () => {
            setPlanPayUrlLoading(undefined);
            createToast({
                title: 'Error', 
                description: 'Ha ocurrido un error al obtener la URL de pago', 
                type: 'error'}
            )
        }
    })

    const [isModifySubDialogOpen, setIsModifySubDialogOpen] = useState(false);
    const [planPayUrlLoading, setPlanPayUrlLoading] = useState<undefined | string>(undefined);

    return (<>
        <div className="grid md:grid-cols-4 justify-center gap-5 mt-10">
            {
                pricingPlans.map((x) => (
                    <PricingCard 
                        key={x.id}
                        plan={x}
                        isYourCurrentPlan={x.id === subscription?.plan_id}
                        callToAction={
                            subscription.plan_id != null && subscription.plan_id === x.id ? 
                                <>{ subscription.renew ? 
                                    <Button 
                                        className="w-full mt-5 bg-red-500 hover:bg-red-500"
                                        onClick={() => setIsModifySubDialogOpen(true)}
                                    >
                                        Cancelar Subscripción
                                    </Button> 
                                    :
                                    <Button
                                        className='w-full mt-5' 
                                        onClick={() => setIsModifySubDialogOpen(true)}>
                                        Renovar Subscripción
                                    </Button>
                                }</>
                            :
                            x.id != null ? 
                                <LoadingButton 
                                    className="w-full mt-5"
                                    onClick={isAuth ? () => {requestSubscription(x.id!); setPlanPayUrlLoading(x.id!)} : () => navigate('/login')} 
                                    loading={planPayUrlLoading == x.id}
                                >
                                    {subscription.plan_id === null ? 'Mejorar Plan' : 'Cambiar Plan'}
                                </LoadingButton>
                            : undefined
                        }
                    />
                ))
            }
        </div>
        <CancelSubscriptionDialog 
            open={isModifySubDialogOpen && subscription.renew} 
            onClose={() => setIsModifySubDialogOpen(false)}
        />
        <RenewSubscriptionDialog 
            open={isModifySubDialogOpen && !subscription.renew} 
            onClose={() => setIsModifySubDialogOpen(false)}
        />
    </>);
}
