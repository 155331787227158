import DashboardElementContainer from "@/pages/App/pages/Dashboard/components/DashboardElementContainer";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import {useDashboardBarChart} from "@/api/charts/useDashboardBarChart";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function PomodoroTimeBarChart() {

    const {data, status} = useDashboardBarChart();
    const daysOfWeek = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo']
    const today = new Date().getDay()
    const rotatedLabels = [...daysOfWeek.slice(today), ...daysOfWeek.slice(0, today)]
    
    const chart = {
        labels: rotatedLabels,
        datasets: [
            {
                label: 'Tiempo de uso (min)',
                data: data?.minEstudioHoy ?? [0, 0, 0, 0, 0, 0, 0],
                backgroundColor: 'rgba(73, 200, 133, 1)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 0,
                borderRadius: 10,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    stepSize: 1,
                    maxTicksLimit: 7
                },
                suggestedMax: 5,
            },
            x: {
                grid: {
                    display: false 
                  }
              },
        },
    };

    return (
        <DashboardElementContainer title="Uso de Pomodoro esta semana" status={[status,]}>
            <div style={{ height: '300px', width: '100%' }}>
                <Bar data={chart} options={options} />
            </div>
        </DashboardElementContainer>
    );
}

export default PomodoroTimeBarChart;
