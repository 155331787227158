import React from 'react'
import {useField} from 'formik'

interface Props{
  children : React.ReactNode
  name: string
}

export default function FormCheckbox(props: Props) {
  const [field, meta] = useField(props.name ?? "");

  return (
    <>

      <div className='flex row items-center mb-2'>
        <input type='checkbox' {...field} checked={field.checked} className='w-4 mr-2'/>
        {
          props.children
        }
      </div>
      {meta.touched && meta.error ? (
        <p className='!text-xs text-red-500'>{meta.error}</p>
      ) : null}
    </>
  )
}
