import { useState } from 'react'
import Dialog from "@/components/ui/Dialog";
import FormInput from "@/components/forms/core/FormInput";
import Form from "@/components/forms/core/Form";
import FormSubmitButton from "@/components/forms/core/FormSubmitButton";
import {useParams} from "react-router-dom";
import {useCreateDeck} from "@/api/decks/useCreateDeck";
import {queryClient} from "@/api/client";

interface props{
    isOpen: boolean;
    setIsOpen: (open: boolean) => void;
}

export const AddDeckFormDialog = ({isOpen, setIsOpen} : props) => {
    const { subject_id } = useParams<{ subject_id: string }>();
    const {mutateAsync} = useCreateDeck({
        onSuccess:  async () => {
             await queryClient.invalidateQueries({
                queryKey: ['decks', subject_id],
            })
            setIsOpen(false);
        }
    })

    return (
        <Dialog open={isOpen} onClose={() => setIsOpen(false)} title={"Crear nuevo mazo"} className="max-w-[500px] mx-2 w-full">
            <Form onSubmit={async (values) => {
                await mutateAsync(values)
            }} initialValues={{name: "", asignatura_id: (subject_id || "")}}>
                <div className="mt-8">
                    <FormInput size="md" name="name" label={"Nombre:"} placeholder="Nombre para tu nuevo mazo"></FormInput>
                </div>
                <div className="flex items-center justify-center gap-3 mt-12">
                    <button onClick={() => setIsOpen(false)} className="px-7 bg-gray-100 py-2 text-gray-500 font-semibold rounded-full">Cancelar</button>
                    <FormSubmitButton className="px-7 bg-brand_secondary-500 py-2 text-white font-semibold !rounded-full">Crear</FormSubmitButton>
                </div>
            </Form>
        </Dialog>
    )
}
