import { encoding_for_model } from 'tiktoken'

export const calculateTokensWithTikToken = async (text: string) => {
  const enc = encoding_for_model('gpt-3.5-turbo') // pasar a .env
  const tokens = enc.encode(text)
  enc.free()
  return tokens.length
}

export const calculateTokens = (text: string) => {
  return Math.round(text.length / 4);
}

export const calculatePages = (tokens: number) => {
  return Math.round(tokens / 800);
}