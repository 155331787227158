import ConfirmationDialog from "@/components/ConfirmationDialog";
import { useForgetSubject } from "@/api/subjects/useForgetSubject";
import { useToast } from "@/components/ui/ToastContext";

interface ForgetSubjectDialogProps {
    subjectId: string,
    open: boolean,
    onClose: () => void
}

export default function ForgetSubjectDialog({subjectId, open, onClose}: ForgetSubjectDialogProps) {
    const {createToast} = useToast();
    const {mutateAsync, isPending} = useForgetSubject({
        onSuccess: async () => {
            onClose()
            createToast({
                title: 'Asignatura Olvidada', 
                description: 'El progreso de la asignatura ha sido olvidado correctamente', 
                type: 'success'
            })
        },
        onError: () => {
            onClose()
            createToast({
                title: 'Error', 
                description: 'Ha ocurrido un error al intentar olvidar la asignatura', 
                type: 'error'}
            )
        }
    });

    if (open) return (
        <ConfirmationDialog
            open={true}
            onClose={onClose}
            title={'Olvidar Asignatura'}
            description={'¿Estás seguro de que deseas olvidar esta asignatura?'}
            confirmText='Olvidar'
            onConfirm={async () => await mutateAsync({subjectId: subjectId})}
            isPending={isPending}
        />
    )
}