import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {Deck} from "@/types/db/deck";
import EntityMenu from "@/pages/App/pages/Library/components/EntityMenu";
import {TbDots} from "react-icons/tb";
import {FaPlay} from "react-icons/fa";
import {BiEdit, BiSolidLowVision, BiTrash} from "react-icons/bi";
import DeleteDeckDialog from './DeleteDeckDialog';
import ForgetDeckDialog from './ForgetDeckDialog';
import {AddDeckFormDialog} from "@/pages/App/pages/Library/pages/DetailedSubject/components/AddDeckFormDialog";
import {UpdateDeckDialog} from "@/pages/App/pages/Library/pages/DetailedSubject/components/UpdateDeckFormDialog";
import { useStudy } from '@/context/StudyContext';
import { Card } from '@/types/db/card';

type DialogType = 'delete' | 'forget' | 'rename';

interface Props{
    deck : Deck
}

//FIXME: CRIMEN
function DeckCard({deck} : Props) {
    const navigate = useNavigate();
    const [dialog, setDialog] = useState<null | DialogType>(null)
    const [numEstudiar, setNumEstudiar] = useState(0)
    const { setData } = useStudy();

    console.log(deck)

    console.log("ME RERENDERICE CRACK")

    useEffect(() => {
        if (deck.modoEstudio == 4) {
            setNumEstudiar(deck.Array.length)
        } else if (deck.modoEstudio == 5) {
            setNumEstudiar(0)
        } else if (deck.modoEstudio == 2) {
            let tarjetas=0
            let numero=deck.numEstudiar
            for(let i=0; i<deck.Array.length; i++){
            if(deck.Array[i].final_bool==false && numero>0){
                tarjetas++
                numero--
            }
            }
            setNumEstudiar(Math.min(deck.numEstudiar,tarjetas))
        } else if (deck.modoEstudio == 1) {
            setNumEstudiar(deck.numEstudiar)
        }
    },[deck])

    const CallStudy = () => {//mas crimenes de pirulo, habra juicios muy pronto
        const modoEstudiar = deck.modoEstudio
        const flashcards = deck.Array.map((card: Card) => ({...card}));
        if(modoEstudiar == 4 || modoEstudiar == 5){
            setData({ cartas: flashcards, modo: modoEstudiar, time: null, onClickReturn: () => {} })
        } else if(modoEstudiar == 2){
            let tarjetas=[]
            let numero=numEstudiar
            for(let i=0; i<flashcards.length; i++){
                if(flashcards[i].final_bool==false && numero>0){
                    tarjetas.push(flashcards[i])
                    numero--
                }
            }
            setData({ cartas: tarjetas, modo: modoEstudiar, time: null, onClickReturn: () => {} })
        } else if(modoEstudiar == 1){
            let tarjetas: any[]=[]
            function obtenerFechaActual() {
            const fecha = new Date();
            
            const año = fecha.getFullYear();
            const mes = ('0' + (fecha.getMonth() + 1)).slice(-2); // getMonth() devuelve de 0 a 11, por eso sumamos 1
            const dia = ('0' + fecha.getDate()).slice(-2);
            
            return `${año}-${mes}-${dia}`;
            }
            let hoy = obtenerFechaActual();
            flashcards.forEach(flashcard => {
            flashcard.exam_date.forEach((date: string, index: string | number) => {
                if (date === hoy && !flashcard.exam_bool[index]) {
                tarjetas.push(flashcard)
                }
            });
            });
            setData({ cartas: tarjetas, modo: modoEstudiar, time: null, onClickReturn: () => {} })
        }
        navigate(`/study`)
    }

    return (
        <>
            <div 
                onClick={() => navigate(`deck/${deck.id}`)}
                className="w-full bg-gray-100 hover:bg-gray-200 p-5 box-border min-h-[150px] h-full rounded-xl cursor-pointer text-gray-700  justify-between flex flex-col relative"
            >
                <div className="w-full flex justify-end">
                    <EntityMenu items={[
                        {
                            title: "Olvidar",
                            icon: <BiSolidLowVision />,
                            callback: () => setDialog('forget')
                        },
                        {
                            title: "Editar",
                            icon: <BiEdit />,
                            callback: () => setDialog('rename')
                        },
                        {
                            title: "Eliminar",
                            trash: true,
                            icon: <BiTrash />,
                            callback: () => setDialog('delete')
                        },
                    ]} >
                        <div className={`h-[30px]  w-[30px]  bg-gray-200 hover:bg-gray-300 flex justify-center items-center rounded-full`}>
                            <TbDots />
                        </div>
                    </EntityMenu>
                </div>
                <div className="flex justify-between items-end">
                    <div>
                        <p className="text-[15px] font-semibold">
                            {deck.name}
                        </p>
                        <p className="mt-0 text-xs text-gray-500">
                        <h4>
                            { deck.Array.length == 0 ? ('Tema vacío') :
                            (numEstudiar > 1 ? (numEstudiar+' pendientes hoy ') :
                            (numEstudiar==1?(numEstudiar+' pendiente hoy '):('Estudio diario hecho')))
                            }
                        </h4>
                        </p>
                    </div>
                    {!(deck.Array.length == 0) && <div className="min-w-[40px] min-h-[40px] flex justify-center items-center text-white  rounded-full bg-brand_secondary-500 hover:bg-brand_secondary-700 "
                    onClick={(e) => {e.stopPropagation();CallStudy()}}>
                        <FaPlay className='ml-1'/>
                    </div>}
                </div>
            </div>
            <DeleteDeckDialog deckId={deck.id} open={dialog === 'delete'} onClose={() => setDialog(null)} />
            <ForgetDeckDialog deckId={deck.id} open={dialog === 'forget'} onClose={() => setDialog(null)} />
            <UpdateDeckDialog deck={deck} open={dialog=== 'rename'} onClose={() => setDialog(null)}/>
        </>
    );
}

export default DeckCard;