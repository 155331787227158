import * as Yup from 'yup'

export const messageValidation = Yup.string()
  .min(1)
  .max(1000, 'El mensaje no puede contener más de 1000 caracteres')
  .required('Por favor, escribe un mensaje')

export const contactSchema = Yup.object().shape({
  message: messageValidation,
})
