import {useMutation, UseMutationOptions} from "@tanstack/react-query";
import {apiClient} from "@/api/client";
import {AxiosError} from "axios";

interface ModifySubjectRequestData {
    subjectId : string
    newName : string
}

interface ModifySubjectResponseData {
    message : string
}

export const modifySubjectRequest = async (modifySubjectData: ModifySubjectRequestData) => {
    try{
        const response = await apiClient.patch<ModifySubjectResponseData>(
            `/folder/renameFolder?folderId=${modifySubjectData.subjectId}&newName=${modifySubjectData.newName}`
        );
        return response.data;
    }catch(error : any){
        throw error.response.data
    }
};

export const useModifySubject = (options: UseMutationOptions<ModifySubjectResponseData, AxiosError, ModifySubjectRequestData, null>) =>{
    return useMutation<ModifySubjectResponseData, AxiosError, ModifySubjectRequestData, null>({
        mutationFn: modifySubjectRequest,
        ...options
    })
}
