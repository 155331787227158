import DashboardElementContainer from "@/pages/App/pages/Dashboard/components/DashboardElementContainer";
import Spinner from "@/components/ui/Spinner";
import {useUserStats} from "@/api/statistics/useUserStats";
import {TbClockHour4} from "react-icons/tb";
import {PiFireBold} from "react-icons/pi";

function DashboardStats() {

    const {data: userStatsData, status: userStatsStatus} = useUserStats()

    const handleTodaysStreakCompleted = () => {
        if(!userStatsData) return false;
        const today = new Date().toISOString().split('T')[0];
        const rachaLastDate = new Date(userStatsData.racha_lastDate).toISOString().split('T')[0];
        return today === rachaLastDate;
      };

    return (
        <div className="grid md:grid-cols-2 gap-3">
            <DashboardElementContainer status={[userStatsStatus,]} className={`${handleTodaysStreakCompleted()? 'bg-orange-500 !text-white' : 'bg-brand_primary-500'}`}>
                <div className="flex gap-4 items-center w-full">
                    <div className={`w-[60px] h-[60px] flex justify-center items-center text-white rounded-xl ${handleTodaysStreakCompleted()? 'bg-orange-700' : 'bg-brand_primary-500'}`}>
                        <PiFireBold size={25}></PiFireBold>
                    </div>
                    <div>
                        <h1 className={`text-xs m-0 font-medium ${handleTodaysStreakCompleted()? "text-white" : "text-gray-500"}`}>Racha</h1>
                        <h1 className="text-xl m-0 terxt-gray-800">{userStatsData?.racha} {userStatsData?.racha==1?'día':'días'}</h1>
                    </div>
                </div>
            </DashboardElementContainer>
            <DashboardElementContainer status={[userStatsStatus,]}>
                <div className="flex gap-4 items-center w-full">
                    <div
                        className="w-[60px] h-[60px] bg-brand_primary-500 flex justify-center items-center text-white rounded-xl">
                        <TbClockHour4 size={25}></TbClockHour4>
                    </div>
                    <div>
                        <h1 className="text-xs m-0 font-medium text-gray-500">Tiempo total concentrado</h1>
                        <h1 className="text-xl m-0 terxt-gray-800 fo">
                            {Math.round(userStatsData?.minEstudioTot ?? 0) >= 60 ? (Math.floor((userStatsData?.minEstudioTot ?? 0) / 60)) : Math.round((userStatsData?.minEstudioTot ?? 0))}
                            {Math.round(userStatsData?.minEstudioTot ?? 0) >= 60 ? (Math.floor((userStatsData?.minEstudioTot ?? 0) / 60) == 1 ? ' hora' : ' horas') : Math.round((userStatsData?.minEstudioTot ?? 0)) == 1 ? ' minuto' : ' minutos'}
                        </h1>
                    </div>
                </div>
            </DashboardElementContainer>
        </div>
);
}

export default DashboardStats;