import { existUserWithUsername } from '@/api/auth/register/validators'
import { debounce } from '@/api/utils'
import * as Yup from 'yup'

const debouncedCheckUsername = debounce(existUserWithUsername, 500);  
  
export const registerFirstFormSchema = Yup.object({
  name: Yup.string()
    .min(1)
    .max(20, 'El nombre no puede contener más de 20 caracteres')
    .matches(/^[a-zA-ZñáéíóúÁÉÍÓÚ\s]+$/, 'El nombre contiene caracteres inválidos')
    .required('El nombre es obligatorio'),

  surname: Yup.string()
    .min(1)
    .max(30, 'Los apellidos no pueden contener más de 30 caracteres')
    .matches(/^[a-zA-ZñáéíóúÁÉÍÓÚ\s]+$/, 'Los apellidos contienen caracteres inválidos')
    .required('El nombre es obligatorio'),
  username: Yup.string()
    .min(1)
    .max(20, 'El nombre de usuario no puede contener más de 20 caracteres')
    .matches(/^[a-zA-Z0-9._]+$/, 'El nombre de usuario contiene caracteres inválidos')
    .required('El nombre de usuario es obligatorio')
    .test('username-exists', 'Este nombre de usuario ya está registrado', async (value) => {
      if (!value) return true; // Skip validation if username is empty
      return !(await debouncedCheckUsername(value));
    }),

  birthdate: Yup.date()
    .min(new Date('1900-01-01'), 'La fecha de nacimiento es invalida')
    .max(new Date(), 'La fecha de nacimiento es invalida')
    .required('La fecha de nacimiento es obligatoria'),
})

export const registerSecondFormSchema = Yup.object().shape({
  location: Yup.string()
    .max(30, 'La localidad no puede contener mas de 30 caracteres'),
  education: Yup.string()
    .matches(/^(degree|vocationalTraining|civilServiceExam|others)$/, 'Tipo invalido')
    .required('El tipo de estudios es obligatorio'),
  institution: Yup.string()
    .max(30, 'El centro educativo no puede contener mas de 30 caracteres'),
  qualification: Yup.string()
    .max(30, 'La titulación no puede contener mas de 30 caracteres'),
  goal: Yup.string()
    .max(1000, 'El mensaje no puede contener mas de 1000 caracteres'),
  termsAccepted: Yup.bool().isTrue("Tienes que aceptar los terminos y condiciones")
})
