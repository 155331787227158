import {ReactNode, useState, useEffect} from 'react'
import logo from "@/assets/refactored_logo.png";
import Navbar from "@/components/common/header/Navbar";
import {useNavigate} from "react-router-dom";
import UserMenuButton from "@/components/common/header/UserMenuButton";
import HeaderClock from "@/components/common/header/HeaderClock";
import {BiMenu} from "react-icons/bi";
import MobileNavbar from "@/components/common/header/MobileNavbar";


export default function Header() {
  const navigate = useNavigate()
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  useEffect(() => {
    if (mobileMenuOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [mobileMenuOpen]);

  return (
      <>
          {
              mobileMenuOpen && (
                  <MobileNavbar onClose={() => setMobileMenuOpen(false)}></MobileNavbar>
              )
          }
    <header className="bg-brand_primary-600 flex w-full justify-between items-center p-3 py-4 box-border fixed !z-40">
      <div >
        <img src={logo} alt='logo' onClick={() => navigate('/home')} className='cursor-pointer w-[110px] hidden lg:block'/>
        <BiMenu onClick={() => setMobileMenuOpen(true)} className="block lg:hidden text-white text-2xl cursor-pointer"></BiMenu>
      </div>
      <div className="hidden lg:block lg:ml-28 xl:ml-36">
        <Navbar></Navbar>
      </div>
      <div className="flex items-center gap-3">
        <HeaderClock></HeaderClock>
        <UserMenuButton></UserMenuButton>
      </div>
    </header>
      </>
  )
}
