import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import DashboardElementContainer from "@/pages/App/pages/Dashboard/components/DashboardElementContainer";
import { useLearnedCards } from "@/api/statistics/useLearnedCards";

// Registra los elementos necesarios para el gráfico
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function LastMonthStudyBarChart() {
    const { status, data } = useLearnedCards();
    
    const labels = Object.keys(data?.fechasPorEstudiar ?? {}).reverse();
    
    const chart = {
        labels: labels,
        datasets: [
            {
                label: 'Tarjetas nuevas',
                data: Object.values(data?.fechasPorEstudiar ?? {}).reverse(),
                backgroundColor: '#E96E90',
                borderWidth: 0,
            },
            {
                label: 'Tarjetas en aprendizaje',
                data: Object.values(data?.fechasEstudiando ?? {}).reverse(),
                backgroundColor: '#EFD794',
                borderWidth: 0,
            },
            {
                label: 'Tarjetas aprendidas',
                data: Object.values(data?.fechasEstudiadas ?? {}).reverse(),
                backgroundColor: '#4AC886',
                borderWidth: 0,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
          legend: {
            display: false
          },
        },
        scales: {
          x: {
            stacked: true, 
            grid: {
                display: false 
              }
          },
          y: {
            stacked: true, 
            beginAtZero: true,
            ticks: {
                stepSize: 1,
                maxTicksLimit: 7,
            },
            suggestedMax: 30,
          },
        },
      };

    return (
        <DashboardElementContainer title="Progreso de Estudio" status={[status]}>
            <div className="w-full h-full mt-4">
                <Bar data={chart} options={options} />
            </div>
        </DashboardElementContainer>
    );
}

export default LastMonthStudyBarChart;


