import FormikEditorEditable from "@/pages/App/pages/Library/pages/DetailedCard/pages/DetailedCardCreate/components/forms/fields/FormikEditorEditable";
import { baseToolkitButtons} from "@/components/forms/TipTapEditorForm/utils";

export default function FlashCardModifyForm() {

  return(
      <div className='w-full flex flex-col items-center justify-center box-border'>
          <FormikEditorEditable toolkit={baseToolkitButtons} editorClass="min-h-[80px]" name={"pregunta"} label="Pregunta" placeholder="Añade una pregunta"/>
          <div className="w-full h-[1px] bg-gray-200 my-2 box-border">
          </div>
          <FormikEditorEditable toolkit={baseToolkitButtons} editorClass="min-h-[80px]" name={"respuesta"} label="Respuesta" placeholder="Añade una respuesta"></FormikEditorEditable>
      </div>
  )
}