import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { apiClient } from "@/api/client";
import { AxiosError } from "axios";

interface DeleteAccountResponseData {
    message: string;
}

export const deleteAccountRequest = async () => {
    try {
        const response = await apiClient.delete<DeleteAccountResponseData>("/auth/delete", {
            withCredentials: true,
        });
        return response.data;
    } catch (error: any) {
        throw error.response.data;
    }
};

export const useDeleteAccount = (
    options: UseMutationOptions<DeleteAccountResponseData, AxiosError, void, null>
) => {
    return useMutation<DeleteAccountResponseData, AxiosError, void, null>({
        mutationFn: deleteAccountRequest,
        ...options
    });
};
