import DeckCard from "@/pages/App/pages/Library/pages/DetailedSubject/components/DeckCard";
import { useParams } from 'react-router-dom';
import { useSubject } from "@/api/subjects/useSubject";
import { useDecks } from "@/api/decks/useDecks";
import React, {useState} from "react";
import {BiSearch} from "react-icons/bi";
import {AddDeckFormDialog} from "@/pages/App/pages/Library/pages/DetailedSubject/components/AddDeckFormDialog";
import PageLayout from "@/components/layout/PageLayout";
import FetchLayout from "@/components/layout/FetchLayout";
import Button from "@/components/ui/Button";
import NoDecksBanner from "@/pages/App/pages/Library/pages/DetailedSubject/components/NoDecksBanner";

function DetailedSubject() {
    const { subject_id } = useParams<{ subject_id: string }>();
    const { data: subject, status : subjectStatus } = useSubject(subject_id || "");
    const { data: decks, status: deckStatus } = useDecks(subject_id || "");
    const [nameFilter, setNameFilter] = useState("")
    const [createSubjectOpen, setCreateSubjectOpen] = useState(false);

    const filteredDecks = decks?.filter(x => x.name?.toLowerCase().includes(nameFilter.toLowerCase()))

    return (
        <FetchLayout status={subjectStatus}>
            <PageLayout title={subject?.name}>
                    <div className="flex gap-3 justify-between items-center mt-8">
                        <div className="w-[230px] box-border  bg-gray-100 rounded-lg flex items-center px-2">
                            <BiSearch className="text-gray-500"></BiSearch>
                            <input value={nameFilter} onFocus={e => e.target.select()}
                                   onChange={e => setNameFilter(e.target.value)}
                                   className="w-full bg-transparent box-border outline-none border-0 shadow-none px-3 py-2 rounded-lg"
                                   placeholder="Busca tus mazos..."/>
                        </div>
                        <Button onClick={() => setCreateSubjectOpen(true)} className="text-nowrap !rounded-lg">
                            Crear mazo
                        </Button>
                    </div>
                    <FetchLayout emptyComponent={<NoDecksBanner/>}
                                 isEmpty={(decks?.length ?? 0) < 1}
                                 isEmptySearch={(decks?.length ?? 0) > 0 && (filteredDecks?.length?? 0) < 1}
                                 status={deckStatus}
                                 className="grid md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3  mt-5 gap-3">
                        {
                            filteredDecks?.map((deck) =>
                                <DeckCard deck={{
                                    id: deck.id,
                                    name: deck.name,
                                    asignatura_id: subject_id!,
                                    Array: deck.Array, //CRIMEN
                                    modoEstudio: deck.modoEstudio, //CRIMEN
                                    numEstudiar: deck.numEstudiar //CRIMEN
                                }} key={deck.id}></DeckCard>
                            )
                        }
                    </FetchLayout>
                    {createSubjectOpen && <AddDeckFormDialog isOpen={createSubjectOpen} setIsOpen={setCreateSubjectOpen}></AddDeckFormDialog>}
            </PageLayout>
        </FetchLayout>
    );
}

export default DetailedSubject;