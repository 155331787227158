import {useMutation, UseMutationOptions} from "@tanstack/react-query";
import {apiClient} from "@/api/client";
import {AxiosError} from "axios";

interface ModifyDeckRequestData {
    deckId : string
    newName : string
}

interface ModifyDeckResponseData {
    message : string
}

export const modifyDeckRequest = async (modifyDeckData: ModifyDeckRequestData) => {
    try{
        const response = await apiClient.patch<ModifyDeckResponseData>(
            `/mazo/rename?mazoId=${modifyDeckData.deckId}&newName=${modifyDeckData.newName}`
        );
        return response.data;
    }catch(error : any){
        throw error.response.data
    }
};

export const useModifyDeck = (options: UseMutationOptions<ModifyDeckResponseData, AxiosError, ModifyDeckRequestData, null>) =>{
    return useMutation<ModifyDeckResponseData, AxiosError, ModifyDeckRequestData, null>({
        mutationFn: modifyDeckRequest,
        ...options
    })
}
