import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import DashboardElementContainer from "@/pages/App/pages/Dashboard/components/DashboardElementContainer";
import {useLearnedCards} from "@/api/statistics/useLearnedCards";

ChartJS.register(ArcElement, Tooltip, Legend);

function LearnedCardsPieChart() {
    const {status, data} = useLearnedCards()
    // Mapea los valores para obtener el total de entre todas las asignaturas
    const chartData = data ? [data.studied, data.studying, data.notStudied] : [0, 0, 0];
    const hasNoData = data && (data.studied == 0 && data.studying == 0 &&  data.notStudied == 0);
    const chart = {
        labels: ['Aprendidas', 'Repasando', 'Por Estudiar'],
        datasets: [
            {
                data: chartData,
                backgroundColor: ['#4AC886', '#EFD794', '#E96E90'],//['#49C985', '#F7D26E', '#FF7973']
                borderWidth: 0,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'bottom' as const,
            },
        }
    }

    return (
        <DashboardElementContainer title={"Tarjetas totales"} status={[status]} empty={hasNoData} emptyData={{title: "No hay tarjetas", subtitle: "Crea tarjetas para ver sus estadísticas"}}>
            <div className="mt-4" style={{height: 270}}>
                <Pie data={chart} options={options}/>
            </div>
        </DashboardElementContainer>
    );
}

export default LearnedCardsPieChart;
