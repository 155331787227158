import { Exam, Flashcard, Mazo, Simulacro } from '@/types/types'
import { useEffect, useState } from 'react'
import Icon from './icons/Icon'
import ExamPercentage from '@/components/ui/ExamPercentage'
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie, Doughnut } from 'react-chartjs-2'
import CreateExam from './CreateExam'
import Popup from '@/components/ui/Popup'
import { Card } from '@/types/db/card'
import ExamSimulacro from './ExamSimulacro'
import Spinner from '@/components/ui/Spinner'
import Information from '@/components/ui/Information'
import Button from './ui/Button'

Chart.register(ArcElement);


interface ExamDetailsProps {
    exam: Exam | null
    datos: any
    closeExam: () => void
    examenes: Exam[]
    estudio: (tarjetas: Card[], modo: number, time:number) => void 
}

export default function ExamDetails ({exam, datos, closeExam, examenes, estudio}:ExamDetailsProps) {



    const [editandoExamen, setEditandoExamen] = useState<boolean>(false);

    const [eliminarExamen, setEliminarExamen] = useState<boolean>(false);
    const [simulacros, setSimulacros] = useState<Simulacro []>([]);
    const [eliminandoExamen, setEliminandoExamen] = useState<boolean>(false);
    const [creandoSimulacro, setCreandoSimulacro] = useState<boolean>(false);
    const [mazos, setMazos] = useState<Mazo[]>([]);
    const [selectedMazoCheckboxes, setSelectedMazoCheckboxes] = useState<{ [key: string]: boolean }>({})
    const [originalMazoCheckboxes, setOriginalMazoCheckboxes] = useState<{ [key: string]: boolean }>({})
    const [actualizando, setActualizando] = useState<boolean>(false);



    let numFlashcardsTotal = datos.numFlashcardsTotal;
    let numFlashcardsStudied = datos.numFlashcardsStudied;
    const truncatedPercentage = 100 * numFlashcardsStudied / numFlashcardsTotal;
    let backgroundColor;
    if (truncatedPercentage < 30) {
        backgroundColor = 'var(--color-delete)';
    } else if (truncatedPercentage >= 30 && truncatedPercentage < 70) {
        backgroundColor = '#F7D26E';
    } else {
        backgroundColor = 'var(--color-secondary)';
    }

    const pieData = {
        labels: ['Aprendidas', 'Repasando', 'Por estudiar'],
        datasets: [
            {
                data: [datos.numFlashcardsStudied, datos.numFlashcardsLearning-datos.numFlashcardsStudied, datos.numFlashcardsTotal-datos.numFlashcardsLearning],
                backgroundColor: [
                    '#49c985',
                    '#F7D26E',
                    '#ff7973'
                ],
                borderColor:'#ededed',
                hoverOffset: 4
            }
        ]
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false, // Desactiva la leyenda
            },
            title: {
                display: false, // Desactiva el título
            },
        },
    };

    useEffect(() => {
        const fetchData = async () => {
            try{
                const response = await fetch(
                    `${import.meta.env.VITE_API_URL}/simulacro/get_for_exam?exam_id=${exam?.id}`,  
                    {
                        method: 'GET',
                        headers: { 'Content-Type': 'application/json', },
                        credentials: 'include'
                    }
                )
                if (!response.ok){
                    throw new Error('Error al obtener los simulacros');
                }	
                const data:Simulacro[] = await response.json();
                const sortedData = data.sort((a, b) => new Date(b.fecha).getTime() - new Date(a.fecha).getTime());
                setSimulacros(sortedData);

                const mazoIds = exam?.id_mazos_all.join(',')
                if(mazoIds!=""){
                    const responseMazoNames = await fetch(
                        `${import.meta.env.VITE_API_URL}/mazo/get_from_mazoId?mazoId=${mazoIds}`,
                        {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json',
                            }
                        }
                    )
                    if (!responseMazoNames.ok) {
                        throw new Error('Error en la actualizacion de la asignatura');
                    }
                    let dataMazos = await responseMazoNames.json();
                    setMazos(dataMazos);
                    const initialCheckboxes = dataMazos.reduce((acc: { [x: string]: boolean }, mazo: { id: string }) => {
                        acc[mazo.id] = exam?.id_mazos.includes(mazo.id) || false;
                        return acc;
                    }, {} as { [key: string]: boolean });
                    setSelectedMazoCheckboxes(initialCheckboxes);
                    setOriginalMazoCheckboxes(initialCheckboxes)
                }
            }
            catch (error) {
                console.error('Error:', error);
            }
        };
    
        fetchData();
    }, []);

    const handleEliminarExamen = async () => {
        setEliminandoExamen(true);
        try {
            const response = await fetch(
                `${import.meta.env.VITE_API_URL}/exam/delete?examId=${exam?.id}`,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include'
                }
            )
            if (!response.ok) {
                throw new Error('Error al eliminar el examen');
            }
            await new Promise(resolve => setTimeout(resolve, 100));

            const secondResponse = await fetch(
                `${import.meta.env.VITE_API_URL}/exam/sortExam`,  
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', },
                    body: JSON.stringify({}),
                    credentials: 'include'
              }
            )
            if (!secondResponse.ok){
                throw new Error('Error al actualizar los examenes');
            }	
            closeExam();
        } catch (error) {
            console.error('Error:', error);
        }
    }




    const CustomLegend = ({ data }: any) => {
        const total = data.datasets[0].data.reduce((acc: number, value: number) => acc + value, 0);
        return (
            <div>
                {data.labels.map((label: string, index: number) => (
                    <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                        <div style={{
                            minWidth: '20px',
                            minHeight: '20px',
                            backgroundColor: data.datasets[0].backgroundColor[index],
                            marginRight: '10px'
                        }}></div>
                        <div>{label}: {data.datasets[0].data[index]} ({total>0?((data.datasets[0].data[index] / total) * 100).toFixed(2):0}%)</div>
                    </div>
                ))}
            </div>
        );
    }

    const estudioDiario = () => {
        if(datos.numFlashcardsToday > 0 && datos.arrayEstudio.length==0){ 
            let numero=datos.numFlashcardsToday
            let arrayEstudio:Card[] = []
            for(let i=0; i<datos.arrayRepaso.length; i++){
                if(numero==0){
                    break
                }
                if(datos.arrayRepaso[i].final_bool==false){
                    arrayEstudio.push(datos.arrayRepaso[i])
                    numero--
                }
            }
            estudio(arrayEstudio,2,0)
        } else if(datos.numFlashcardsToday > 0 && datos.arrayEstudio.length>0){
            estudio(datos.arrayEstudio,1,0)
        }
    }

    const handleCheckboxChange = (mazoId: string) => {
        setSelectedMazoCheckboxes((prevCheckboxes) => ({
            ...prevCheckboxes,
            [mazoId]: !prevCheckboxes[mazoId],
        }));
    };

    const handleCambiarTemasEstudiados = () => async () => {
        const mazosSeleccionados = Object.keys(selectedMazoCheckboxes).filter((mazoId) => selectedMazoCheckboxes[mazoId]);
        const idMazos = mazosSeleccionados.length > 0 ? mazosSeleccionados : null;
        try {
            setActualizando(true)

            const response = await fetch(  
                `${import.meta.env.VITE_API_URL}/exam/edit_exam?examId=${exam?.id}&fecha_fin=${exam?.fecha_fin}&name=${exam?.name}&id_mazos=${idMazos}&id_mazos_all=${exam?.id_mazos_all}`,
                {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include'
                })
            if(!response.ok){
                console.log(response)
                throw new Error('Error en editar el examen');
            }
            
            const responseSort = await fetch(
                `${import.meta.env.VITE_API_URL}/exam/sortExam`,  
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', },
                    body: JSON.stringify({}),
                    credentials: 'include'
              }
            )
            if (!responseSort.ok){
                throw new Error('Error al actualizar los examenes');
            }		
            closeExam()
        } catch (error) {
            console.error('Error:', error);
            return
        }
    }




    return (
        <>
            <div className="w-[80vw] min-h-[420px] xl:h-[70vh] xl:max-h-[70vh] p-[20px] bg-[var(--color-background)] rounded-[10px] flex flex-col overflow-hidden mt-[20px]">
                <div
                    className="w-full border-[10px] border-solid p-[10px] relative left-[-20px] top-[-40px]"
                    style={{ backgroundColor: backgroundColor, borderColor: backgroundColor }}
                />
                {actualizando ? ( 
                    <div className="w-full h-[70vh] flex justify-center items-center flex-col">
                        <Spinner fillAll={false} size="40px" />
                        <div className="mt-[10px] text-[1.3em] font-medium">Planificando estudio</div>
                    </div> ) : (
                    <div className="relative top-[-30px]">
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start'}}>
                        <div className='flex-1 max-w-[70vw]'>
                            <div className='truncate text-4xl pb-[4px] font-bold'>{exam?.name}</div>
                            <div style={{ fontSize: '1rem', fontWeight: 'bold', color: 'var(--color-text-light)', marginTop: '3px' }}>{exam?.fecha_fin.toString()}</div>
                        </div>
                        <Icon iconName="xmark" onClick={() => closeExam()} size='25'/>
                    </div>
                    <hr style={{ width: '80%', height: '1px', border: 'none', backgroundColor: 'var(--color-primary)', marginTop: '6px', marginLeft: '0' }}></hr>
                        
                    <div className="flex flex-col md:flex-row  justify-start items-start md:items-end mt-[-10px] text-[var(--color-text-light)] font-semibold">
                        <div className="flex items-end mr-[30px]">
                            <div className="text-[3em] text-[var(--color-text)] mr-[5px]">{exam?.id_mazos.length}</div>
                            <div className="text-[1.5em] mb-2">Temas</div>
                        </div>
                        <div className="flex items-end mr-[30px] flex-wrap">
                            <div className="text-[3em] text-[var(--color-text)] mr-[5px]">{datos.numFlashcardsTotal - datos.numFlashcardsStudied}/{datos.numFlashcardsTotal}</div>
                            <div className="text-[1.5em] mb-2">Conceptos restantes</div>
                        </div>
                        <div className="flex items-end">
                            <div className="text-[3em] text-[var(--color-text)] mr-[5px] ">{datos.numFlashcardsToday}</div>
                            <div className="text-[1.5em] mb-2">Tarjetas para repasar hoy</div>
                        </div>
                    </div>

                    <div className="flex justify-around items-start my-5 px-2.5 xl:flex-row-reverse flex-wrap xl:flex-nowrap">

                        <div className='flex md:flex-row-reverse flex-col w-full justify-around' >

                            <div className="flex md:flex-col flex-wrap items-start justify-center lg:ml-5">
                                <Button
                                    className="flex justify-center my-1 mx-4 md:mx-0 bg-primary-color hover:bg-primary-color w-[220px]"
                                    onClick={() => setEditandoExamen(true)}
                                >
                                    Modificar Examen
                                    <div className="ml-[10px]">
                                        <Icon iconName="edit" size="20" />
                                    </div>
                                </Button>
                                <Button
                                    className="flex justify-center my-[5px] mx-4 md:mx-0  bg-delete-color hover:bg-delete-color w-[220px]"
                                    onClick={() => setEliminarExamen(true)}
                                >
                                    Eliminar Examen
                                    <div className="ml-[10px]">
                                        <Icon iconName="trash" size="20" />
                                    </div>
                                </Button>
                                <Button
                                    className={`flex justify-center my-[5px] mx-4 md:mx-0  ${numFlashcardsTotal === 0 ? 'bg-[#bbb] hover:bg-[#bbb] border-none cursor-default' : 'bg-medium-color hover:bg-medium-color'} w-[220px]`}
                                    onClick={() => setCreandoSimulacro(true)}
                                    disabled={numFlashcardsTotal === 0}
                                >
                                    Hacer Simulacro
                                    <div className="ml-[10px]">
                                        <Icon iconName="graduationCap" size="24" />
                                    </div>
                                </Button>
                                <Button
                                    className={`flex text-[15.7px] my-[5px] mx-4 md:mx-0 ${datos.numFlashcardsToday === 0 ? 'bg-[#bbb] hover:bg-[#bbb] border-none cursor-default' : 'bg-[var(--color-secondary)] hover:bg-[var(--color-secondary)]'} w-[220px] items-center`}
                                    onClick={() => estudioDiario()}
                                    disabled={datos.numFlashcardsToday === 0}
                                >
                                    {datos.numFlashcardsToday === 0 ? 'No hay conceptos por repasar' : 'Repasar Conceptos Planeados para Hoy'}
                                    <div className="ml-[10px]">
                                        <Icon iconName="circlePlay" size="25" isClickable={datos.numFlashcardsToday !== 0} />
                                    </div>
                                </Button>
                            </div>

                            <div className="flex flex-col items-start">
                                <div className="text-[22px] text-[var(--color-primary)] font-medium">Estadísticas</div>
                                <hr className="h-[1px] border-none bg-[var(--color-primary)] mt-[2px] w-[70%] mr-[70%] md:w-full" />
                                <div className="flex flex-row items-center mt-[10px]">
                                    <div className="h-[150px] w-[150px]">
                                        <Pie data={pieData} options={options} />
                                    </div>
                                    <div className="md:ml-[30px] ml-4">
                                        <CustomLegend data={pieData} />
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className='flex md:flex-row-reverse flex-col  w-full justify-around ' >
                            <div className="flex flex-col items-start xl:mt-0 mt-4">
                                <div className="text-[22px] text-[var(--color-primary)] font-medium">Simulacros recientes</div>
                                <hr className=" h-[1px] border-none bg-[var(--color-primary)] mt-[2px] w-[90%] mr-[90%] md:w-full" />
                                <ul className="scrollable-list h-[25vh] xl:w-[15vw] w-[88%]">
                                    {simulacros.map((simulacro, index) => (
                                        <div key={index} className="mt-[10px] font-medium">
                                            <div>Fecha: {simulacro.fecha} Nota: {Math.round(100 * simulacro.aciertos / simulacro.total) / 10}/10 ({simulacro.aciertos}/{simulacro.total})</div>
                                        </div>
                                    ))}
                                    {simulacros.length === 0 && (
                                        <div className="flex justify-center mt-[30px] font-medium">No hay simulacros recientes</div>
                                    )}
                                </ul>
                            </div>
                    
                            <div className="flex flex-col items-start xl:mt-0 mt-4">
                                <div className="text-[22px] text-[var(--color-primary)] font-medium flex gap-[10px] items-end">
                                    Temas estudiados
                                    <div className="mb-[5px]">
                                        <Information information='Desactiva aquellos temas que no te interesa que se te pregunten aún' />
                                    </div>
                                </div>
                                <hr className="h-[1px] border-none bg-[var(--color-primary)] mt-[2px] w-[90%] mr-[90%] md:w-full" />
                                <ul className="scrollable-list h-[25vh] xl:w-[15vw] w-[88%]">
                                    {mazos.map((mazo, index) => (
                                        <div key={index} className="mt-[10px] font-medium flex">
                                            <input
                                                type="checkbox"
                                                className="custom-checkbox mr-[10px] min-w-[20px] h-[20px]"
                                                value={mazo.id}
                                                checked={selectedMazoCheckboxes[mazo.id] || false}
                                                onChange={() => handleCheckboxChange(mazo.id)}
                                            />
                                            <div>{mazo.name}</div>
                                        </div>
                                    ))}
                                    {mazos.length === 0 && (
                                        <div className="flex justify-center mt-[30px] font-medium">No hay temas</div>
                                    )}
                                </ul>
                                {JSON.stringify(selectedMazoCheckboxes) !== JSON.stringify(originalMazoCheckboxes) && (
                                    <div className="flex justify-center items-center mt-[10px] w-full">
                                        <button onClick={handleCambiarTemasEstudiados()}>Confirmar cambio</button>
                                    </div>
                                )}
                            </div>
                        </div>

                    </div>
                </div> 
                )}
            </div>
            {editandoExamen && <CreateExam
                    show={editandoExamen}
                    examenes={examenes}
                    close={() => setEditandoExamen(false)}
                    exitAndRefresh={() => { closeExam()} } 
                    examUpdate={exam}
            />}
            <Popup open={eliminarExamen} onClose={()=>setEliminarExamen(false)} width='500px'>
                <div style={{display:'flex',flexDirection:'column', justifyContent:'center',padding:'10px', alignItems:'center'}}>
                    ¿Estás seguro de que quieres eliminar este examen?
                    <div style={{display:'flex', justifyContent:'center', marginTop:'20px'}}>
                    <Button 
                        className='bg-delete-color hover:bg-error-color mr-5'
                        onClick={() => setEliminarExamen(false)} 
                        disabled={eliminandoExamen}
                    >
                        Cancelar
                    </Button>
                    <Button 
                        onClick={() => handleEliminarExamen()} 
                        disabled={eliminandoExamen}
                    >
                        {!eliminandoExamen ? 'Eliminar Examen' : 'Eliminando...'}
                    </Button>
                    </div>
                </div>

            </Popup>
            {creandoSimulacro && <ExamSimulacro 
                simulacroOptBool={creandoSimulacro} 
                cerrarSimulacro={()=>setCreandoSimulacro(false)}
                empezarSimulacro={(cards,time)=>estudio(cards,3,time)}
                datos={datos}/>}
        </>
    )
}
