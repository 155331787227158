import { useEffect, useState } from 'react'
import ExamCard from '@/components/ExamCard'
import {useNavigate} from "react-router-dom";
import { Exam } from '@/types/types'
import CreateExam from '@/components/CreateExam'
import ExamDetails from '@/components/ExamDetails'
import { Card } from '@/types/db/card'
import DefaultLayout from '@/components/layout/DefaultLayout'
import Study from "@/pages/App/pages/Study";
import Button from '@/components/ui/Button'
import { useStudy } from '@/context/StudyContext';


export default function Exams() {

    const [examen, setExamen] = useState<Exam[]>([])
    const [refrescar, setRefrescar] = useState<boolean>(true)
    const [creandoExamen, setCreandoExamen] = useState<boolean>(false);
    const [examenViendo, setExamenViendo] = useState<Exam | null>(null)
    const [examenViendoDatos, setExamenViendoDatos] = useState<{
        numFlashcardsTotal: number,
        numFlashcardsStudied: number,
        numFlashcardsToday: number,
        numFlashcardsLearning: number,
        arrayEstudio: Card[],
        arrayRepaso: Card[]
    } | null>(null)

    const navigate = useNavigate();
    const { setData } = useStudy();

    useEffect(() => {
        const obtenerExamenes = async () => {
            try {
                const response = await fetch(
                    `${import.meta.env.VITE_API_URL}/exam/get_from_user`, 
                    {
                        method: 'GET',
                        headers: { 'Content-Type': 'application/json', },
                        credentials: 'include'
                    }
                )
                if (response.status === 401) {
                    return
                }
                if (!response.ok) {
                    throw new Error('Error al obtener los examenes')
                } 
                let data=await response.json()
                setExamen(data);
                setExamenViendo(null)
            } catch (error) {
                console.error('Error:', error)
            }
        }
        obtenerExamenes()
    }, [refrescar])
    /*
    const funcioneliminar = async () => {
        try {
            const response = await fetch(
                `${import.meta.env.VITE_API_URL}/exam/sortExam`,  
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', },
                    body: JSON.stringify({}),
                    credentials: 'include'
              }
            )
            if (!response.ok){
                throw new Error('Error al editar asignatura');
            }		
        } catch (error) {
            console.error('Error:', error);
            return
        }
    }*/

    const CallStudy = (tarjetas: Card[],modo: number,time: number) => {//mas crimenes de pirulo, habra juicios muy pronto
        setData({ cartas: tarjetas, modo: modo, time: time, onClickReturn: () => {} })
        navigate(`/study`)
    }

    return (
        <>
        {examenViendo ? (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '20px 0px',
            }}
            >
                <ExamDetails    
                    exam={examenViendo}
                    datos={examenViendoDatos}
                    closeExam={() => {setRefrescar(!refrescar)}}
                    examenes={examen}
                    estudio={(tarjetas,modo,time) => {CallStudy(tarjetas,modo,time)}}   
                />
            </div>
        ):
            <>
                <div className="sm:ml-[30px] ml-[20px] mr-[9vw] mt-[15px] mb-[10px]">
                    <h2>Exámenes</h2>
                </div>
                <div className="h-[1.5px] bg-[#ddd] mt-[-1.5px] sm:ml-[30px] ml-[20px] w-[90%]"/>
                <div className="flex justify-between items-end ml-[30px] mr-[9vw] mt-[15px] mb-[10px]">
                    <div className="text-[var(--color-text-light)] mr-1">
                        {examen.length === 0 ? (
                            'No hay exámenes programados'   
                        ) : examen.length === 1 ? (
                            `${examen.length} examen programado`
                        ) : (
                            `${examen.length} exámenes programados`
                        )}
                    </div>
                    <Button onClick={() => setCreandoExamen(true)} className='ml-auto'>Crear examen</Button> {/*<button onClick={() => funcioneliminar()} style={{ marginLeft: 'auto' }}>Refrescar</button>*/}
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap', marginRight: '25px' }}>
                    {examen.map((exam, index) => (
                        <div key={index} style={{ marginBottom: '30px', marginRight: '25px', marginLeft: '25px' }}>
                            <ExamCard exam={exam} seeExam={(datos) => {setExamenViendoDatos(datos);setExamenViendo(exam)}} 
                            estudio={(tarjetas,modo,time) => {CallStudy(tarjetas,modo,time)}}/>
                        </div>
                    ))}
                </div>
                <CreateExam
                    show={creandoExamen}
                    examenes={examen}
                    close={() => setCreandoExamen(false)}
                    exitAndRefresh={() => { setCreandoExamen(false); setRefrescar(!refrescar) } } 
                    examUpdate={null}
                />
            </>
        }
        </>
    )
}
