import React from 'react';
import Input from "@/components/forms/core/Input";
import { useField } from "formik";
import { tv } from "tailwind-variants";

interface Props {
    label: string;
    icon: React.ReactNode;
    name: string;
    color: "blue" | "red";
    optionName: string;
    option: string;
    disabled?: boolean;
}

const cardStyles = tv({
    base: "p-3 flex flex-col items-start rounded-lg cursor-pointer w-full bg-gray-100 !box-border",
    variants: {
        active: { true: "text-white" },
        disabled: { true: "opacity-75 cursor-not-allowed" },
        color: { "red": "", "blue": ""}
    },
    compoundVariants: [
        { active: true, color: "red", className: "bg-red-400" },
        { active: true, color: "blue", className: "bg-blue-400" },
        { active: false, color: "red", className: "text-red-400" },
        { active: false, color: "blue", className: "text-blue-400" },
    ],
});

const inputStyles = tv({
    base: "border-0 outline-0 bg-transparent text-3xl w-[120px] font-semibold text-right !box-border",
    variants: {
        active: { true: "!text-white", false: "pointer-events-none" },
        disabled: { true: "opacity-50" } },
});

const PomodoroPeriodCard: React.FC<Props> = ({ name, label, icon, color, option, optionName, disabled }) => {
    const [field, , helpers] = useField(optionName);
    const active = field.value === option;

    return (
        <div
            onClick={() => !disabled && helpers.setValue(option)}
            className={cardStyles({ active, disabled, color })}
        >
            <h1 className="text-sm font-semibold my-2">{label}</h1>
            <div className="flex justify-between items-center w-full">
                {icon}
                <div className="flex items-end gap-2">
                    <Input disabled={!active || disabled} name={name} className={inputStyles({ active, disabled })} />
                    <p className={active ? "text-white" : "text-gray-400"}>min</p>
                </div>
            </div>
        </div>
    );
};

export default PomodoroPeriodCard;