import FormInput from '@/components/forms/core/FormInput'
import {FaCalendar, FaUser} from 'react-icons/fa'

export default function RegisterFirstForm() {
    return (
        <div className='grid gap-4'>
            <div className='w-full grid md:grid-cols-2 gap-4'>
                <FormInput
                    label='Nombre' name='name' placeholder="Nombre..." />
                <FormInput label='Apellidos' name='surname' placeholder="Apellidos..."/>
            </div>
            <div className='w-full grid md:grid-cols-2 gap-4'>
                <FormInput
                    leftIcon={() => <FaUser size={17}/>}
                    label='Nombre de Usuario' name='username'
                    placeholder="Nombre de usuario..."
                />
                <FormInput
                    leftIcon={() => <FaCalendar size={17}/>}
                    label='Fecha de Nacimiento' name='birthdate' type='date'
                />
            </div>
        </div>
    )
}
