import Button, {ButtonProps} from '@/components/ui/Button';
import Spinner from '@/components/ui/Spinner';

interface LoadingButtonProps extends ButtonProps {
  loading: boolean;
}

const LoadingButton = ({loading, disabled, ...props}: LoadingButtonProps) => {

  return (
    <Button
      disabled={disabled || loading}
      {...props}
    >
      <div className='flex flex-row items-center gap-3 justify-center'>
        {loading && <Spinner fillAll={false} size='15px' color='gray' />}
        {props.children}
      </div>
    </Button>
  );
};

export default LoadingButton;
