import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {apiClient} from '@/api/client'
import {AxiosResponse} from 'axios'

interface ForgetDeckRequestData {
    deckId: string
}

interface ForgetDeckResponseData {
    message : string
}

interface ForgetDeckErrorData{
    error: string
}

export const forgetDeckRequest = async (forgetDeckData: ForgetDeckRequestData) => {
    try{
        const response = await apiClient.patch<ForgetDeckResponseData, AxiosResponse<ForgetDeckResponseData, ForgetDeckErrorData>>(
            `/mazo/forget?mazoId=${forgetDeckData.deckId}`
        )
        return response.data
    } catch(error : any){
        throw error.response.data
    }
}

export const useForgetDeck = (options: UseMutationOptions<ForgetDeckResponseData, ForgetDeckErrorData, ForgetDeckRequestData, null>) =>{
    return useMutation<ForgetDeckResponseData, ForgetDeckErrorData, ForgetDeckRequestData, null>({
        mutationFn: forgetDeckRequest,
        ...options
    })
}
