import {BiBrain, BiCheckCircle, BiText, BiVector} from "react-icons/bi";
import FlashCardModifyForm from "@/pages/App/pages/Library/pages/DetailedCard/pages/DetailedCardCreate/components/forms/FlashCardModifyForm";
import OcultarCardModifyForm from "@/pages/App/pages/Library/pages/DetailedCard/pages/DetailedCardCreate/components/forms/OcultarCardModifyForm";
import TestCardModifyForm from "@/pages/App/pages/Library/pages/DetailedCard/pages/DetailedCardCreate/components/forms/TestCardModifyForm";
import React from "react";
import {CardEditor} from "@/types/db/card";

export const cardEditorList : CardEditor[] = [
    {
        name: "Flashcard",
        value : "flashcard",
        icon: <BiBrain></BiBrain>,
        editor: <FlashCardModifyForm></FlashCardModifyForm>,
        description: "Son cartas de memorización con una pregunta y una respuesta correcta"
    },
    {
        name: "Ocultar palabra",
        value : "ocultar",
        icon: <BiText></BiText>,
        editor: <OcultarCardModifyForm></OcultarCardModifyForm>,
        description: "Son cartas de memorización que permiten ocultar palabras de la frase inicial",
        enabled: false
    },
    {
        name: "Tipo test",
        value : "test",
        icon: <BiCheckCircle></BiCheckCircle>,
        editor: <TestCardModifyForm></TestCardModifyForm>,
        description: "Las preguntas de exámen tipo test de siempre, una pregunta y varias respuestas, solo una correcta."
    }
]
