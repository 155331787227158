import {useEffect, useState} from 'react'
import Dialog from '@/components/ui/Dialog';
import Button from '@/components/ui/Button';
import FetchLayout from '@/components/layout/FetchLayout';
import {useUpdateUser} from '@/api/user/useUpdateUser';
import {useSortExams} from '@/api/exams/useSortExams';
import {useUser} from '@/api/user/useUser';

interface RestDaysDialogProps {
    open: boolean
    onClose: (refrescarTrue: boolean) => void
}

export default function RestDaysDialog({open, onClose}: RestDaysDialogProps) {
    const {data, status} = useUser();
    const {mutate: sortExams} = useSortExams({});
    const {mutateAsync: updateRestDays, isPending} = useUpdateUser({
        onSuccess: () => {
            sortExams(null);
            onClose(true);
        },
        onError: (error) => {
            console.log(error)
        }
    })

    const [selectedDays, setSelectedDays] = useState<boolean[]>(Array(7).fill(false));

    useEffect(() => {
        status === 'success' && setSelectedDays(data.dias_descanso);
    }, [data])

    const handleUpdateRestDays = async () => {
        let newUserData = data!;
        newUserData.dias_descanso = selectedDays;
        updateRestDays(newUserData);
    }

    const toggleDay = (index: number) => {
        setSelectedDays(prev => {
            const newDays = [...prev];
            newDays[index] = !newDays[index];
            return newDays;
        });
    }

    return (
        <Dialog open={open} onClose={() => onClose(false)} className='w-[500px]'>
            <FetchLayout status={status} className='flex flex-col items-center p-3 gap-3'>
                <h3>Ajustes de la organización diaria</h3>
                <h4 className='font-normal'>
                    <span className='font-bold'>Dias de descanso:</span> se organizará tu estudio para que puedas descansar los días indicados
                </h4>
                <div className='flex justify-center flex-wrap m-2'>
                    {['L', 'M', 'X', 'J', 'V', 'S', 'D'].map((day, index) => (
                        <Button
                            key={day}
                            className={`m-[5px] w-[calc(25%-10px)] md:w-[40px] h-[40px] ${selectedDays[index] ? '' : ' bg-gray-100 !text-text-color hover:bg-medium-color hover:!text-white'} border border-gray-400 rounded-full cursor-pointer flex items-center justify-center`}
                            onClick={() => toggleDay(index)}
                        >
                            {day}
                        </Button>
                    ))}
                </div>
                <Button onClick={handleUpdateRestDays} disabled={isPending || selectedDays == data?.dias_descanso}>
                    {isPending ? 'Guardando...' : 'Guardar'}
                </Button>
            </FetchLayout>
        </Dialog>
    );
}