import { ReactNode, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '@/context/auth/useAuth'
/**
 * Este componente usa useAuth para comprobar la autenticación del usuario de manera que si el usuario
 * ya se ha cargado (loading es false) y está autenticado (user es diferente de null) redirecciona a /dashboard,
 * en caso contrario si el usuario no está autenticado devuelve el children
 * @property {ReactNode} children componente de uso no autenticado
 * @returns children si el usuario no está autenticado
 */
export default function NonAuthenticatedRoute({ children }: { children: ReactNode }) {

  const {isAuth, isPending} = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if(!isPending){
      if (isAuth)
        return navigate('/home', { replace: true })
    }
  }, [isAuth])

  if (!isPending) return !isAuth ? children : null

}