import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { apiClient } from '@/api/client';
import { AxiosError } from 'axios';

interface UserStatsResponseData {
    id: string;
    racha: number;
    racha_lastDate: Date;
    minEstudioHoy: number[];
    minEstudioTot: number;
    dia_minEstudio: Date;
}

export const getUserStatsData = async (): Promise<UserStatsResponseData> => {
    const response = await apiClient.get<UserStatsResponseData>('/estadisticas/get_stats');
    return response.data;
};

export const useUserStats = (options?: UseQueryOptions<UserStatsResponseData, AxiosError>) => {
    return useQuery<UserStatsResponseData, AxiosError>({
        queryKey: ['user_stats'],
        queryFn: getUserStatsData,
        ...options,
    });
};
