import DashboardStats from "@/pages/App/pages/Dashboard/components/DashboardStats";
import PomodoroTimeBarChart from "@/pages/App/pages/Dashboard/components/PomodoroTimeBarChart";
import LearnedCardsPieChart from "@/pages/App/pages/Dashboard/components/LearnedCardsPieChart";
import LastMonthStudyBarChart from "@/pages/App/pages/Dashboard/components/LastMonthStudyBarChart";
import DashboardPendingTasks from "@/pages/App/pages/Dashboard/components/DashboardPendingTasks";
import UserProfileCard from "@/bilerncomponents/users/UserProfileCard";
import {useUser} from "@/api/user/useUser";
import SEO from "@/components/SEO";

export default function Dashboard() {
  const {data,} = useUser();

  return (
    <>
      <SEO 
        title="Bilern Dashboard" 
        description="Bienvenido a Bilern. Desde aquí podrás ver tus estadísticas de estudio, el tiempo dedicado a Pomodoro, las tarjetas aprendidas y las tareas pendientes. Mantente al tanto de tu progreso y organiza tus actividades de manera eficiente."
      />
      <h1 className="text-left text-2xl mb-5 font-semibold">
        Bienvenido de nuevo{data?.nombre && <>, <span className="font-medium">{data.nombre}</span></>}
      </h1>
      <div className="grid gap-3 lg:grid-cols-1">
        <div className="grid lg:grid-cols-5 gap-3 w-full">
          <div className="grid gap-3  w-full lg:col-span-3">
            <DashboardStats />
            <div className="lg:grid-cols-2 grid gap-3">
              <LearnedCardsPieChart />
              <PomodoroTimeBarChart />
            </div>
            <div>
              <LastMonthStudyBarChart />
            </div>
          </div>
          <div className="w-full grid lg:col-span-2 gap-3 grid-rows-[auto,1fr]">
            <UserProfileCard />
            <div className="relative">
              <div className="">
                <DashboardPendingTasks />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
