import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Task, UserTask } from '@/types/tasks';
import { getUserTasks, UserTasksResponse } from './useUserTasks';
import { getExamGeneratedTasks, UserExamGeneratedTasks } from './useExamGeneratedTasks';


export type TasksReponse = {
    [date: string]: Task[]
};

const combineUserTasksAndExamTasks = async (rawExamTasks: UserExamGeneratedTasks , rawUserTasks : UserTasksResponse) => {
    let initTasks: { [date: string]: Task[] } = {};
    // User tasks from the backend are mapped into UserTask objects
    if (rawUserTasks.length > 0) {
        for (const rawUserTask of rawUserTasks) {
        const userTask: UserTask = {
            id: rawUserTask.id,
            name: rawUserTask.content,
            completed: rawUserTask.completed,
            type: 'user',
        };
        const dateKey = rawUserTask.date;
        if (!initTasks[dateKey]) {
            initTasks[dateKey] = [];
        }
        initTasks[dateKey].push(userTask);
        }
    }
    // Exam tasks from the backend are mapped into ExamTask objects
    // (Actualmente se devuelve un diccionario, seria mejor que se devolvieran directamente)
    let examTasksMap = rawExamTasks.combinedDictionary
    for (const dateKey in examTasksMap) {
        if (!initTasks[dateKey]) {
        initTasks[dateKey] = [];
        }
        initTasks[dateKey].push(...examTasksMap[dateKey]);
    }
    return initTasks;
}

export const getAllTasks = async () => {
    const examTasks = await getExamGeneratedTasks()
    const userTasks = await getUserTasks()
    return combineUserTasksAndExamTasks(examTasks, userTasks)
}

export const useTasks = (options?: UseQueryOptions<TasksReponse, AxiosError>) => {
    return useQuery<TasksReponse, AxiosError>({
        queryKey: ['tasks'],
        queryFn: getAllTasks,
        ...options,
    });
};
