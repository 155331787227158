import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { apiClient } from '@/api/client';
import { AxiosError } from 'axios';

interface AuthStateResponseData {
  valor: boolean;
  redirectTo?: string;
}

export const getAuthState = async (): Promise<AuthStateResponseData> => {
    const response = await apiClient.get<AuthStateResponseData>('/auth/auth_state');
    return response.data;
};

export const useAuthState = (options?: UseQueryOptions<AuthStateResponseData, AxiosError>) => {
    return useQuery<AuthStateResponseData, AxiosError>({
        queryKey: ['auth_state'],
        queryFn: getAuthState,
        ...options,
    });
};
