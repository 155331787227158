import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { apiClient } from '@/api/client';
import { AxiosError } from 'axios';
import { Exam } from '@/types/types';

type ExamsResponseData = Exam[];

export const getExamsData = async (): Promise<ExamsResponseData> => {
    const response = await apiClient.get<ExamsResponseData>('/exam/get_from_user');
    return response.data;
};

export const useExams = (options?: UseQueryOptions<ExamsResponseData, AxiosError>) => {
    return useQuery<ExamsResponseData, AxiosError>({
        queryKey: ['exams'],
        queryFn: getExamsData,
        ...options,
    });
};
