import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { apiClient } from "@/api/client";
import { AxiosError } from "axios";

interface UpdatePasswordRequestData {
    email: string;
    oldPassword: string;
    newPassword: string;
}

interface UpdatePasswordResponseData {
    message: string;
}

export const updatePasswordRequest = async (updatePasswordData: UpdatePasswordRequestData) => {
    try {
        const response = await apiClient.post<UpdatePasswordResponseData>(
            "/auth/update_password",
            updatePasswordData,
            { withCredentials: true }
        );
        return response.data;
    } catch (error: any) {
        throw error.response.data;
    }
};

export const useChangeUserPassword = (
    options: UseMutationOptions<UpdatePasswordResponseData, AxiosError, UpdatePasswordRequestData, null>
) => {
    return useMutation<UpdatePasswordResponseData, AxiosError, UpdatePasswordRequestData, null>({
        mutationFn: updatePasswordRequest,
        ...options
    });
};
