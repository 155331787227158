import { ExamTask } from "@/types/tasks";
import Icon from "@/components/icons/Icon";
import { Card } from "@/types/db/card";
import { useStudy } from '@/context/StudyContext';
import {useNavigate} from "react-router-dom";
import { queryClient } from "@/api/client";

interface TaskCardProps {
    task: ExamTask
    isToday: boolean
}

function ExamTaskCard({task, isToday}: TaskCardProps) {
    const navigate = useNavigate();
    const { data, setData } = useStudy();

    const CallStudy = async () => {//mas crimenes de pirulo, habra juicios muy pronto
        const hardCopyCards = task.cards.map((card: Card) => ({...card}));
        if(task.type == 'exam'){
            setData({ cartas: hardCopyCards, modo: 1, time: 0, onClickReturn: () => {} })
            navigate(`/study`)
        } else if(task.type == 'exam_revision'){
            setData({ cartas: hardCopyCards, modo: 2, time: 0, onClickReturn: () => {} })
            navigate(`/study`)
        }
    }


    return (
        <div className="bg-white flex p-2 items-center rounded-2xl max-w-full">
            <div className="p-2 flex gap-4 items-center truncate overflow-hidden whitespace-nowrap">
                { task.totalCards == task.completedCards ? 
                    <div style={{color:'var(--color-secondary)'}}>
                        <Icon iconName='checkCircle' size='20' isClickable={false} />
                    </div>
                    :
                    <div className="w-5 h-5 bg-gray-200 rounded-full"/>
                }
                <div className="flex-1 min-w-0">
                    <p className="text-md text-gray-800 truncate overflow-hidden whitespace-nowrap">
                        <>
                            {task.type == 'exam'?'Estudiar ':'Repaso general de ' }
                            <span className="font-semibold">
                                { task.totalCards === task.completedCards ?
                                    task.totalCards :
                                    task.totalCards - task.completedCards
                                }
                            </span> 
                            <span className="font-semibold"> preguntas </span>de {task.name} 
                        </>
                    </p>
                </div>
            </div>
            {isToday && task.totalCards != task.completedCards && 
                <div className=" mr-2 ml-auto hidden group-hover:!flex" >
                    <Icon iconName='circlePlay' className="text-[--color-primary] hover:text-[#38BF78] duration-150" size='28' isClickable={true} onClick={()=>CallStudy()}/>
                </div>
            }
        </div>
    );
}

export default ExamTaskCard;