import DashboardElementContainer from "@/pages/App/pages/Dashboard/components/DashboardElementContainer";
import { useTasks } from "@/api/tasks/useTasks";
import TasksView from "../../Schedule/pages/Tasks/components/TasksView";
import TaskCard from "@/pages/App/pages/Schedule/pages/Tasks/components/TaskCard";
import { format } from "date-fns";
import Button from "@/components/ui/Button";
import { useNavigate } from "react-router-dom";
import AddTaskInlineForm from "../../Schedule/pages/Tasks/components/AddTaskInlineForm";
import DashboardPendingTasksContainer from "./DashboardPendingTasksContainer";

function DashboardPendingTasks() {
    const {data: tasks, status} = useTasks();
    const navigate = useNavigate();
    const today = format(new Date(), "yyyy-MM-dd")

    return (
        <DashboardPendingTasksContainer 
            className="absolute inset-0 overflow-y-auto min-h-80 group/pepe"
            empty={(tasks?.[today] ?? []).length === 0}
            emptyData={{title:'No hay tareas previstas para hoy'}}
            title="Tareas para hoy"
            status={[status,]}
            rightButtons={
                <a className="text-base py-1 text-gray-500 font-semibold px-3 hover:text-gray-700 rounded-lg bg-gray-300 opacity-0 group-hover/pepe:!opacity-100" onClick={() => navigate('/schedule/tasks')}>Ir a tareas</a>
            }
        > 
            { tasks &&
                <div className="grid grid-cols-1 gap-3 mt-0 mx-3 mb-2 py-7" >
                    {tasks[today]?.map((task, i) => (
                        <div key={i.toString()} className="w-full max-w-full">
                            <TaskCard 
                                task={task}
                                isToday={true}
                            />
                        </div>
                    ))}
                    <AddTaskInlineForm date={new Date()}></AddTaskInlineForm>
                </div>
            }
        </DashboardPendingTasksContainer>
    );
}

export default DashboardPendingTasks;