import { Mark, mergeAttributes } from '@tiptap/core'

export interface OcultarOptions {
  HTMLAttributes: Record<string, any>,
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    ocultar: {
      setOcultar: () => ReturnType,
      toggleOcultar: () => ReturnType,
      unsetOcultar: () => ReturnType,
    }
  }
}

/**
 * Ocultar - Mark de tiptap para ocultar texto.
 * @returns span que contiene el contenido original con el
 * atributo elemento-oculto a true y la clase oculto.
 * @see https://tiptap.dev/docs/editor/extensions/custom-extensions/create-new#create-a-mark
 * (La documentación para la creación de es escasa, casi inexistente, y por ello esto es una copia de bold).
 */
const Ocultar = Mark.create<OcultarOptions>({
  name: 'ocultar',

  addOptions() {
    return {
      HTMLAttributes: {},
    }
  },

  parseHTML() {
    return [
      {
        tag: 'span[elemento-oculto]',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', mergeAttributes(HTMLAttributes, { 'elemento-oculto': 'true', 'class': 'oculto' }), 0]
  },

  addCommands() {
    return {
      setOcultar: () => ({ commands }) => {
        return commands.setMark(this.name)
      },
      toggleOcultar: () => ({ commands }) => {
        console.log(this.name)
        return commands.toggleMark(this.name)
      },
      unsetOcultar: () => ({ commands }) => {
        return commands.unsetMark(this.name)
      },
    }
  },

  addKeyboardShortcuts() {
    return {
      'Mod-o': () => this.editor.commands.toggleOcultar(),
      'Mod-O': () => this.editor.commands.toggleOcultar(),
    }
  }

}) 
export default Ocultar
