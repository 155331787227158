import {useMutation, UseMutationOptions} from "@tanstack/react-query";
import {apiClient} from "@/api/client";
import {AxiosError} from "axios";

interface CreateSubjectRequestData {
    name : string
}

interface CreateSubjectResponseData {
    id: number;
    name: string;
}

export const createSubjectRequest = async (createSubjectData: CreateSubjectRequestData) => {
    try{
        const response = await apiClient.post<CreateSubjectResponseData>(
            '/folder/create', createSubjectData
        );
        return response.data;
    }catch(error : any){
        throw error.response.data
    }
};

export const useCreateSubject = (options: UseMutationOptions<CreateSubjectResponseData, AxiosError, CreateSubjectRequestData, null>) =>{
    return useMutation<CreateSubjectResponseData, AxiosError, CreateSubjectRequestData, null>({
        mutationFn: createSubjectRequest,
        ...options
    })
}
