// Cancel Subscription
import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { apiClient } from "@/api/client";
import { AxiosError } from "axios";

export const renewSubscriptionRequest = async () => {
    const response = await apiClient.put('/pay/renew_subscription', {}, { withCredentials: true });
    if (response.status !== 200) throw new Error('Error al renovar la subscripcion');
    return response.data;
};

export const useRenewSubscription = (options: UseMutationOptions<any, AxiosError, void, void>) => {
    return useMutation<any, AxiosError, void, void>({
        mutationFn: renewSubscriptionRequest,
        ...options
    });
};