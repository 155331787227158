import React from 'react';
import Spinner from "@/components/Spinner";
import {FaCheck} from "react-icons/fa";
import {Link, useNavigate} from "react-router-dom";
import {IoMdClose} from "react-icons/io";

function CardGenerationProcessDoneForm() {

    const navigate = useNavigate()

    return (
        <div className='h-full flex flex-col py-10 items-center justify-center gap-20'>
                <>
                    <div className='flex flex-col items-center justify-center '>
                        <div
                            className="w-[150px] h-[150px] bg-brand_secondary-500 flex justify-center items-center text-white rounded-full">
                            <FaCheck size={50}></FaCheck>
                        </div>
                        <h1 className='text-secondary-color font-bold mb-4 text-2xl mt-12'>Generación terminada</h1>
                        <h3 className='text-xl text-gray-500'>
                            La generación de flashcards de ha realizado correctamente.
                        </h3>
                        <button
                            onClick={()=> navigate(-1)}
                            className='mt-10 bg-brand_secondary-500 font-semibold rounded-xl text-white hover:!bg-brand_secondary-700 text-sm !px-10 py-3'>Terminar
                        </button>
                    </div>
                </>

        </div>
    );
}

export default CardGenerationProcessDoneForm;