import FilePageView from "@/pages/App/pages/Library/pages/DetailedCard/pages/DetailedCardCreateAi/components/FilePageView";
import { usePdf } from "@/hooks/usePdf";
import HorizontalScroll from "@/components/ui/HorizontalScroll";
import { useField } from "formik";
import {BiBug} from "react-icons/bi";
import {Link} from "react-router-dom";
import {useEffect} from "react";

interface Props {
    file: File;
    name: string;
}

const FormFilePagePickerField = ({ file, name }: Props) => {
    const { numPages, fileUrl, isFetching } = usePdf(file);
    const [field, , helpers ] = useField<Set<number>>(name);
    const [, meta , ] = useField("files")

    useEffect(() => {
        if(!field.value) {
            field.value = new Set()
            helpers.setValue(field.value);
        }
    }, [])

    if (isFetching ) return <>Loading...</>;
    if(!field.value) return <>Filling up component...</>;

    const togglePageSelection = async (pageNumber: number) => {
        const updatedSet = new Set(field.value);
        updatedSet.has(pageNumber) ? updatedSet.delete(pageNumber) : updatedSet.add(pageNumber);
        await helpers.setValue(updatedSet);
    };

    const selectAllFiles = async () => {
        const set = new Set<number>();
        (new Array(numPages)).fill(0).forEach((_, i) => {
            set.add(i)
        });
        await helpers.setValue(set);
    }

    const deselectAllFiles = async () => {
        await helpers.setValue(new Set())
    }

    return (
        <>
            <div className="w-full justify-between flex items-center">
                <h2 className="text-lg text-gray-800 mb-2">
                    {file.name} <span className="ml-2 font-normal text-gray-500">{field.value.size}/{numPages}</span>
                </h2>
                <div className="flex gap-2">
                    {
                        field.value.size > 0 &&
                        <button onClick={() => deselectAllFiles()} className="cursor-pointer bg-brand_primary-500 font-semibold text-white px-3 py-1 rounded-full">
                            Deseleccionar todo
                        </button>
                    }
                    {
                        field.value.size < (numPages?? 1000) &&
                        <button onClick={() => selectAllFiles()} className="cursor-pointer bg-brand_secondary-500 font-semibold text-white px-3 py-1 rounded-full">
                            Seleccionar todo
                        </button>
                    }
                </div>
            </div>
            <div className='overflow-hidden'>
                <HorizontalScroll>
                    {[...Array(numPages)].map((_, pageNumber) => (
                        <div
                            key={pageNumber}
                            className="w-fit flex justify-center flex-col items-center cursor-pointer"
                            onClick={() => togglePageSelection(pageNumber)}
                        >
                            <div className={`overflow-hidden rounded-xl border-1 border-solid border-transparent 
                                ${field.value.has(pageNumber) ? (meta.error ?  "!border-red-500" : "!border-brand_secondary-500") : ""}
                            `}>
                                <FilePageView fileUrl={fileUrl} pageNumber={pageNumber + 1} />
                            </div>
                            <p className="text-gray-500 font-semibold mt-2">Página {pageNumber + 1}</p>
                        </div>
                    ))}
                </HorizontalScroll>
            </div>

            {
                meta.error && field.value.size > 0 &&
                <p className="text-red-500 flex gap-1 items-center">
                    <BiBug></BiBug>
                    {
                        meta.error
                    }
                    <Link to="/pricing" className="text-red-500 hover:text-red-600 underline">Cambiar plan</Link>
                </p>
            }
        </>
    );
};

export default FormFilePagePickerField;
