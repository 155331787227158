import React from 'react';
import {Outlet} from "react-router";
import LibrarySidebar from "@/pages/App/pages/Library/components/LibrarySidebar";
import {BiChevronLeft} from "react-icons/bi";

function Library() {
    return (
        <div className="relative h-full">
            <div  className="w-full md:w-[330px] z-1 md:fixed h-fit md:h-[87vh] box-border">
                <LibrarySidebar/>
            </div>
            <div className="md:ml-[330px] box-border">
                <section className="text-left m-0">
                    <div className="md:pl-5 lg:px-5 xl:px-8 px-1 pt-4">
                        <Outlet></Outlet>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default Library;