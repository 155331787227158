import React, {useEffect, useState} from 'react';
import Spinner from "@/components/Spinner";

const frases: string[] = [
    "Estamos organizando tus ideas... 🧠 ¡Un momento por favor!",
    "Transformando datos en flashcards inteligentes... 🚀",
    "La magia de la IA está en camino... ✨",
    "Procesando tu contenido... casi listo para estudiar 📚",
    "Preparando tu material de aprendizaje... ¡un segundo! 🕒",
    "No te preocupes, la IA probablemente solo está buscando esto en Google... 🤔",
    "Tu paciencia es tan impresionante como la cuestionable velocidad de la IA... 🐌",
    "¿Sabías que ver crecer la hierba puede ser más rápido que esto? 🌱",
    "Las tarjetas llegarán pronto… o al menos eso es lo que la IA quiere que creas. 🤖",
    "¿Qué tienen en común Chofa, Shizuka y Froilán? Que solo nosotros sabemos por qué están en este chiste.🤍",
    "Buscando en el sótano de Eren las respuestas a tus preguntas... 🔑",
    "Consultando en la biblioteca de Alejandría... 📚",
    "Consultando en la sección prohibida del castillo mágico... 🧙‍♂️",
    "Inyectando 3 TB de malware en tu dispositivo... ¡Es broma! O no... 🦠",
    "¿Sabías que el 99% de las veces la IA no sabe lo que está haciendo? 🤖",
    "Estas tarjetas van a ser tan emocionantes como Stephen Bunting con Titanium de fondo... 🎶",
    "Con estas cartas tu precisión será tan alta como la de Luke Littler acertando el triple 20... 🎯",
    "Jane Goodall estudió a los chimpancés, nosotros estudiamos tus apuntes... 🐒",
    "La IA está trabajando duro, ¡prometido! 💪",
    "Tus flashcards estarán listas en un abrir y cerrar de ojos... 🧙‍♂️",
    "La espera valdrá la pena, ¡lo prometemos! 🌟",
    "Estamos afinando los últimos detalles... 🎻",
    "Tus tarjetas están a punto de materializarse... 🃏",
    "La IA está haciendo su magia... 🪄",
    "Estamos preparando algo increíble para ti... 🎁",
    "La IA está dando los últimos retoques... 🎨",
    "Estamos casi listos para mostrarte tus tarjetas... 👀",
    "Tus tarjetas estarán listas en un instante... ⚡",
];

function GenerationInProcess() {
    const [random, setRandom] = useState(0)

    function generateRandomLoop(){
        setRandom(Math.floor(Math.random() * frases.length))
        setTimeout(() => {
            generateRandomLoop()
        }, 5000)
    }

    useEffect(() => {
        generateRandomLoop()
    }, [])


    return (
        <div className='mt-20 h-full flex flex-col py-10 items-center justify-center gap-5'>
            <Spinner size={70} color="var(--color-secondary)" bgColor="#f5f5f5" />
            <h3 className="mt-10 text-brand_secondary-400 text-md font-semibold">{frases[random]}</h3>
        </div>
    );
}

export default GenerationInProcess;