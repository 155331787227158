import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {EducationType} from '@/types/db/user'
import {queryClient} from '@/api/client'
import {apiClient} from '@/api/client'
import {AxiosResponse} from 'axios'

interface CreateUserRequestData {
    name: string
    surname: string
    username: string
    birthdate: string
    location?: string
    education: EducationType
    institution?: string
    qualification?: string
    goal?: string
}

interface CreateUserResponseData {
    message : string
}

interface CreateUserErrorData {
    error: string
}

export const registerRequest = async (userData: CreateUserRequestData) => {
    try{
        const response = await apiClient.post<CreateUserResponseData, AxiosResponse<CreateUserResponseData, CreateUserErrorData>>(
            '/user/create', userData
        )
        queryClient.clear();
        return response.data
    } catch(error: any){
        throw error.response.data
    }
}

export const useCreateUser = (options: UseMutationOptions<CreateUserResponseData, CreateUserErrorData, CreateUserRequestData, null>) =>{
    return useMutation<CreateUserResponseData, CreateUserErrorData, CreateUserRequestData, null>({
        mutationFn: registerRequest,
        ...options
    })
}