import * as Yup from 'yup'

export const emailValidation = Yup.string()
  .email('El formato del correo no es válido')
  .min(2)
  .max(50, 'El correo no puede contener más de 50 caracteres')
  .required('El correo es obligatorio')

export const passwordValidation = Yup.string()
  .min(6, 'La contraseña debe tener al menos 6 caracteres')
  .max(50, 'La contraseña no puede contener más de 50 caracteres')
  .required('La contraseña es obligatoria')

export const confirmPasswordValidation = Yup.string()
  .oneOf([Yup.ref('password')], 'Las contraseñas deben coincidir')
  .required('Debes confirmar la contraseña')
