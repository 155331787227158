import { ReactNode, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '@/context/auth/useAuth'

/**
 * Este componente usa useAuth para comprobar la autenticación del usuario de manera que si el usuario
 * ya se ha cargado (loading es false) y no está autenticado (user es null) redirecciona a /login,
 * en caso contrario si el usuario está autenticado devuelve el children
 * @property {ReactNode} children componente de uso autenticado
 * @returns children si el usuario está autenticado
 */
export default function AuthenticatedRoute({ children }: { children: ReactNode }) {
  const {isAuth, isPending, redirectTo} = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if(!isPending){
      if (!isAuth) {
        return navigate('/auth', { replace: true })
      }
      if (redirectTo) {
        return navigate(redirectTo, { replace: true })
      }
    }
  }, [isAuth, isPending])

  if (!isPending) return isAuth ? children : null

}
