import {Outlet} from "react-router";
import {ToastProvider} from "@/components/ui/ToastContext";
import { TasksProvider } from "@/context/TasksContext";
import { StudyProvider } from "@/context/StudyContext";
import Header from "@/components/common/header/Header";

function Application() {
    return (
        <StudyProvider>
        <TasksProvider>
        <ToastProvider>
            <Header />
            <div className="w-full flex justify-center items-center">
                <div className="max-w-[1500px]  p-3 w-full mt-20 rounded-2xl mb-10">
                    <Outlet />
                </div>
            </div>
        </ToastProvider>
        </TasksProvider>
        </StudyProvider>
    );
}

export default Application;