import {BiTrash} from "react-icons/bi";
import {UserTask} from "@/types/tasks";
import Icon from "@/components/icons/Icon";
import { useModifyTask } from "@/api/tasks/useModifyTask";
import { useDeleteTask } from "@/api/tasks/useDeleteTask";
import { queryClient } from "@/api/client";
import Form from "@/components/forms/core/Form";
import {FormIconButton} from "@/components/forms/core/FormIconButton";

interface TaskCardProps {
    task: UserTask
}

function TaskCard({task}: TaskCardProps) {

	console.log(queryClient.getQueryData(["tasks"]))
    const {mutateAsync: deleteTask} = useDeleteTask({
		onSuccess: async (x) => {
			await queryClient.invalidateQueries({queryKey: ['tasks']})
		}
	});
    const {mutateAsync: updateTask} = useModifyTask({
		onSuccess: async () => {
			await queryClient.invalidateQueries({queryKey: ['tasks']})
		}
	});

    function toggleCompletedTask() {
        task.completed = !task.completed;
        updateTask(task as UserTask);
    }

    return (
		<div className="bg-white flex items-center rounded-lg p-2 max-w-full justify-between">
			<div className="p-2 flex gap-4 items-center truncate overflow-hidden whitespace-nowrap">
				{ task.completed ?
					<div style={{color:'var(--color-secondary)'}}>
						<Icon iconName='checkCircle' size='20' isClickable={true} onClick={toggleCompletedTask}/>
					</div>
					: 
					<div className="w-5 h-5 bg-gray-200 rounded-full cursor-pointer" onClick={toggleCompletedTask}/>					
				}
				<div className="flex-1 min-w-0">
					<div className="text-md text-gray-800 truncate overflow-hidden whitespace-nowrap">
						{task.name}
					</div>
				</div>
			</div>
			<Form initialValues={{}} onSubmit={async ()=> await deleteTask(task)}>
				<FormIconButton icon={<BiTrash />} size="sm"/>
			</Form>
		</div>
    );
}

export default TaskCard;