import React, { Suspense } from 'react'
import { Route, Routes, Navigate} from 'react-router-dom'
import Login from '@/pages/Auth/pages/Login'
import Register from '@/pages/Auth/pages/Register/index'
import UserSetup from '@/pages/App/pages/UserSetup/index'
import Terminos from '@/pages/Terminos'
import Tutorial from '@/pages/Tutorial'
import ImportFromQr from '@/pages/ImportFromQr'
import AuthenticatedRoute from '@/context/auth/AuthenticatedRoute'
import NonAuthenticatedRoute from '@/context/auth/NonAuthenticatedRoute'
import SubscriptionRestrictedRoute from './context/auth/SubscriptionRestrictedRoute'
import { UpdateFoldersProvider } from '@/context/UpdateFoldersContext'
import { PomodoroProvider } from '@/context/ClockContext'

// TEMPORAL PARA QUE ESTADISTICAS Y SEGUIMIENTO SE VEAN CON HEADER
import {QueryClientProvider} from "@tanstack/react-query";
import {queryClient} from "@/api/client";
import ResetPassword from './pages/Auth/pages/ResetPassword'
import ForgetPassword from './pages/Auth/pages/ForgetPassword'
import GoogleAuthLoading from './pages/Auth/pages/GoogleAuthLoading'
import Auth from "@/pages/Auth";
import Application from "@/pages/App";
import Dashboard from "@/pages/App/pages/Dashboard";
import Support from "@/pages/App/pages/Support";
import Library from "@/pages/App/pages/Library";
import Schedule from "@/pages/App/pages/Schedule";
import Exams from '@/pages/App/pages/Exams'
import Pomodoro from "@/pages/App/pages/Schedule/pages/Pomodoro";
import Tasks from "@/pages/App/pages/Schedule/pages/Tasks";
import DetailedSubject from "@/pages/App/pages/Library/pages/DetailedSubject";
import DetailedDeck from "@/pages/App/pages/Library/pages/DetailedDeck";
import NoSubjectSelected from "@/pages/App/pages/Library/pages/NoSubjectSelected";
import CardDetailed from "@/pages/App/pages/Library/pages/DetailedCard";
import DetailedCardCreate from "@/pages/App/pages/Library/pages/DetailedCard/pages/DetailedCardCreate";
import DetailedCardModify from "@/pages/App/pages/Library/pages/DetailedCard/pages/DetailedCardModify";
import DetailedCardCreateAi from "@/pages/App/pages/Library/pages/DetailedCard/pages/DetailedCardCreateAi";
import Study from "@/pages/App/pages/Study";
import Settings from "@/pages/App/pages/Settings";
import PricingPage from "@/pages/Pricing";
import { ToastProvider } from '@/components/ui/ToastContext'
//<Route path='/tutorial' element={<Tutorial />} />
export default function App() {

	return (
		<Suspense fallback={<h1>Cargando Bilern...</h1>}>
			<QueryClientProvider client={queryClient}>
				<UpdateFoldersProvider>
				<PomodoroProvider>
				<Routes>
					<Route path="*" element={<Navigate to="/auth" />} />
					<Route path='/auth'  element={<NonAuthenticatedRoute><Auth/></NonAuthenticatedRoute>}>
						<Route index={true} element={<Login />} />
						<Route path='register' element={<Register />} />
						<Route path='password_forget' element={<ForgetPassword />} />
						<Route path='password_reset' element={<ResetPassword />} />
						<Route path='google_auth_loading' element={<GoogleAuthLoading />} />
					</Route>
					<Route path="/" element={<AuthenticatedRoute><Application/></AuthenticatedRoute>}>
						<Route path='' element={<Navigate to="home" />} />
						<Route path='home' element={<Dashboard />} />
						<Route path='dashboard' element={<Library />}>
							<Route index={true} element={<NoSubjectSelected/>} />
							<Route path='subject/:subject_id' element={<DetailedSubject/>} />
							<Route path='subject/:subject_id/deck/:deck_id' element={<DetailedDeck/>} />
							<Route path='subject/:subject_id/deck/:deck_id/card/' element={<CardDetailed/>} >
								<Route index element={<DetailedCardCreate/>} />
								<Route path="ai" element={<DetailedCardCreateAi/>} />
								<Route path=':card_id' element={<DetailedCardModify/>} />
							</Route>
						</Route>
						<Route path='settings' element={<Settings />} />
						<Route path='examenes' element={<SubscriptionRestrictedRoute><Exams /></SubscriptionRestrictedRoute>} />
						<Route path='schedule' element={<Schedule />} >
							<Route index element={<Navigate to="tasks" />} />
							<Route path='pomodoro' element={<Pomodoro />} />
							<Route path='tasks' element={<Tasks />} />
						</Route>
						<Route path='study' element={<Study />} />
						<Route path='soporte' element={<Support />} />
					</Route>
					<Route path='/terminos' element={<Terminos />} />
					<Route path='/pricing' element={<ToastProvider><PricingPage /></ToastProvider>} />
					<Route path='/user_setup' element={<AuthenticatedRoute><UserSetup /></AuthenticatedRoute>} />
					<Route path='/codigos/:code' element={<ImportFromQr />} />
				</Routes>
				</PomodoroProvider>
				</UpdateFoldersProvider>
			</QueryClientProvider>
		</Suspense>
	)
}
