import React, {useState} from 'react';
import {BiDotsHorizontal, BiDotsVertical, BiEdit, BiFolder, BiRedo, BiSolidLowVision, BiTrash} from "react-icons/bi";
import {IoFolder} from "react-icons/io5";
import {useNavigate} from "react-router-dom";
import {TbDots} from "react-icons/tb";
import {Subject} from "@/types/db/subject";
import EntityMenu from "@/pages/App/pages/Library/components/EntityMenu";
import {Tooltip, useEditable} from '@ark-ui/react';
import SubjectEditInlineForm from "@/pages/App/pages/Library/components/SubjectCard/SubjectEditInlineForm";
import DeleteSubjectDialog from './DeleteSubjectDialog';
import ForgetSubjectDialog from './ForgetSubjectDialog';
import {useModifySubject} from "@/api/subjects/useModifySubject";
import {queryClient} from "@/api/client";

type DialogType = 'delete' | 'forget';

interface SubjectCardProps {
    subject: Subject,
    active?: boolean
}

function SubjectCard({subject, active}: SubjectCardProps) {
    const navigate = useNavigate();
    const [dialog, setDialog] = useState<null | DialogType>(null)
    const {mutate} = useModifySubject({
        onSuccess: () => queryClient.invalidateQueries({
            queryKey: ['subjects']
        })
    })
    const editable = useEditable({
        placeholder: subject.name,
        onValueCommit: (commit) => mutate({subjectId: subject.id, newName: commit.value}),
        required: true,
        defaultValue: subject.name,
        activationMode: 'dblclick'
    })

    function generateTextCaption(text: string, maxLength: number): string {
        if (text.length < maxLength) return text;
        return text.slice(0, maxLength) + '...';
    }


    function getItems() {
        return [
            {
                title: "Olvidar",
                icon: <BiSolidLowVision></BiSolidLowVision>,
                callback: ()  => setDialog('forget')
            },
            {
                title: "Editar",
                icon: <BiEdit></BiEdit>,
                callback: () => { setTimeout(() => editable.edit(), 100) }
            },
            {
                title: "Eliminar",
                trash: true,
                icon: <BiTrash></BiTrash>,
                callback: () => setDialog('delete')
            }
        ];
    }

    return (<>
                <div onClick={() => navigate("subject/"+subject.id)} className={`w-full ${active ? 'bg-brand_primary-500 text-white' : 'bg-gray-200 text-gray-500' } py-2 px-3 box-border cursor-pointer rounded-lg`}>
                    <div className="flex items-center gap-2 justify-between">
                        <div className="flex items-center gap-2 justify-between">
                            <IoFolder className="" size={20}></IoFolder>
                            <SubjectEditInlineForm name={generateTextCaption(subject.name, 15)} value={editable} />
                        </div>
                        <EntityMenu items={getItems()}>
                            <div className={`h-[25px]  w-[25px] ${active ? 'bg-white bg-opacity-20 text-white' : 'bg-gray-200 hover:bg-gray-300'} flex justify-center items-center rounded-full `}>
                                <TbDots></TbDots>
                            </div>
                        </EntityMenu>
                    </div>
                </div>
            <DeleteSubjectDialog subjectId={subject.id} open={dialog =='delete'} onClose={() => setDialog(null)} />
            <ForgetSubjectDialog subjectId={subject.id} open={dialog =='forget'} onClose={() => setDialog(null)} />
    </>);
}

export default SubjectCard;
