import React from 'react';
import FormCardTypeSelector
    from "@/pages/App/pages/Library/pages/DetailedCard/pages/DetailedCardCreateAi/components/form/fields/FormCardTypeSelector";

function PickCardTypeForm() {
    return (
        <div className="py-10">
            <div className="w-full justify-center flex mb-5">
                <h1 className="text-3xl text-gray-800">¿Que tipo de cartas quieres generar?</h1>
            </div>

            <FormCardTypeSelector name="cardType"></FormCardTypeSelector>
        </div>
    );
}

export default PickCardTypeForm;