import FormikEditorEditable from "@/pages/App/pages/Library/pages/DetailedCard/pages/DetailedCardCreate/components/forms/fields/FormikEditorEditable";
import {allToolkitButtons} from "@/components/forms/TipTapEditorForm/utils";

export default function OcultarCardModifyForm() {

  return(
      <div className='w-full flex flex-col items-center justify-center p-4 box-border'>
          <FormikEditorEditable toolkit={allToolkitButtons} className="min-h-52" editorClass="min-h-40" name={"text"} label="Enunciado" placeholder="Añade una respuesta"></FormikEditorEditable>
      </div>
  )
}
