import {useMutation, UseMutationOptions} from "@tanstack/react-query";
import {apiClient} from "@/api/client";
import {AxiosError} from "axios";

interface DeleteTaskRequestData {
    id : string
}

interface DeleteTaskResponseData {
    message : string
}

export const deleteTaskRequest = async (deleteTaskData: DeleteTaskRequestData) => {
    try{
        const response = await apiClient.delete<DeleteTaskResponseData>(
            `/task/delete?id=${deleteTaskData.id}`
        );
        return response.data;
    }catch(error : any){
        throw error.response.data
    }
};

export const useDeleteTask = (options: UseMutationOptions<DeleteTaskResponseData, AxiosError, DeleteTaskRequestData, null>) =>{
    return useMutation<DeleteTaskResponseData, AxiosError, DeleteTaskRequestData, null>({
        mutationFn: deleteTaskRequest,
        ...options,
    })
}