import { useQuery, UseQueryOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { apiClient } from '@/api/client';
import { Deck } from '@/types/db/deck';

type DeckResponseData = Deck;

export const getDeckData = async (deckId: string): Promise<DeckResponseData> => {
    const response = await apiClient.get<DeckResponseData>(`/mazo?deck_id=${deckId}`); // NO IMPLEMENTADO AUN EN EL BACK !!!
    let responseDeck = await apiClient.get(`/card/get_for_study?mazoId=${response.data.id}`);
    response.data['Array'] = responseDeck.data.Array;
    response.data['modoEstudio'] = responseDeck.data.modoEstudio;
    return response.data;
};

export const useDeck = (deckId: string, options?: UseQueryOptions<DeckResponseData, AxiosError>) => {
  const queryClient = useQueryClient();
    return useQuery<DeckResponseData, AxiosError>({
        queryKey: ['deck', deckId],
        queryFn: () => getDeckData(deckId),
        enabled: !!deckId, // Solo habilita la consulta si deckId no es una cadena vacía
        initialData: () => {
          const decks = queryClient.getQueryData<DeckResponseData[]>(['decks']);
          return decks?.find(deck => deck.id === deckId);
        },
        ...options,
    });
};
