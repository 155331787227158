import {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import logo from '@/assets/logo.png'
import SEO from '@/components/SEO'
import ResetPasswordForm from './components/ResetPasswordForm'

export default function ResetPassword() {
	const [token, setToken] = useState<string | undefined>(undefined)
	const [email, setEmail] = useState<string | undefined>(undefined)
	const navigate = useNavigate()

	useEffect(() => {
    const urlParams = new URLSearchParams(window.location.hash.substring(1))
    const accessToken = urlParams.get('access_token')
	const email = urlParams.get('email')
    if (accessToken && email){
		setToken(accessToken)
		setEmail(email)
	} 
    else navigate('/login')
  }, [navigate])

	return (<>
		<SEO title='Restablecer Contraseña - Bilern' />
		<main className='flex justify-center items-center h-min-[100vh] h-full'>
			<div className='max-w-[400px] max-h-[600px] w-full h-2/3 flex justify-center flex-col items-start p-10 rounded-3xl shadow-xl'>
				<img src={logo} alt='Bilern logo' className='w-[150px] mb-5' />
				<div className='flex flex-col gap-1 w-full text-left'>
					<h1 className='text-2xl text-gray-700 font-semibold m-0'>Cambia tu contraseña</h1>
					<h3 className='text-base text-gray-500 font-medium m-0'>Y esta vez intenta no volverla a olvidar...</h3>
				</div>
				<div className='w-full my-12 flex flex-col gap-4'>
					{ token && email &&
						<ResetPasswordForm accessToken={token} email={email}/>
					}
				</div>
			</div>
		</main>
	</>)
}
