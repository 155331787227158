import React from 'react';
import FormTipTapEditor from "@/components/forms/TipTapEditorForm";
import { Editable } from '@ark-ui/react';
import {useField} from "formik";
import {ToolkitButton, allToolkitButtons} from "@/components/forms/TipTapEditorForm/utils";

interface props extends React.HTMLProps<HTMLDivElement>{
    name: string,
    label ?: string,
    placeholder?: string,
    editorClass?: string,
    toolkit: ToolkitButton[]
}

function FormikEditorEditable({name, placeholder, label, editorClass, toolkit, ...other} : props) {
    const [field, meta, helpers] = useField(name)

    return (
        <Editable.Root className={`min-h-28 items-stretch flex justify-center w-full p-1  box-border rounded-xl ` + other.className} placeholder="enter a value" defaultValue="Chakra">
            <Editable.Area className="w-full flex justify-center">
                <Editable.Preview asChild={true}>
                    <div className="p-1 lg:p-4 box-border w-full h-full hover:bg-gray-200 cursor-pointer rounded-xl font-semibold">
                        {
                            field.value ?
                                <>
                                    <div className="text-sm text-gray-500 mb-5">{label}</div>
                                    <div className="flex flex-col gap-2 font-normal" dangerouslySetInnerHTML={{__html: field.value}} />
                                </>
                                :
                                <div className="w-full flex justify-center items-center h-full">
                                    + {placeholder}
                                </div>

                        }
                    </div>
                </Editable.Preview>
                <Editable.Input asChild={true}>
                    <Editable.Context>
                        {
                            (context) => (
                                context.editing &&
                                <FormTipTapEditor onBlur={(event) => {
                                    if (!event.currentTarget.contains(event.relatedTarget)) {
                                        context.cancel()
                                    }
                                }} name={name} label={label} editorClass={editorClass} toolkit={toolkit}></FormTipTapEditor>
                            )
                        }
                    </Editable.Context>
                </Editable.Input>
            </Editable.Area>
        </Editable.Root>
    );
}

export default FormikEditorEditable;