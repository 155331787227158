import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { apiClient } from '@/api/client';
import { AxiosError } from 'axios';

interface FolderCardsStats {
    studied: number;
    studying: number;
    notStudied: number;
    fechasEstudiando: { [key: string]: number };
    fechasPorEstudiar: { [key: string]: number };
    fechasEstudiadas: { [key: string]: number };
};

type LearnedCardsResponseData = FolderCardsStats;

export const getLearnedCardsData = async (): Promise<LearnedCardsResponseData> => {
    const response = await apiClient.get<LearnedCardsResponseData>('/estadisticas/cards_stats');
    return response.data;
};

export const useLearnedCards = (options?: UseQueryOptions<LearnedCardsResponseData, AxiosError>) => {
    return useQuery<LearnedCardsResponseData, AxiosError>({
        queryKey: ['learned_cards'],
        queryFn: getLearnedCardsData,
        ...options,
    });
};
