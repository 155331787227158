import SubjectCard from "@/pages/App/pages/Library/components/SubjectCard/index";
import {useParams} from "react-router-dom";
import {useSubjects} from "@/api/subjects/useSubjects";
import AddSubjectInlineForm from "@/pages/App/pages/Library/components/AddSubjectInlineForm";
import {BiSearch} from "react-icons/bi";
import {useState} from "react";
import Index from "@/components/layout/FetchLayout";
import FetchLayout from "@/components/layout/FetchLayout";

function LibrarySidebar() {
    const {data: subjectsData, status: subjectsStatus} = useSubjects();
    const [nameFilter, setNameFilter] = useState("")
    const { subject_id } = useParams();

    return (
        <div className="w-full bg-gray-100 h-full p-5 text-left rounded-2xl overflow-hidden relative box-border">
            <h1 className="text-xl font-bold m-0 text-gray-700">
                Asignaturas
            </h1>
            <div className="w-full box-border  bg-white rounded-lg mt-3 flex items-center px-2" >
                <BiSearch className="text-gray-500"></BiSearch>
                <input value={nameFilter} onFocus={e => e.target.select()} onChange={e => setNameFilter(e.target.value)} className="w-full bg-transparent box-border outline-none border-0 shadow-none px-3 py-2 rounded-lg" placeholder="Busca tus asignaturas..."/>
            </div>
            <div className="mt-3 h-full overflow-auto">
                <FetchLayout emptyComponent={<AddSubjectInlineForm/>} isEmpty={(subjectsData?.length??0) < 1} status={subjectsStatus} className="flex flex-col gap-3 md:pb-20">
                        {
                            (subjectsData)?.filter(x => x.name?.toLowerCase().includes(nameFilter.toLowerCase())).map((subject, i) => (
                            <SubjectCard subject={{
                            id: subject.id,
                            name: subject.name,
                            user_id: subject.user_id//TODO preguntar
                        }} key={subject.id} active={subject.id === subject_id}/>
                        ))
                        }
                        <AddSubjectInlineForm/>
                </FetchLayout>
            </div>
        </div>
    )
}

export default LibrarySidebar;