import { useState } from 'react'
import { useCreateUser } from '@/api/user/useCreateUser'
import FormSubmitButton from '@/components/forms/core/FormSubmitButton'
import Form from '@/components/forms/core/Form'
import { EducationType } from '@/types/db/user'
import FormBackButton from './FormBackButton'
import { Step } from '../types'
import FormStepper from './FormStepper'
import { useNavigate } from 'react-router-dom'

const initialValues = {
  name: '',
  surname: '',
  username: '',
  birthdate: '',
  location: '',
  education: 'degree' as EducationType,
  institution: '',
  qualification: '',
  goal: '',
  termsAccepted: false
}

export default function UserSetupForm({steps} : {steps : Step[]}) {
  const [currentStep, setCurrentStep] = useState<number>(0)
  const step = steps[currentStep]
  const navigate = useNavigate()

  const { mutateAsync, status } = useCreateUser({
    onSuccess: () => { 
      localStorage.setItem('firstLogin', 'true')
      navigate('/home')
    },
  })

  const handleBack =() => {
    if(currentStep < 1) return;
    setCurrentStep((step) => step-1)
  }

  const handleSubmit = async (values: any, helpers : any) => {
      if(currentStep >= steps.length ) return;
      setCurrentStep((step) => step+1)
      if(step.submitForm == true){
        const { termsAccepted, ...formValues } = values;
        await mutateAsync(formValues);
      }
      helpers.setSubmitting(false)
  }

	return (
    <>
      <Form
        initialValues={initialValues}
        validationSchema={step.validationSchema}
        onSubmit={handleSubmit}
        className='text-left flex flex-col gap-20'
      >
          <div className="flex items-center w-full  justify-center">
              <div className="max-w-[500px] w-full">
                  <FormStepper currentStep={currentStep} totalSteps={steps.length}></FormStepper>
              </div>
          </div>
          {
            step.component({
              status,
              goBack: handleBack
            })
          }
          {
              step.hideControls ??
              <div className="flex justify-center flex-col items-center gap-4">
                  <div className='gap-4  grid grid-cols-2'>
                      <FormBackButton className='w-32 py-3 bg-gray-200 hover:!bg-gray-300 !text-gray-500' onBack={handleBack} />
                      <FormSubmitButton className='w-32 py-3'>Enviar</FormSubmitButton>
                  </div>
              </div>
          }
      </Form>
    </>
  )
}
