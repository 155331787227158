import { useQuery, UseQueryOptions, useQueryClient } from '@tanstack/react-query';
import { apiClient } from '@/api/client';
import { AxiosError } from 'axios';
import { Subject } from '@/types/db/subject';

type SubjectResponseData = Subject;

export const getSubjectData = async (subjectId: string): Promise<SubjectResponseData> => {
    const response = await apiClient.get<SubjectResponseData>(`/folder?subject_id=${subjectId}`);
    return response.data;
};

export const useSubject = (subjectId: string, options?: UseQueryOptions<SubjectResponseData, AxiosError>) => {
  const queryClient = useQueryClient();
    return useQuery<SubjectResponseData, AxiosError>({
        queryKey: ['subject', subjectId],
        queryFn: () => getSubjectData(subjectId),
        enabled: !!subjectId, // Solo habilita la consulta si subjectId no es una cadena vacía
        initialData: () => {
          const subjects = queryClient.getQueryData<SubjectResponseData[]>(['subjects']);
          return subjects?.find(subject => subject.id === subjectId);
        },
        ...options,
    });
};