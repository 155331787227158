import {Link } from 'react-router-dom'
import logo from '@/assets/logo.png'
import LoginForm from "@/pages/Auth/pages/Login/components/LoginForm";
import LoginDecoration from "@/pages/Auth/pages/Login/components/LoginDecoration";
import GoogleAuthButton from '@/pages/Auth/components/GoogleAuthButton';
import ODivider from '@/components/ui/ODivider';
import SEO from '@/components/SEO';

export default function Login() {
	return (<>
		<SEO
			title='Inicia Sesión - Bilern'
			description='Inicia sesión en Bilern y accede a una plataforma educativa innovadora. Inicia sesión fácilmente y comienza a disfrutar de nuestro método de estudio y herramientas diseñados para mejorar tu aprendizaje. Tu información está segura con nosotros'
		/>
		<main className="grid md:grid-cols-2 h-min-[100vh] h-[100vh]">
            <section className="bg-brand_primary-500 hidden md:flex m-5 rounded-3xl overflow-hidden justify-center items-center relative">
				<LoginDecoration/>
			</section>
            <section className="flex flex-col justify-around items-center px-5">
                <header className='max-w-[500px] w-full flex flex-col items-start'>
                    <img src={logo} alt='Bilern logo' className="w-[150px] mb-5"/>
                    <h1 className="text-2xl text-gray-700 text-left font-semibold m-0">Bienvenido a Bilern</h1>
                </header>
                <div className='max-w-[500px] w-full p-4'>
                    <LoginForm />
                    <ODivider />
                    <GoogleAuthButton>Iniciar sesión con Google</GoogleAuthButton>     
                </div>
                <footer className='bottom-12'>
                    <p className='pt-2 text-base font-normal text-gray-700'>
                        <Link to='password_forget' className="text-brand_primary-500 hover:text-brand_primary-700">
                            <strong>¿Has olvidado tu contraseña?</strong>
                        </Link>
                    </p>
                    <p className='text-base font-sm text-gray-700'>
                        ¿No tienes cuenta?{' '}
                        <Link to='register' className="text-brand_primary-500 hover:text-brand_primary-700">
                            <strong>Crear cuenta</strong>
                        </Link>
                    </p>
                </footer>
            </section>
				</main>
    </>)
}