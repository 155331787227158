import {createContext, useContext, useState, ReactNode, useEffect} from 'react';
import {Task, UserTask } from '@/types/tasks';
import {getUserTasks} from '@/api/tasks/useUserTasks';
import {format} from 'date-fns';
import {queryClient} from "@/api/client";
import { getExamGeneratedTasks } from '@/api/tasks/useExamGeneratedTasks';
import { useModifyTask } from '@/api/tasks/useModifyTask';
import { useDeleteTask } from '@/api/tasks/useDeleteTask';
import { useCreateTask } from '@/api/tasks/useCreateTask';
import { Exam } from '@/types/types';
import { se } from 'date-fns/locale';

interface TasksContextType {
  tasks: { [date: string]: Task[] };
  loading: boolean;
  getTasksOfDate: (date: Date) => Task[];
  setTasks: (tasks: { [date: string]: Task[] }) => void;
  addTask: (date: Date, content: string) => void;
  removeTask: (date: Date, taskToRemove: UserTask) => void;
  updateTask: (date: Date, updatedTask: UserTask) => void;
  //getExams: () => Exam[];
}

const TasksContext = createContext<TasksContextType | undefined>(undefined);

interface PomodoroProviderProps {
  children: ReactNode;
}

export const TasksProvider = ({ children }: PomodoroProviderProps) => {
  const [tasks, setTasks] = useState<{ [date: string]: Task[] }>({});
  //const [exams, setExams] = useState<Exam[]>([]);
  const [loading, setLoading] = useState(true);

  const {mutateAsync: mutateAsyncModify} = useModifyTask({
      onSuccess: async () => {
          await queryClient.invalidateQueries({queryKey: ['tasks']})
      },
  });

  const {mutateAsync: mutateAsyncDelete} = useDeleteTask({
      onSuccess: async () => {
          await queryClient.invalidateQueries({queryKey: ['tasks']})
      },
  });

  const {mutateAsync: mutateAsyncCreate} = useCreateTask({
    onSuccess: async () => {
        await queryClient.invalidateQueries({queryKey: ['tasks']})
    },
});

  useEffect(() => {
    const load = async () => {
      const fetchTasks = async () => {
        let initTasks: { [date: string]: Task[] } = {};
        // User tasks from the backend are mapped into UserTask objects
        const rawUserTasks = await getUserTasks();
        if (rawUserTasks.length > 0) {
          for (const rawUserTask of rawUserTasks) {
            const userTask: UserTask = {
              id: rawUserTask.id,
              name: rawUserTask.content,
              completed: rawUserTask.completed,
              type: 'user',
            };
            const dateKey = rawUserTask.date;
            if (!initTasks[dateKey]) {
              initTasks[dateKey] = [];
            }
            initTasks[dateKey].push(userTask);
          }
        }
        // Exam tasks from the backend are mapped into ExamTask objects
        // (Actualmente se devuelve un diccionario, seria mejor que se devolvieran directamente)
        let rawExamTasks = await getExamGeneratedTasks();
        let examTasksMap = rawExamTasks.combinedDictionary
        for (const dateKey in examTasksMap) {
          if (!initTasks[dateKey]) {
            initTasks[dateKey] = [];
          }
          initTasks[dateKey].push(...examTasksMap[dateKey]);
        }
        //let exams=examTasksRaw.exams
        //setExams(exams);
        setTasks(initTasks)
      };
      await fetchTasks();
      setLoading(false);
    }; load();
  }, []);

  /**
   * @param date in Date format
   * @returns date in 'YYYY-MM-DD' format
   */
  const formatDate = (date: Date): string => {
    return format(date, 'yyyy-MM-dd');
  };

  const getTasksOfDate = (date: Date): Task[] => {
    return(tasks[formatDate(date)] || []);
  };

  /*const getExams = (): Exam[] => {
    return exams;
  }*/

  const addTask = async (date: Date, content: string) => {
    const dateKey = formatDate(date);
    const {id} = await mutateAsyncCreate({date: dateKey, content: content});
    setTasks(prevTasks => ({
      ...prevTasks,
      [dateKey]: [
        ...(prevTasks[dateKey] || []),
        {
          id,
          name: content,
          completed: false,
          type: 'user',
        },
      ],
    }));
  };

  const removeTask = (date: Date, taskToRemove: UserTask) => {
    const dateKey = formatDate(date);
    setTasks(prevTasks => ({
      ...prevTasks,
      [dateKey]: prevTasks[dateKey].filter(task =>
          !(task.type === 'user' && (task as UserTask).id === taskToRemove.id)
      ),
    }));
    mutateAsyncDelete(taskToRemove);
  };

  const updateTask = (date: Date, updatedTask: UserTask) => {
    const dateKey = formatDate(date);
    setTasks(prevTasks => ({
        ...prevTasks,
        [dateKey]: prevTasks[dateKey].map(task => 
            task.type === 'user' && (task as UserTask).id === (updatedTask as UserTask).id ? updatedTask : task
        ),
    }));
    mutateAsyncModify(updatedTask as UserTask);
};

  return (
    <TasksContext.Provider value={{ tasks, loading, setTasks, getTasksOfDate, addTask, removeTask, updateTask, /*getExams*/ }}>
      {children}
    </TasksContext.Provider>
  );
};

export const useTasks = () => {
  const context = useContext(TasksContext);
  if (!context) throw new Error('usetasks hook is called outside the TasksProvider component');
  return context;
};

export default TasksContext;
