import FormFilePagePickerField
    from "@/pages/App/pages/Library/pages/DetailedCard/pages/DetailedCardCreateAi/components/form/fields/FormFilePagePickerField";
import Dialog from "@/components/ui/Dialog";
import {useFormikContext} from "formik";
import {useNavigate} from "react-router-dom";

function PickDocumentsPagesForm() {
    const {values, errors} = useFormikContext<{files : File[]}>();
    const navigate = useNavigate();

    return (<>
        <div className="py-10">
            <div className="w-full justify-center flex">
                <h1 className="text-3xl text-gray-800">Elige que páginas quieres utilizar</h1>
            </div>
            <div className="w-full grid gap-8 overflow-hidden">
            {
                values.files.map((x, i) => (
                        <FormFilePagePickerField key={i} file={x} name={`pages.${i}`}></FormFilePagePickerField>
                    )
                )
            }
            </div>
        </div>
    </>);
}

export default PickDocumentsPagesForm;