import FormCardTypeSelector from "@/pages/App/pages/Library/pages/DetailedCard/pages/DetailedCardCreate/components/forms/fields/FormCardTypeSelector";
import { cardEditorList } from "@/pages/App/pages/Library/pages/DetailedCard/pages/DetailedCardCreate/components/utils";
import FormSubmitButton from "@/components/forms/core/FormSubmitButton";
import {ModifyCardRequestData} from "@/api/cards/useModifyCard";
import {CreateCardRequestData} from "@/api/cards/useCreateCard";
import {Card, FlashCard, OcultarCard, TestCard} from "@/types/db/card";
import {Formik} from "formik";
import {validationSchema} from "@/pages/App/pages/Library/pages/DetailedCard/pages/DetailedCardCreate/validators";
import * as Yup from "yup";

interface CardEditorProps {
	initialValues ?: Card ,
	onSubmit: (values: ModifyCardRequestData | CreateCardRequestData) => void
}

export const CardEditor = ({initialValues, onSubmit}: CardEditorProps) => {

	function getEditor(value : string) {
		return cardEditorList.find((x) => x.value == value)?.editor
	}

	return(
		<Formik
			enableReinitialize={false}
			initialValues={{
				id: initialValues?.id || "",
				oldType: initialValues?.type || "flashcard",
				type: initialValues?.type || "flashcard",
				text: (initialValues as OcultarCard)?.text,
				pregunta: (initialValues as FlashCard | TestCard)?.pregunta,
				respuesta: (initialValues as FlashCard)?.respuesta,
				respuestas: (initialValues as TestCard)?.respuestas || [
					"Respuesta 1",
					"Respuesta 2",
					"Respuesta 3",
					"Respuesta 4"
				] as string[],
				correcta: (initialValues as TestCard)?.correcta || 0,
				...initialValues
			}}
			onSubmit={async (values, helpers) => {
					await onSubmit(values)
					helpers.resetForm();
					helpers.setSubmitting(false)
			}}
		>
			{
				({values}) =>
				<div className="w-full flex justify-center mt-10">
					<div className="max-w-[800px] w-full gap-3 flex flex-col">
						<div className="w-full flex flex-col lg:flex-row justify-between gap-2">
							<div className="max-w-[700px]">
								<FormCardTypeSelector name="type"></FormCardTypeSelector>
							</div>
							<FormSubmitButton className=" w-full max-w-[150px] p-5 py-2 h-fit rounded-lg font-semibold text-white bg-brand_secondary-500 box-border">Guardar</FormSubmitButton>
						</div>
						<div className="w-full flex justify-center">
							<div className="bg-gray-100    w-full p-2 lg:p-5 rounded-2xl">
								{getEditor(values?.type)}
							</div>
						</div>
						<div >
						</div>
					</div>
				</div>
			}
		</Formik>
	)
}

export default CardEditor
