import React from 'react';
import {Outlet} from 'react-router'

function Auth() {
    return (
        <div className="mt-30 h-full">
            <Outlet></Outlet>
        </div>
    );
}

export default Auth;