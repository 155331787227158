import React from 'react';
import {IoFolder} from "react-icons/io5";
import Image from '../../../../../../../assets/empty_deck.png'

function NoDecksBanner() {
    return (
        <div className="w-full justify-center flex mt-16">
            <div className="flex justify-center flex-col items-center text-center">
                <div
                    className="w-[180px] text-gray-500 h-[180px] bg-gray-100 rounded-full flex justify-center items-center text-7xl">
                    <img width={250} src={Image} className="shadow-lg rounded-xl"/>
                </div>
                <h1 className="text-xl font-semibold text-gray-600 mb-0 mt-10">
                    Aún no has creado ningún mazo
                </h1>
                <h1 className="text-base font-medium text-gray-400 mt-0 max-w-[500px] mt-2 mb-4">
                    Estos son grupos de preguntas: pueden organizarse por tema, examen, etc.
                </h1>

            </div>
        </div>
    );
}

export default NoDecksBanner;