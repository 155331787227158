import { ReactNode } from 'react';
import { Toast as ArkToast } from '@ark-ui/react';
import { IoClose, IoWarning } from 'react-icons/io5';
import {BiCheckCircle, BiError, BiInfoCircle} from "react-icons/bi";

export type ToastType = "success" | "error" | "loading" | "info" | (string & {});

export interface ToastProps {
  id?: string;
  title?: ReactNode;
  description?: ReactNode;
  type?: ToastType;
  className?: string;
}

const Toast = ({id, title, description, type='info'}: ToastProps) => {
  return (
    <ArkToast.Root 
      key={id} 
      className='realtive min-w-96 py-6 p-4 rounded-xl  bg-gray-100 border-solid border-1 border-gray-200 flex flex-row items-center overflow-hidden animate-slide-up-down'
    >
      {type && getToastIcon(type)}
      <div className='w-full px-5 text-left'>
        <ArkToast.Title className='font-bold text-gray-800'>{title}</ArkToast.Title>
        <ArkToast.Description className='text-sm text-gray-500'>{description}</ArkToast.Description>
      </div>
      <ArkToast.CloseTrigger className='absolute top-2 right-2 text-gray-400'>
        <IoClose size={'20px'} />
      </ArkToast.CloseTrigger>
    </ArkToast.Root>
  );
}

const getToastIcon = (type: ToastType) => {
  switch (type) {
    case 'info':
      return <BiInfoCircle size={'40px'} className='text-gray-400' />;
    case 'success':
      return <BiCheckCircle size={'40px'} className='text-brand_secondary-500' />;
    case 'warning':
      return <IoWarning size={'40px'} className='text-yellow-500' />;
    case 'error':
      return <BiError size={'40px'} className='text-red-400' />;
    default:
      return '';
  }
}

export default Toast;