import {useMutation, UseMutationOptions} from "@tanstack/react-query";
import {queryClient} from "@/api/client";
import {apiClient} from "@/api/client";
import {AxiosResponse} from "axios";


interface LoginRequestData {
    email: string;
    password: string;
}

interface LoginResponseData {
    message : string
}

interface LoginErrorData{
    error: string
}

export const loginRequest = async ({ email, password }: LoginRequestData) => {
    try{
        const response = await apiClient.post<LoginResponseData, AxiosResponse<LoginResponseData, LoginErrorData>>('/auth/login', {
            email,
            password,
        });
        queryClient.resetQueries();
        return response.data;
    }catch(error : any){
        throw error.response.data
    }
};

export const useLogin = (options: UseMutationOptions<LoginResponseData, LoginErrorData, LoginRequestData, null>) =>{
    return useMutation<LoginResponseData, LoginErrorData, LoginRequestData, null>({
        mutationFn: loginRequest,
        ...options
    })
}