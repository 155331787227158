import React from 'react';
import Spinner from "@/components/ui/Spinner";
import {BiArchive, BiListCheck} from "react-icons/bi";
import FetchLayout from '@/components/layout/FetchLayout';
import AddTaskInlineForm from '../../Schedule/pages/Tasks/components/AddTaskInlineForm';

interface Props{
    children : React.ReactNode,
    status ?:  ("error" | "success" | "pending")[],
    className ?: string,
    empty?: boolean,
    title ?: string,
    emptyData ?: {
        title ?: string,
        subtitle?: string,
        cta?: string
    },
    rightButtons ?: React.ReactNode
}

interface EmptyComponentProps{
    title ?: string,
    emptyData ?: {
        title ?: string,
        subtitle?: string,
        cta?: string
    },
    rightButtons ?: React.ReactNode
}

function Container({children, title, className, rightButtons } : {children : React.ReactNode, title ?: string, className ?: string, rightButtons ?: React.ReactNode}) {
    return (
        <>
            <div className="flex justify-between items-center">
                {title && <h1 className="text-lg text-gray-700 m-0 font-semibold">{title}</h1>}
                {rightButtons}

            </div>
            {
                children
            }
        </>
    )
}

function EmptyComponent({title, emptyData, rightButtons}: EmptyComponentProps){
    return <Container title={title} rightButtons={rightButtons}>
        <div className="w-full flex justify-center items-center flex-col py-5 h-full">
            <div className="w-[80px] h-[80px] bg-gray-200 rounded-full flex items-center justify-center text-gray-500">
                <BiListCheck size={60}></BiListCheck>
            </div>
            <div className="mt-3 text-lg font-semibold text-gray-700 mb-3">
                {emptyData?.title ?? "No hay tareas previstas para hoy"}
            </div>
            <AddTaskInlineForm date={new Date()}></AddTaskInlineForm>
        </div>
    </Container>
}

function DashboardElementContainer({title,  children, status, className, empty, emptyData, rightButtons} : Props) {

    return (
        <div className={`p-5 bg-gray-100 rounded-xl text-left flex flex-col box-border ${className}`}>
            <FetchLayout statusArray={status} isEmpty={empty} emptyComponent={<EmptyComponent title={title} emptyData={emptyData} rightButtons={rightButtons}/>}>
                <Container title={title} className={className} rightButtons={rightButtons}>
                    {
                        children
                    }
                </Container>
            </FetchLayout>
        </div>
    );

}

export default DashboardElementContainer;