import { Link, useLocation } from 'react-router-dom'
import { useState, useEffect } from 'react'

export default function Navbar() {

  const location = useLocation()
  const [currentPath, setCurrentPath] = useState('')

  useEffect(() => {
		setCurrentPath(location.pathname)
	}, [location.pathname])

  const routes = [
    { path: '/home', label: 'Inicio' },
    { path: '/dashboard', label: 'Biblioteca' },
    { path: '/examenes', label: 'Exámenes' },
    { path: '/schedule', label: 'Organización' },
  ]

  return (
    <nav aria-label='Main Navigation' className="flex gap-5">
      {routes.map((route) => (
        <Link
          className={currentPath.startsWith(route.path) ? 'font-semibold text-white' : ''}
          key={route.path}
          to={route.path}
        >
          {route.label}
        </Link>
      ))}
    </nav>
  )

}
