import { GoogleIcon } from './icons'
import { FaApple } from 'react-icons/fa'

type Provider = 'google' | 'apple'

interface AuthProviderButtonProps {
    provider: Provider,
    onClick: () => void,
    children: React.ReactNode 
}

const providerIcons: Record<Provider, JSX.Element> = {
    google: <GoogleIcon className='w-5 h-5'/>,
    apple: <FaApple className='w-5 h-5'/>
}

export default function AuthProviderButton({provider, onClick, children}: AuthProviderButtonProps) {
    return (
		<button 
            className='w-full h-11 text-semibold flex justify-center items-center gap-3 bg-gray-100 rounded-xl border-solid border-1 border-gray-200 hover:bg-gray-200'
            onClick={onClick}
        >
                {providerIcons[provider]}
            <span className='text-gray-700 font-semibold text-sm'>{children}</span>
		</button>
	)
}