import React from 'react'
import FormInput, {IconContext} from './FormInput'
import {FaEye, FaEyeSlash} from 'react-icons/fa'

interface PasswordInputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> {
  label: string
  name: string
  leftIcon ?: (context : IconContext) => React.ReactNode
}

export default function FormPasswordInput(props: PasswordInputProps) {
 
  return (
    <FormInput 
      type='password'
      leftIcon={props.leftIcon}
      rightIcon={(context) => (
        context.currentInputType == 'password' ?
          <FaEye 
            className='cursor-pointer' size={20} 
            onClick={() => context.changeInputType(() => 'text')} 
          />
        : <FaEyeSlash 
            className='cursor-pointer' size={20}
            onClick={() => context.changeInputType(() => 'password')}
          />
      )}
      {...props}
    />
  )
}
