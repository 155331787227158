import ConfirmationDialog from "@/components/ConfirmationDialog";
import {useCancelSubscription} from "@/api/pricing/useCancelSubscription";
import {useSubscription} from "@/api/user/useSubscription";
import {queryClient} from "@/api/client";
import {useToast} from "@/components/ui/ToastContext";

interface CancelSubscriptionDialogProps {
    open: boolean,
    onClose: () => void
}

export default function CancelSubscriptionDialog({open, onClose}: CancelSubscriptionDialogProps) {
    const {createToast} = useToast();
    const {data: subscription} = useSubscription()
    const {mutateAsync, isPending} = useCancelSubscription({
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ['subscription']})
            onClose()
            createToast({
                title: 'Transacción Completada', 
                description: 'Tu subscripción ha sido cancelada correctamente', 
                type: 'success'
            })
        },
        onError: () => {
            onClose()
            createToast({
                title: 'Error', 
                description: 'Ha ocurrido un error al intentar cancelar tu subscripcion', 
                type: 'error'}
            )
        }
    });

    if (open) return (
        <ConfirmationDialog
            open={true}
            onClose={onClose}
            title={'Cancelar Subscripción'}
            confirmText='Confirmar'
            onConfirm={async () => await mutateAsync()}
            isPending={isPending}
            description={`
                ¿Estás seguro de que deseas cancelar tu subscripción?
                Podrás seguir disfrutando de los beneficios de tu plan
                hasta el ${subscription && (new Date(subscription!.end_date)).toLocaleDateString()}.
            `}
        />
    )
}