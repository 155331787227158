import {useMutation, UseMutationOptions} from "@tanstack/react-query";
import {apiClient} from "@/api/client";
import {AxiosError} from "axios";

interface CreateTaskRequestData {
    date : string
    content : string
}

interface CreateTaskResponseData {
    message : string
    id : string
}

export const createTaskRequest = async (createTaskData: CreateTaskRequestData) => {
    try{
        const response = await apiClient.post<CreateTaskResponseData>(
            '/task/create', createTaskData
        );
        return response.data;
    }catch(error : any){
        throw error.response.data
    }
};

export const useCreateTask = (options: UseMutationOptions<CreateTaskResponseData, AxiosError, CreateTaskRequestData, null>) =>{
    return useMutation<CreateTaskResponseData, AxiosError, CreateTaskRequestData, null>({
        mutationFn: createTaskRequest,
        ...options
    })
}
