import ConfirmationDialog from "@/components/ConfirmationDialog";
import {useForgetDeck} from "@/api/decks/useForgetDeck";
import { useToast } from "@/components/ui/ToastContext";
import { queryClient } from "@/api/client";
import { useParams } from "react-router-dom";

interface ForgetDeckDialogProps {
    deckId: string,
    open: boolean,
    onClose: () => void
}

export default function ForgetDeckDialog({deckId, open, onClose}: ForgetDeckDialogProps) {
    const {createToast} = useToast();
    const {deck_id, subject_id} = useParams<{ deck_id: string, subject_id: string }>();
    const {mutateAsync, isPending} = useForgetDeck({
        onSuccess: async () => {
            await queryClient.refetchQueries({queryKey: ['cards', deck_id]})
            await queryClient.refetchQueries({queryKey: ['decks', subject_id]})
            await queryClient.refetchQueries({queryKey: ['deck', deck_id]})
            onClose()
            createToast({
                title: 'Mazo Olvidado', 
                description: 'El progreso del mazo ha sido olvidado correctamente', 
                type: 'success'
            })
        },
        onError: () => {
            onClose()
            createToast({
                title: 'Error', 
                description: 'Ha ocurrido un error al intentar olvidar el mazo', 
                type: 'error'}
            )
        }
    });

    if (open) return (
        <ConfirmationDialog
            open={true}
            onClose={onClose}
            title={'Olvidar Mazo'}
            description={'¿Estás seguro de que deseas olvidar este mazo?'}
            confirmText='Olvidar'
            onConfirm={async () => await mutateAsync({deckId: deckId})}
            isPending={isPending}
        />
    )
}