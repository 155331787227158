import {useMutation, UseMutationOptions} from "@tanstack/react-query";
import {apiClient} from "@/api/client";
import {AxiosError} from "axios";

interface ModifyTaskRequestData {
    id : string
    completed : boolean
}

interface ModifyTaskResponseData {
    message : string
}

export const modifyTaskRequest = async (modifyTaskData: ModifyTaskRequestData) => {
    try{
        const response = await apiClient.patch<ModifyTaskResponseData>(
            `/task/update_task?id=${modifyTaskData.id}&completed=${modifyTaskData.completed}`
        );
        return response.data;
    }catch(error : any){
        throw error.response.data
    }
};

export const useModifyTask = (options: UseMutationOptions<ModifyTaskResponseData, AxiosError, ModifyTaskRequestData, null>) =>{
    return useMutation<ModifyTaskResponseData, AxiosError, ModifyTaskRequestData, null>({
        mutationFn: modifyTaskRequest,
        ...options
    })
}
