import { useQuery, UseQueryOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { apiClient } from '@/api/client';
import { Card } from '@/types/db/card';

type CardResponseData = Card;

export const getCardData = async (cardId: string): Promise<CardResponseData> => {
    const response = await apiClient.get<CardResponseData>(`/card?card_id=${cardId}`); // NO IMPLEMENTADO AUN EN EL BACK !!!
    return response.data;
};

export const useCard = (cardId: string, options?: UseQueryOptions<CardResponseData, AxiosError>) => {
  const queryClient = useQueryClient();
    return useQuery<CardResponseData, AxiosError>({
        queryKey: ['cards', 'detailed', cardId],
        queryFn: () => getCardData(cardId),
        enabled: !!cardId, // Solo habilita la consulta si cardId no es una cadena vacía
        initialData: () => {
          const cards = queryClient.getQueryData<CardResponseData[]>(['cards']);
          return cards?.find(card => card.id === cardId);
        },
        ...options,
    });
};
