import decoration from '@/assets/RegisterDecoration.png'

export default function LoginDecoration() {
    return (
        <div className='max-w-[400px]'>
            <h1 className='text-2xl text-white'>Es la hora de estudiar</h1>
            <h3 className='text-[15px] text-white font-light mb-8'>No te quedes atrás</h3>
            <svg className='absolute top-[-50px] left-[-40px]' width='258' height='229' viewBox='0 0 358 329'
                 fill='none'>
                <path
                    d='M159.29 328.649C71.6895 323.449 -11.4041 223.649 1.28952 159.149C19.7895 65.1495 72.2895 17.1495 115.79 3.14945C159.29 -10.8505 328.29 21.6495 353.79 104.149C379.29 186.649 268.79 335.149 159.29 328.649Z'
                    className='fill-brand_primary-700'/>
            </svg>
            <svg className='absolute bottom-[-50px] right-[-40px]' width='198' height='169'
                 viewBox='0 0 358 329'
                 fill='none'>
                <path
                    d='M159.29 328.649C71.6895 323.449 -11.4041 223.649 1.28952 159.149C19.7895 65.1495 72.2895 17.1495 115.79 3.14945C159.29 -10.8505 328.29 21.6495 353.79 104.149C379.29 186.649 268.79 335.149 159.29 328.649Z'
                    className='fill-brand_primary-700'/>
            </svg>
            <img src={decoration} alt='Bilern logo' className='w-[400px] mb-5'/>
        </div>
    )
}