import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {apiClient} from '@/api/client'
import {AxiosResponse} from 'axios'

interface createFeedbackRequestData {
    nombre: string
    apellidos: string
    email: string
    message: string
}

interface createFeedbackResponseData {
    message : string
}

interface createFeedbackErrorData{
    error: string
}

export const createFeedbackRequest = async (createFeedbackData: createFeedbackRequestData) => {
    try{
        const response = await apiClient.post<createFeedbackResponseData, AxiosResponse<createFeedbackResponseData, createFeedbackErrorData>>(
            '/feedback/store', createFeedbackData
        )
        return response.data
    } catch(error : any){
        throw error.response.data
    }
}

export const useCreateFeedback = (options: UseMutationOptions<createFeedbackResponseData, createFeedbackErrorData, createFeedbackRequestData, null>) =>{
    return useMutation<createFeedbackResponseData, createFeedbackErrorData, createFeedbackRequestData, null>({
        mutationFn: createFeedbackRequest,
        ...options
    })
}
