import {useField} from "formik";
import {cardEditorList} from "@/pages/App/pages/Library/pages/DetailedCard/pages/DetailedCardCreate/components/utils";


interface props{
    name : string
}

function FormCardTypeSelector({name} : props) {

    const [field, meta, helper] = useField(name)


    return (
        <div className="w-full lg:grid-cols-3 grid gap-2">
            {
                cardEditorList.map((x) => (
                    <div key={x.value} onClick={() => helper.setValue(x.value)}
                         className={`${field.value == x.value && " !bg-brand_secondary-500 text-white"} 
                          gap-2 text-gray-700 items-center flex cursor-pointer px-8 
                           py-3 bg-gray-100 rounded-xl font-semibold text-xs w-full box-border` }
                    >
                        <div className="text-xl flex items-center">
                            {x.icon}
                        </div>
                        {x.name}
                    </div>
                ))
            }
        </div>
    );
}

export default FormCardTypeSelector;