import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { queryClient } from '@/api/client';
import { apiClient } from '@/api/client';
import { AxiosError } from 'axios';

interface LogoutResponseData {
    message: string;
}

export const logoutRequest = async (): Promise<LogoutResponseData> => {
    const response = await apiClient.post<LogoutResponseData>('/auth/signout');
    queryClient.clear();
    return response.data;
};

export const useLogout = (options?: UseMutationOptions<LogoutResponseData, AxiosError>) => {
    return useMutation<LogoutResponseData, AxiosError>({
        mutationFn: logoutRequest,
        ...options,
    });
};
