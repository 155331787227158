import ConfirmationDialog from "@/components/ConfirmationDialog";
import {useDeleteSubject} from "@/api/subjects/useDeleteSubject";
import {queryClient} from "@/api/client";
import {useToast} from "@/components/ui/ToastContext";
import {useLocation, useNavigate, useParams} from "react-router-dom";


interface DeleteSubjectDialogProps {
    subjectId: string,
    open: boolean,
    onClose: () => void
}

export default function DeleteSubjectDialog({subjectId, open, onClose}: DeleteSubjectDialogProps) {
    const {createToast} = useToast();
    const {subject_id} = useParams()
    const navigate = useNavigate()
    const {mutateAsync, isPending} = useDeleteSubject({
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ['subjects']})
            onClose()
            createToast({
                title: 'Asignatura eliminada', 
                description: 'La asignatura ha sido eliminada correctamente', 
                type: 'success'
            })
            if(subjectId == subject_id) navigate("/dashboard")
        },
        onError: () => {
            onClose()
            createToast({
                title: 'Error', 
                description: 'Ha ocurrido un error al intentar borrar la asignatura', 
                type: 'error'}
            )
        }
    });

    if (open) return (
        <ConfirmationDialog
            open={true}
            onClose={onClose}
            title={'Eliminar Asignatura'}
            description={'¿Estás seguro de que deseas eliminar esta asignatura?'}
            confirmText='Eliminar'
            onConfirm={async () => await mutateAsync({subjectId: subjectId})}
            isPending={isPending}
        />
    )
}