import { Card, FlashCard, OcultarCard, TestCard } from '@/types/db/card';

export function getCardPreview(card: Card): string | null {
  switch (card.type) {
    case 'flashcard':
      return (card as FlashCard).pregunta;
    case 'test':
      return (card as TestCard).pregunta;
    case 'ocultar':
      return (card as OcultarCard).text;
    default:
      return null;
  }
}