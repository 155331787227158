import {useEffect, useState} from "react";
import {FileUpload, useFileUpload} from "@ark-ui/react";
import {BiImage, BiSolidFilePdf, BiTrash} from "react-icons/bi";
import {calculateTokens} from "@/utils/tokens";
import {getFileTokens, getUniqueFileIdentifier, readFile} from "@/utils/files";
import {useField} from "formik";

export const FilePreviewCard = ({file} : {file : File}) => {
    const [tokens, setTokens] = useState<number | undefined>(undefined);


    async function setFileTokens(){
        const tokens = await getFileTokens(file)
        setTokens(tokens)
    }

    useEffect(() => {
        setFileTokens()
    }, [file]);

    return (<>
        <FileUpload.Item
            className="bg-gray-100 rounded-xl w-full p-4 flex items-center gap-3 justify-between box-border"
            key={getUniqueFileIdentifier(file)}
            file={file}
        >
            <div className="flex items-center gap-3">
                <div className="min-w-[50px] min-h-[50px] bg-brand_primary-500 text-white rounded-xl flex items-center justify-center text-xl">
                    <FileUpload.ItemPreview type="image/*">
                        <BiImage></BiImage>
                    </FileUpload.ItemPreview>
                    <FileUpload.ItemPreview type="application/pdf">
                        <BiSolidFilePdf size={'30px'} style={{ transform: 'translateY(3px)' }} />
                    </FileUpload.ItemPreview>
                </div>
                <div>
                    <FileUpload.ItemName className="font-semibold text-gray-700 text-sm" />
                    <div className="flex flex-row gap-1">
                        <FileUpload.ItemSizeText className="text-gray-500 text-xs" />
                        <h4 className="text-gray-500 text-xs font-normal">
                            {tokens !== undefined ? `- ${tokens} tk` : 'Calculando costo en tokens...'}
                        </h4>
                    </div>
                </div>
            </div>
            <FileUpload.ItemDeleteTrigger className="text-gray-500">
                <BiTrash size={'20px'} className='rounded-xl hover:bg-gray-200 p-2 text-xs' />
            </FileUpload.ItemDeleteTrigger>
        </FileUpload.Item>
    </>)
}