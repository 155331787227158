import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { apiClient } from '@/api/client';
import { AxiosError } from 'axios';
import { ExamTask } from '@/types/tasks';
import { Exam } from '@/types/types';


export type UserExamGeneratedTasks = {
    combinedDictionary: { [key: string]: ExamTask[] };
    exams: Exam[];
};

export const getExamGeneratedTasks = async (): Promise<UserExamGeneratedTasks> => {
    const response = await apiClient.get<UserExamGeneratedTasks>('/exam/get_cards_from_user');
    return response.data;
};

export const useExamGeneratedTasks = (options?: UseQueryOptions<UserExamGeneratedTasks, AxiosError>) => {
    return useQuery<UserExamGeneratedTasks, AxiosError>({
        queryKey: ['exam_generated_tasks'],
        queryFn: getExamGeneratedTasks,
        ...options,
    });
};
