import { useEffect } from 'react'
import { useAuthState } from '@/api/auth/state/useAuthState';
import { useSortExams } from '@/api/exams/useSortExams';

export function useAuth() {
  const { data, isPending } = useAuthState()
  const { mutateAsync: sortExams } = useSortExams({})

  const isTokenUpdateExamsExpired = () => {
    const token = localStorage.getItem('updateExamsToken');
    if (!token) {
      return true
    }
    try {
      const tokenData = JSON.parse(atob(token))
      const currentTime = Date.now()
      return currentTime > tokenData.expiresAt
    } catch (error) {
      console.error('Error decoding token:', error)
      return true
    }
  };

  useEffect(() => {
    if(data){
      if (isTokenUpdateExamsExpired()) {//isTokenUpdateExamsExpired()
        sortExams(null)
        const now = new Date();
        const expirationDate = new Date(now);
        expirationDate.setHours(23, 59, 59, 999); // Establecer la hora a las 23:59:59 del mismo día
        const expiresIn = expirationDate.getTime() - now.getTime();
        const tokenData = {
          data: 'yourData',
          expiresAt: Date.now() + expiresIn,
        };
        const token = btoa(JSON.stringify(tokenData));
        localStorage.setItem('updateExamsToken', token);
      }
    }
  }, [data])

  return { isAuth: data?.valor, isPending, redirectTo: data?.redirectTo }

}