import {useState} from 'react';
import {Link} from 'react-router-dom';
import logo from '@/assets/logo.png'
import SEO from '@/components/SEO';
import RegisterForm from './components/RegisterForm';
import RegisterDecoration from './components/RegisterDecoration';
import GoogleAuthButton from '@/pages/Auth/components/GoogleAuthButton';
import ODivider from '@/components/ui/ODivider';

export default function Register() {
    const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);

	return (<>
        <SEO
            title='Registrate - Bilern'
            description='Únete a Bilern y accede a una plataforma educativa innovadora. Regístrate fácilmente y comienza a disfrutar de nuestro método de estudio y herramientas diseñados para mejorar tu aprendizaje. Tu información está segura con nosotros'
        />
        <main className='grid md:grid-cols-5 h-[100vh]'>
            <section className='col-span-3 h-[100vh] flex flex-col justify-around items-center px-5'>
                <header className='max-w-[500px] w-full flex flex-col items-start'>
                    <img src={logo} alt='Bilern logo' className="w-[150px] mb-5"/>
                </header>
                <div className='max-w-[500px] w-full p-4'>
                    <RegisterForm onSubmit={() => setIsFormSubmitted(true)} />
                    { !isFormSubmitted && <>
                        <ODivider className='!my-12 md:!mb-6 md:!mt-8'/>
                        <div className='flex flex-col gap-2'>
                            <GoogleAuthButton>Registrate con Google</GoogleAuthButton>
                        </div>
                    </>}       
                </div>
                <footer className='bottom-12'>
                    <p className='text-lg font-normal text-gray-700'>
                        Ya tienes cuenta?{' '}
                        <Link to='/login' >
                            <strong className="text-brand_primary-500 hover:text-brand_primary-900">Iniciar sesión</strong>
                        </Link>
                    </p>
                </footer>
            </section>
            <section className='hidden relative overflow-hidden col-span-2 md:flex w-full bg-background-color bg-brand_primary-500 flex-row items-center justify-center'>
                <RegisterDecoration />
            </section>
        </main>
    </>)
}
