import {FileUpload, useFileUpload} from '@ark-ui/react';
import {BiFile} from "react-icons/bi";
import {useField} from "formik";
import {useEffect} from "react";
import {getUniqueFileIdentifier} from "@/utils/files";

interface props extends FileUpload.RootProps{
    name : string,
    maxFiles?: number,
    previewCard?: (file : File) => React.ReactNode
}

export const FormFileField = ({name, maxFiles=5, previewCard, ...other} : props) => {
    const [field, , helpers] = useField(name)
    const fileUpload = useFileUpload({
        onFileChange: (value) => {
            helpers.setValue([...value.acceptedFiles])
        },
        accept: ["application/pdf"],
        maxFiles: maxFiles,
        ...other,
    })

    useEffect(() => {
        fileUpload.setFiles(field.value)
    }, [])



    return (
        <FileUpload.RootProvider value={fileUpload}  className="w-full "  >
            <FileUpload.Dropzone className="flex justify-center items-center w-full p-4 cursor-pointer bg-gray-100 h-[230px] box-border rounded-xl border-[1px] border-gray-300 border-dashed">
                <div className="flex justify-center flex-col items-center gap-3 text-gray-500">
                    <BiFile  size={50}/>
                    Sube tus apuntes en PDF
                    <FileUpload.Trigger className="text-xs font-semibold px-4 py-2 bg-brand_primary-500 hover:bg-brand_primary-700 rounded-md text-white">Elige archivo(s)</FileUpload.Trigger>
                </div>
            </FileUpload.Dropzone>
            <FileUpload.ItemGroup className="w-full mt-3 grid lg:grid-cols-1 gap-2">
                <FileUpload.Context>
                    {({ acceptedFiles }) =>
                        acceptedFiles.map((file, i) => (
                            <div key={getUniqueFileIdentifier(file) + i}>
                                {
                                    previewCard ? previewCard(file) : <></>
                                }
                            </div>
                        ))
                    }
                </FileUpload.Context>
            </FileUpload.ItemGroup>
            <FileUpload.HiddenInput />
        </FileUpload.RootProvider>
    )
}
