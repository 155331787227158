import {IoMdClose} from "react-icons/io";

export default function RegisterResult() {
	return (
        <div className='h-full flex flex-col items-center justify-center gap-20'>
            <div className='flex flex-col items-center justify-center '>
                <div
                    className="w-[150px] h-[150px] bg-red-500 flex justify-center items-center text-white rounded-full">
                    <IoMdClose size={80}></IoMdClose>
                </div>
                <h2 className='text-red-500 font-bold m-0 mb-4 text-4xl mt-12'>
                    Algo ha salido mal
                </h2>
                <h3 className='text-xl text-gray-500'>
                    Vuelve a intentarlo o contacta con soporte.
                </h3>
            </div>
        </div>
    )
}
