import React from 'react';
import {BlockLayout} from "@/pages/App/pages/Settings/components/BlockLayout";
import FormInput from "@/components/forms/core/FormInput";
import {Formik} from "formik";
import FormSubmitButton from "@/components/forms/core/FormSubmitButton";
import {useChangeUserPassword} from "@/api/user/useChangeUserPassword";
import {useToast} from "@/components/ui/ToastContext";
import {useUser} from "@/api/user/useUser";
import FetchLayout from "@/components/layout/FetchLayout";
import FormPasswordInput from "@/components/forms/core/FormPasswordInput";

function ChangeUserPasswordForm(props) {
    const {createToast} = useToast();
    const {data, status} = useUser()
    const { mutateAsync } = useChangeUserPassword({
        onSuccess: () => {
            createToast({
                type: "success",
                title: "Contraseña cambiada correctamente"
            })
        }
    })

    return (
        <BlockLayout title="Seguridad" className="grid md:grid-cols-1 gap-3">
            <FetchLayout status={status}>
                <Formik initialValues={{oldPassword: "", newPassword: "", password: ""}}
                        onSubmit={async (values) => {
                            await mutateAsync({
                                oldPassword: values.oldPassword,
                                newPassword: values.newPassword,
                                email : data?.correo || ""
                            })
                        }
                    }
                >
                    {
                        () => (
                            <div className="grid gap-3">
                                <FormPasswordInput label="Contraseña actual" name="oldPassword" size="md"
                                           variant="secondary"></FormPasswordInput>
                                <FormPasswordInput label="Nueva contraseña" name="newPassword" size="md"
                                           variant="secondary"></FormPasswordInput>
                                <FormPasswordInput label="Confirmar nueva contraseña" name="confirmPassword" size="md"
                                           variant="secondary"></FormPasswordInput>
                                <FormSubmitButton className="w-fit">Cambiar contraseña</FormSubmitButton>
                            </div>
                        )
                    }
                </Formik>
            </FetchLayout>
        </BlockLayout>
    );
}

export default ChangeUserPasswordForm;