import FormikEditorEditable
    from "@/pages/App/pages/Library/pages/DetailedCard/pages/DetailedCardCreate/components/forms/fields/FormikEditorEditable";
import { baseToolkitButtons } from "@/components/forms/TipTapEditorForm/utils";
import FormMultipleAnswerField
    from "@/pages/App/pages/Library/pages/DetailedCard/pages/DetailedCardCreate/components/forms/fields/FormMultipleAnswerField";

export default function TestCardModifyForm() {


  return(
      <div className='w-full flex flex-col items-center justify-center p-4 box-border'>
          <FormikEditorEditable toolkit={baseToolkitButtons} editorClass="min-h-[80px]" name={"pregunta"} label="Pregunta" placeholder="Añade una pregunta"/>
          <div className="w-full h-[1px] bg-gray-200 my-2"></div>
          <FormMultipleAnswerField name="respuestas" correctAnswerName="correcta"></FormMultipleAnswerField>
      </div>
  )
}