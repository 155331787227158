import { useDeleteCard } from "@/api/cards/useDeleteCard"
import { queryClient } from "@/api/client"
import Button from "@/components/ui/Button"
import { useToast } from "@/components/ui/ToastContext"
import { CardType } from "@/types/types"

interface ConfirmDeleteCardProps {
    cardId: string
    cardType: CardType
    onReturn: () => void
    onReturnDelete: () => void
}

export default function ConfirmDeleteCard({ cardId, cardType, onReturn, onReturnDelete }: ConfirmDeleteCardProps) {
    
    const {createToast} = useToast();
    const {mutateAsync, isPending} = useDeleteCard({
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ['cards']})
            onReturnDelete()
            createToast({
                title: 'Carta eliminada', 
                description: 'La carta ha sido eliminada correctamente', 
                type: 'success'
            })
        },
        onError: () => {
            onReturnDelete()
            createToast({
                title: 'Error', 
                description: 'Ha ocurrido un error al intentar eliminar la carta', 
                type: 'error'}
            )
        }
    });

    return (
        <div className="flex flex-col items-center justify-center p-6 text-xl">
            ¿Estás seguro de que quieres borrar esta tarjeta?
            <div className="flex flex-row items-center justify-center w-full mt-5 text-lg">
                <Button className="bg-delete-color hover:bg-error-color w-36" onClick={onReturn}>
                    Cancelar
                </Button>
                <Button className="w-36 ml-14" onClick={async () => await mutateAsync({
                cardType: cardType, 
                cardId: cardId
            })}>
                Eliminar
                </Button>
            </div>
        </div>
    )
}
