import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {apiClient} from '@/api/client'
import {AxiosResponse} from 'axios'
import {CardType} from '@/types/db/card'

interface DeleteCardRequestData {
    cardId: string
    cardType: CardType
}

interface DeleteCardResponseData {
    message : string
}

interface DeleteCardErrorData{
    error: string
}

export const deleteCardRequest = async (deleteCardData: DeleteCardRequestData) => {
    try{
        const response = await apiClient.delete<DeleteCardResponseData, AxiosResponse<DeleteCardResponseData, DeleteCardErrorData>>(
            `/card/delete?cardType=${deleteCardData.cardType}&cardId=${deleteCardData.cardId}`
        )
        return response.data
    } catch(error : any){
        throw error.response.data
    }
}

export const useDeleteCard = (options: UseMutationOptions<DeleteCardResponseData, DeleteCardErrorData, DeleteCardRequestData, null>) =>{
    return useMutation<DeleteCardResponseData, DeleteCardErrorData, DeleteCardRequestData, null>({
        mutationFn: deleteCardRequest,
        ...options
    })
}
