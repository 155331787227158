import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { apiClient } from "@/api/client";
import { AxiosError } from "axios";

export const subscriptionRequest = async (planId: string) => {
    const response = await apiClient.get(`/pay/subscribe_session?planId=${planId}`, { withCredentials: true });
    if (response.status !== 200) throw new Error('Error al obtener la URL de pago');
    const data = response.data;
    return data.url;
};

export const useRequestSubscription = (options: UseMutationOptions<string, AxiosError, string, void>) => {
    return useMutation<string, AxiosError, string, void>({
        mutationFn: subscriptionRequest,
        ...options
    });
};