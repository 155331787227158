interface FormStepperProps{
    currentStep : number,
    totalSteps: number
}

export default function FormStepper({currentStep, totalSteps} : FormStepperProps){
    return (
        <div className="w-full flex justify-between relative">
            {
                (new Array(totalSteps)).fill(0).map((x, i) => (
                    <div key={i} className={(currentStep >= i ? '!bg-brand_secondary-500 ' : '') + 'z-10 h-[30px] w-[30px] border-solid  bg-white border-2 border-brand_secondary-500 rounded-full'} />
                ))
            }
            <div className="w-full absolute inset-y-1/2 transform -translate-y-1/2 h-[2px] bg-brand_secondary-500"></div>
        </div>
    )
}