import React from 'react';
import FormSubmitButton from "@/components/forms/core/FormSubmitButton";
import {useCreateTask} from "@/api/tasks/useCreateTask";
import {queryClient} from "@/api/client";
import Dialog from "@/components/ui/Dialog";
import Form from "@/components/forms/core/Form";
import FormInput from "@/components/forms/core/FormInput";

interface props{
    isOpen: boolean;
    setIsOpen: (open: boolean) => void;
}

export const TaskCreateForm = ({isOpen, setIsOpen} : props) => {
        const {mutateAsync} = useCreateTask({
            onSuccess:  async () => {
                await queryClient.invalidateQueries({
                    queryKey: ['tasks'],
                })
                setIsOpen(false);
            }
        })

        return (
            <Dialog open={isOpen} onClose={() => setIsOpen(false)} title={"Crear tarea"} className="max-w-[500px] mx-2 w-full">
                <Form onSubmit={async (values) => {
                    await mutateAsync(values)
                }} initialValues={{content: "", date: (new Date()).toISOString().slice(0, 10)}}>
                    <div className="mt-8 grid gap-3">
                        <FormInput size="md" variant="primary"  name="content" label={"Contenido:"} placeholder="Nombre para tu nueva tarea"/>
                        <FormInput type="date" size="md" variant="primary"  name="date" label={"Fecha:"} placeholder="Fecha"/>
                    </div>
                    <div className="flex items-center justify-center gap-3 mt-12">
                        <button onClick={() => setIsOpen(false)} className="px-7 bg-gray-100 py-2 text-gray-500 font-semibold rounded-full">Cancelar</button>
                        <FormSubmitButton className="px-7 bg-brand_secondary-500 py-2 text-white font-semibold !rounded-full">Crear</FormSubmitButton>
                    </div>
                </Form>
            </Dialog>
        )
}


export default TaskCreateForm;