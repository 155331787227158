import {useState} from 'react'
import logo from '@/assets/logo.png'
import SEO from '@/components/SEO'
import ForgetPasswordForm from './components/ForgetPasswordForm'
import { MdMail } from 'react-icons/md'

export default function ForgetPassword() {
	const [emailSentTo, setEmailSentTo] = useState<string | undefined>(undefined)

	return (<>
		<SEO title='He olvidado mi contraseña' />
		<main className='flex justify-center items-center h-min-[100vh] h-full'>
			<div className='max-w-[400px] max-h-[600px] w-full flex justify-center flex-col items-start p-10 rounded-3xl shadow-xl'>
				<img src={logo} alt='Bilern logo' className='w-[150px] mb-5' />
				<div className='flex flex-col gap-1 w-full text-left'>
					<h1 className='text-2xl text-gray-700 font-semibold m-0'>¿Has olvidado tu contraseña?</h1>
					<h3 className='text-base text-gray-500 font-medium m-0'>No pasa nada, te mandaremos un correo para que puedas cambiarla.</h3>
				</div>
				<div className='w-full my-12 flex flex-col gap-4'>
					{ emailSentTo ?
						<div className='flex flex-col items-center'>
							<div>
								<h2 className='font-bold text-brand_secondary-500'>¡Correo enviado!</h2>
								<MdMail className='text-[5rem] text-brand_secondary-500' />
							</div>
							<div className='w-full text-left text-xl'>
								<h4 className='text-gray-500'>Revisa tu bandeja de entrada o tu carpeta de spam en:</h4>
								<h4 className='text-gray-700'>{emailSentTo}</h4>
							</div>
						</div>
					: <ForgetPasswordForm onSuccess={(email) => setEmailSentTo(email)} />
					}
				</div>
			</div>
		</main>
	</>)
}