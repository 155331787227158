import React from 'react';
import PageLayout from "@/components/layout/PageLayout";
import ModifyUserForm from "@/pages/App/pages/Settings/components/ModifyUserForm";
import ChangeUserPasswordForm from "@/pages/App/pages/Settings/components/ChangeUserPasswordForm";
import DeleteUserForm from "@/pages/App/pages/Settings/components/DeleteUserForm";
import UserProfileCard from "@/bilerncomponents/users/UserProfileCard";


function Settings(props) {

    return (
        <PageLayout title="Configuración de la cuenta" isDetailed={true}>
                <div className="mt-3">
                    <UserProfileCard></UserProfileCard>
                </div>
                <div className="grid md:grid-cols-2 mt-5 gap-3">
                    <ModifyUserForm></ModifyUserForm>
                    <div className="grid gap-3">
                            <ChangeUserPasswordForm></ChangeUserPasswordForm>
                            <DeleteUserForm></DeleteUserForm>
                    </div>
                </div>
        </PageLayout>
)
    ;
}

export default Settings;