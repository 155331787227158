import axios from "axios";
import {QueryClient} from "@tanstack/react-query";

export const apiClient = axios.create({
    baseURL: `${import.meta.env.VITE_API_URL}`,
    withCredentials: true,
})

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
        }
    }
})