import {IoAddSharp } from "react-icons/io5";
import { useState } from "react";
import Form from '@/components/forms/core/Form'
import {IoFolder} from "react-icons/io5";
import {GenerateLibrarySchema} from '@/pages/App/pages/Library/schemas'
import FormSubmitButton from '@/components/forms/core/FormSubmitButton'
import {useNavigate} from 'react-router-dom'
import { Field } from "formik";
import { useSubjects } from "@/api/subjects/useSubjects";
import { useCreateSubject } from "@/api/subjects/useCreateSubject";
import { queryClient } from "@/api/client";
import { set } from "date-fns";
import {Editable, editableAnatomy, useEditable} from "@ark-ui/react";
import {BiCheck, BiExit, BiX} from "react-icons/bi";
import Spinner from "@/components/Spinner";
//mport {useLogin} from '@/api/auth/login/useLogin'


export default function AddSubjectInlineForm() {

    const { mutateAsync, status } = useCreateSubject({
        onSuccess: async (data) => { 
          await queryClient.invalidateQueries({queryKey: ['subjects']})
          navigate(`/dashboard/subject/${data.id}`)
        },
      })

    const {data: subjectsData, status: subjectsStatus} = useSubjects();
    const subjectNames = subjectsStatus=='success'? subjectsData?.map((subject) => subject.name):[]
    const navigate = useNavigate()
    const editable = useEditable({
            onValueCommit: async (field) =>  {
                let curr = field.value
                while(subjectNames.indexOf(curr) > -1) {
                    curr += " nuevo";
                }
                await mutateAsync({name: curr})
                editable.clearValue()
            },
            submitMode: "enter",
            required: true
        });


    function getStatusIndicator(){
        if(status === "pending") return <Spinner size={20} color="#4AC886" bgColor="#d5d5d5"></Spinner>
        if(!editable.editing) return <IoAddSharp className="" size={20} />
        return null
    }

    function getStatusText(){
        if(status==="pending") return "Añadiendo asignatura..."
        return "Añadir asignatura"
    }

    return (
            <Editable.RootProvider value={editable}>
                <Editable.Area onClickCapture={() => editable.edit()} className={`text-left flex items-center gap-2 w-full bg-gray-100 hover:bg-white text-gray-500 py-2 px-3 box-border cursor-pointer border-4 border-black rounded-lg
                            ${editable.editing && "!bg-white"}`} onClick={(e) => e.stopPropagation()}>
                    {
                        getStatusIndicator()
                    }
                    <Editable.Input className="w-full shadow-none outline-none border-none"></Editable.Input>
                    <Editable.Preview className="font-semibold">
                        {
                            getStatusText()
                        }
                    </Editable.Preview>
                </Editable.Area>
                <Editable.Context>
                    {(editable) => (
                        <Editable.Control>
                            {editable.editing &&
                                <div className="flex mt-2 gap-2">
                                    <Editable.SubmitTrigger className="w-[30px] h-[30px] bg-gray-200 flex justify-center items-center rounded-md hover:bg-gray-300">
                                        <BiCheck></BiCheck>
                                    </Editable.SubmitTrigger>
                                    <Editable.CancelTrigger className="w-[30px] h-[30px] bg-gray-200 flex justify-center items-center rounded-md hover:bg-gray-300">
                                        <BiX></BiX>
                                    </Editable.CancelTrigger>
                                </div>
                            }
                        </Editable.Control>
                    )}
                </Editable.Context>
            </Editable.RootProvider>
    )
}