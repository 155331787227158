import {ReactNode} from 'react';
import {PricingPlan} from "@/data/pricingPlans";
import {FaCircleCheck} from "react-icons/fa6";
import {tv} from "tailwind-variants";

interface Props{
    plan: PricingPlan,
    isYourCurrentPlan?: boolean
    callToAction?: ReactNode;
}

function PricingCard({plan, isYourCurrentPlan, callToAction}: Props) {

    const cardStyle = tv({
        base: "bg-gray-100 rounded-xl text-left max-w-[300px]",
        variants: {
            isYourCurrentPlan : {
                true: "border-4 border-solid  border-brand_secondary-500 scale-125",
                false: " "
            }
        }
    })

    return (
        <div className={cardStyle({
            isYourCurrentPlan
        })}>
            {
                isYourCurrentPlan &&
                <div className="w-full bg-brand_secondary-500 p-1 box-border text-sm font-bold text-white">
                    TU PLAN ACTUAL
                </div>
            }
            <div className="p-6 ">
                <h1 className="font-semibold text-base text-gray-600">
                    {plan.name}
                </h1>
                <div className="flex gap-2 items-baseline">
                    <h2 className="font-semibold text-4xl text-gray-800">
                        {(plan.price/100).toFixed(2)} €
                    </h2>
                    <p className="text-gray-400 font-semibold">
                        {
                            plan.duration === 1 ? '/ Mes' :
                            `/ ${plan.duration} Meses`
                        }
                    </p>
                </div>
                {callToAction}
            </div>
            <div className="w-full h-[1px] bg-gray-300"></div>
            <div className="p-6 font-semibold flex flex-col gap-1">
                <p className="text-sm text-gray-400 font-normal">{plan.description}</p>
                <div className="mt-3 flex flex-col gap-3">
                {
                    plan.features.map(x => (
                        <div className="flex">
                            <FaCircleCheck className="w-[40px] min-w-[40px] text-brand_secondary-800"></FaCircleCheck>
                            <p className="text-sm font-base text-gray-500">
                                {x}
                            </p>
                        </div>
                    ))
                }
                </div>
            </div>

        </div>
    );
}

export default PricingCard;