import {useMutation, UseMutationOptions} from "@tanstack/react-query";
import {apiClient} from "@/api/client";
import {AxiosError} from "axios";

interface ModifyStatsRequestData {
    minEstudioHoy : number
}

interface ModifyStatsResponseData {
    message : string
}

export const modifyStatsRequest = async (modifyStatsData: ModifyStatsRequestData) => {
    try{
        const response = await apiClient.patch<ModifyStatsResponseData>(
            `/estadisticas/update_stats?minEstudioHoy=${modifyStatsData.minEstudioHoy}`
        );
        return response.data;
    }catch(error : any){
        throw error.response.data
    }
};

export const useModifyStats = (options: UseMutationOptions<ModifyStatsResponseData, AxiosError, ModifyStatsRequestData, null>) =>{
    return useMutation<ModifyStatsResponseData, AxiosError, ModifyStatsRequestData, null>({
        mutationFn: modifyStatsRequest,
        ...options
    })
}
