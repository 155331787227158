import {getUserData} from '@/api/user/useUser';
import {calculateTokens} from "@/utils/tokens";
import {readPages} from "@/utils/files";
import * as Yup from 'yup';

let currentTokens: number | null = null;

/**
 * Este método se encarga de que la llamada a la API para obtener los tokens de la subscripción
 * se haga una única vez, guardando el resultado en una variable global para el resto de comprobaciones.
 * @returns Si currentTokens es null devuelve los tokens de la subscripción, si no, devuelve el valor guardado.
 */
const fetchTokens = async () => {
    if (currentTokens === null) {
        const data = await getUserData();
        currentTokens = data?.tokens ?? 0;
    }
    return currentTokens;
};

export const addDocumentsValidationSchema = Yup.object({
    files: Yup.array()
        .min(1, 'Mínimo tienes que añadir un archivo')
        .required('Un archivo es requerido'),
});

export const pickDocumentsPagesValidationSchema = Yup.object({
    pages : Yup.mixed().required().test('Debes seleccionar al menos una página es requerida', 'Al menos una página es requerida',
        function () {
            const {pages} = this.parent;
            for (const key in pages) {
                if (pages[key].size !== 0) {
                    return true;
                }
            }
            return false;
        }
    ),
    files: Yup.mixed().required().test('Tienes que tener tokens suficientes', 'Tu subscripción es insuficiente, disminuye el número de páginas o mejora tu plan',
        async function () {
            const tokens = await fetchTokens();
            const {files, pages} = this.parent;
            const getTotalTokens = async () => {
                let totalTokens = 0;
                for (let i = 0; i < files.length; i++) {
                    const text = await readPages(files[i], pages[i]);
                    const tokenCount = await calculateTokens(text.join(' '));
                    totalTokens += tokenCount;
                }
                return totalTokens;
            };
            const tokensToPay = await getTotalTokens()
            return tokensToPay <= tokens;
        }
    ),
});
