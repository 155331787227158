import {useMutation, UseMutationOptions} from "@tanstack/react-query";
import {apiClient} from "@/api/client";
import {AxiosError} from "axios";

interface ForgetSubjectRequestData {
    subjectId : string
}

interface ForgetSubjectResponseData {
    message : string
}

export const forgetSubjectRequest = async (forgetSubjectData: ForgetSubjectRequestData) => {
    try{
        const response = await apiClient.patch<ForgetSubjectResponseData>(
            `/folder/forget?folderId=${forgetSubjectData.subjectId}`
        );
        return response.data;
    }catch(error : any){
        throw error.response.data
    }
};

export const useForgetSubject = (options: UseMutationOptions<ForgetSubjectResponseData, AxiosError, ForgetSubjectRequestData, null>) =>{
    return useMutation<ForgetSubjectResponseData, AxiosError, ForgetSubjectRequestData, null>({
        mutationFn: forgetSubjectRequest,
        ...options
    })
}
