import React from 'react'
import {useField} from 'formik'
import {tv} from "tailwind-variants";
import {InputOptions} from "@/components/forms/core/types";

interface SelectProps extends React.InputHTMLAttributes<HTMLSelectElement>, InputOptions {
    label: string
    options: { label: string; value: string }[]
}

const inputStyles = tv({
    base: 'transition-all ease-in-out duration-150 w-full box-border gap-3 rounded-lg flex items-center text-gray-400 p-4 py-1 text-xs border-transparent',
    variants: {
        size: {
            lg: '!h-10',
            md: '!h-8',
            sm: '!h-7',
            xs: '!h-6',
        },
        variant: {
            primary: 'bg-gray-100 border-solid focus-within:!border-brand_secondary-500 focus-within:text-brand_secondary-500 focus-within:!bg-white hover:bg-gray-200',
            secondary: 'bg-white border-solid focus-within:!border-brand_secondary-500 focus-within:text-brand_secondary-500 focus-within:!bg-gray-100 hover:bg-gray-200',
        },
    },
    defaultVariants: {
        size: 'lg',
        variant: 'primary',
    },
});

export default function FormSelect({ label, options, size="lg", variant="primary", ...props} : SelectProps) {
    const [field, meta] = useField(props.name ?? '')

    return (
        <div className='w-full flex flex-col !gap-2'>
            <label className='font-semibold text-gray-600 !text-sm'>
                {label}
            </label>
            <select {...field} {...props} className={inputStyles(size, variant)}>
                {options.map((option, index) => (
                    <option key={index} value={option.value}>{option.label}</option>
                ))}
            </select>
            {meta.touched && meta.error ? (
                <p className="!text-xs text-red-500">{meta.error}</p>
            ) : null}
        </div>
    )
}
