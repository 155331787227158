export function debounce<T extends (...args: any[]) => Promise<any>>(fn: T, delay: number): (...args: Parameters<T>) => Promise<ReturnType<T>> {
    let timeoutId: ReturnType<typeof setTimeout>;
    
    return (...args: Parameters<T>): Promise<ReturnType<T>> => {
      clearTimeout(timeoutId);
      return new Promise((resolve) => {
        timeoutId = setTimeout(() => resolve(fn(...args)), delay);
      }) as Promise<ReturnType<T>>;
    };
  }

import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { apiClient } from '@/api/client';
import { AxiosError } from 'axios';

// Generic fetch function
const fetchData = async <TResponse>(
    endpoint: string,
    filters: Record<string, unknown> = {}
): Promise<TResponse> => {
    const response = await apiClient.get<TResponse>(endpoint, {
        params: filters,
    });
    return response.data;
};

// Utility function for creating hooks
export const createQueryHook = <TResponse>(
    queryKey: string,
    endpoint: string
) => {
    return (
        filters: Record<string, unknown> = {},
        options?: UseQueryOptions<TResponse, AxiosError>
    ) => {
        return useQuery<TResponse, AxiosError>({
            queryKey: [queryKey, filters],
            queryFn: () => fetchData<TResponse>(endpoint, filters),
            ...options,
        });
    };
};
