import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { apiClient } from "@/api/client";
import { AxiosError } from "axios";

export interface UpdateUserRequestData {
    created_at: string;
    objetivo: string;
    nacimiento: string;
    correo: string;
    nombre: string;
    apellidos: string;
    usuario: string;
    ubicacion: string;
    tipo_estudios: string;
    titulacion: string;
    centro_estudios: string;
}

interface UpdateUserResponseData {
    message: string;
}

export const updateUserRequest = async (updateUserData: UpdateUserRequestData) => {
    try {
        const response = await apiClient.post<UpdateUserResponseData>(
            "/user/update",
            updateUserData,
            { withCredentials: true }
        );
        return response.data;
    } catch (error: any) {
        throw error.response.data;
    }
};

export const useUpdateUser = (
    options: UseMutationOptions<UpdateUserResponseData, AxiosError, UpdateUserRequestData, null>
) => {
    return useMutation<UpdateUserResponseData, AxiosError, UpdateUserRequestData, null>({
        mutationFn: updateUserRequest,
        ...options
    });
};
