import { useParams, useNavigate } from 'react-router-dom';
import CardEditor from "@/pages/App/pages/Library/pages/DetailedCard/pages/DetailedCardCreate/components/CardEditor";
import { EditorsProvider } from "@/context/EditorsContext";
import { BiArrowBack } from 'react-icons/bi';
import { useCard } from "@/api/cards/useCard";
import {useCreateCard} from "@/api/cards/useCreateCard";
import {useModifyCard} from "@/api/cards/useModifyCard";
import {queryClient} from "@/api/client";
import {useToast} from "@/components/ui/ToastContext";
import PageLayout from "@/components/layout/PageLayout";
import {Outlet} from "react-router";

const CardDetailed = () => {
  return (
    <>
			<EditorsProvider>
			  <Outlet></Outlet>
			</EditorsProvider>
    </>
  )
}

export default CardDetailed;
