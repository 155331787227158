import {useState, ReactNode} from 'react';
import {BiListCheck} from "react-icons/bi";
import Icon from '@/components/icons/Icon';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import AddTaskInlineForm from "@/pages/App/pages/Schedule/pages/Tasks/components/AddTaskInlineForm";
import { Exam } from '@/types/types';
import { PiExamFill } from 'react-icons/pi';

interface TaskViewProps{
    date: Date,
    empty?: boolean,
    numberOfTasks: number,
    children : ReactNode,
    exams?: Exam[]
}   

function TasksView({date, empty=false, numberOfTasks, children, exams} : TaskViewProps) {

    const [expanded, setExpanded] = useState(date.toDateString() === new Date().toDateString());

    return (
        <div className='p-3 bg-gray-100 rounded-xl text-left flex flex-col h-full box-border'>
            <div className="flex flex-row gap-2 items-center truncate overflow-hidden whitespace-nowrap ml-2">
                { expanded ? 
                    <Icon iconName='angleDown' size='13' onClick={() => setExpanded(false)} /> :
                    <Icon iconName='angleRight' size='13' onClick={() => setExpanded(true)} />
                }
                <h1 className="text-base whitespace-nowrap text-gray-700 m-0 font-medium py-2 ml-1">{format(date, 'EEEE, d MMMM yyyy', { locale: es })} {numberOfTasks>0 && "("+numberOfTasks +")"}</h1>
                {exams && exams.map((exam, index) => (
                    <div className="text-xl text-gray-700 bg-white p-2 font-semibold items-center flex rounded-lg">
                        <PiExamFill size={30} className="text-[--color-secondary] mr-1" />
                        <div className='truncate overflow-hidden whitespace-nowrap'>
                        <span className="mr-1">Examen de</span>{exam.name}
                        </div>
                    </div>
                    
                ))}
            </div>
            {expanded && ( empty ? 
                <div className="w-full flex justify-center items-center flex-col pt-4 h-full mb-8">
                    <div className="w-[60px] h-[60px] bg-gray-200 rounded-full flex items-center justify-center text-gray-500">
                        <BiListCheck size={40}></BiListCheck>
                    </div>
                    <div className="mt-3 text-lg font-semibold text-gray-700 mb-3">
                        No hay tareas previstas
                    </div>
                    <AddTaskInlineForm date={date}></AddTaskInlineForm>
                </div>
                :
                <div>
                    {children}
                    <AddTaskInlineForm date={date}></AddTaskInlineForm>
                </div>
            )}
        </div>
    );
}


export default TasksView;