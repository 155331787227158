import {useNavigate} from 'react-router-dom'
import Form from '@/components/forms/core/Form'
import FormPasswordInput from '@/components/forms/core/FormPasswordInput'
import FormSubmitButton from '@/components/forms/core/FormSubmitButton'
import {useUpdatePassword} from '@/api/auth/resetPassword/useUpdatePassword'
import {resetPasswordSchema} from '../schemas'

export default function ResertPasswordForm({accessToken, email} : {accessToken : string, email: string}) {
  const navigate = useNavigate()
  const {mutateAsync, error} = useUpdatePassword({
    onSuccess: () => navigate('/login'),
  })

  return (
    <Form
      validationSchema={resetPasswordSchema}
      fetchErrors={error?.error}
      initialValues={{password : '', confirmPassword : ''}}
      onSubmit={async (values) => await mutateAsync({
        accessToken: accessToken,
        password: values.password,
        email: email
      })}
      className='text-left flex flex-col gap-4'
    >
      <FormPasswordInput name='password' label='Nueva contraseña' />
      <FormPasswordInput name='confirmPassword' label='Repetir contraseña' />
      <FormSubmitButton className='w-full bg-brand_secondary-500 border-0 hover:bg-brand_secondary-800 font-semibold'>
        Confirmar
      </FormSubmitButton>
    </Form>
  )
}
