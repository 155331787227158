import {BiBold, BiCode, BiHide, BiItalic, BiStrikethrough, BiUnderline} from "react-icons/bi";
import {Editor} from "@tiptap/react";

export interface ToolkitButton{
    name: string,
    label: React.ReactNode,
    command: (editor: Editor) => boolean
}

export const baseToolkitButtons = [
    { name : "bold", label: <BiBold/>, command: (editor :Editor) => editor!.chain().focus().toggleBold().run() },
    { name : "italic", label: <BiItalic/>, command: (editor : Editor) => editor!.chain().focus().toggleItalic().run() },
    { name : "underline", label: <BiUnderline/>, command: (editor : Editor) => editor!.chain().focus().toggleUnderline().run() },
    {name: "strike", label: <BiStrikethrough/>, command: (editor : Editor) => editor!.chain().focus().toggleStrike().run() },
    { name: "code", label: <BiCode/>, command: (editor : Editor) => editor!.chain().focus().toggleCode().run() },
]

export const allToolkitButtons : ToolkitButton[] = [
    ...baseToolkitButtons,
    { name: "ocultar", label: <BiHide/>, command: (editor : Editor) => editor!.chain().focus().toggleOcultar().run() },
]

