import {HTMLProps, useEffect} from 'react'
import { useEditors } from '@/context/EditorsContext'
import { useEditor, EditorContent } from '@tiptap/react'
import Text from '@tiptap/extension-text'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import Underline from '@tiptap/extension-underline'
import Strike from '@tiptap/extension-strike'
import Code from '@tiptap/extension-code'
import {useField} from "formik";
import Toolkit from "./Toolkit";
import Ocultar from "./ocultar";
import {ToolkitButton} from "./utils";

const extensions = [Text, Document, Paragraph, Bold, Italic, Underline, Strike, Code, Ocultar]

interface props extends  HTMLProps<HTMLDivElement>{
    name: string,
    label?: string,
    editorClass?: string,
    toolkit: ToolkitButton[]
}

export default function FormTipTapEditor({ name, label, className, editorClass, toolkit, ...other }: props) {

    const [field, meta, helpers] = useField(name)
    const { addEditor, setSelectedEditorId } = useEditors()

    const editor = useEditor({
        extensions: extensions,
        autofocus: true,
        editorProps: {
            attributes: {
                class: 'text-sm w-full group/tiptap h-full max-h-[250px] !rounded-t-xl !rounded-b-none  bg-white !border-[1px] border-solid focus:!border-brand_secondary-500 ' + editorClass,
            },
        },
        content: field.value,
        onUpdate: ({ editor }) => {
            helpers.setValue(editor.getHTML())
        },
    })

    useEffect(() => {
        if (editor) addEditor(name, editor)
        return () => editor?.destroy()
    }, [editor])


    return (
        <div className={`w-full flex flex-col gap-2  items-stretch ${className}`} {...other}>
            <label className="font-semibold text-gray-500 text-sm">{label}</label>
            <div className="w-full h-full">
                <EditorContent editor={editor} />
                <Toolkit editor={editor} toolkit={toolkit}></Toolkit>
            </div>
        </div>
    )
}
