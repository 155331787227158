import FormikEditorEditable from "@/pages/App/pages/Library/pages/DetailedCard/pages/DetailedCardCreate/components/forms/fields/FormikEditorEditable";
import {baseToolkitButtons} from "@/components/forms/TipTapEditorForm/utils";
import {useField} from "formik";
import {BiCheck} from "react-icons/bi";


interface props{
    name : string,
    correctAnswerName : string
}

function FormMultipleAnswerField({name, correctAnswerName} : props) {

    const [field, meta, helpers] = useField(name)
    const [answerField, , answerHelpers] = useField(correctAnswerName)

    return (
        <div className="w-full">

            {
            field.value.map((x, i: number) => (
                <div className="relative bg-gray-100 hover:bg-gray-200 rounded-xl p-3 py-2 flex items-center gap-2">
                    <div className="flex">
                        <div className="font-semibold flex items-center gap-2">
                            <div
                                onClick={() => answerHelpers.setValue(i)}
                                className={`h-[25px] text-white cursor-pointer
                                  w-[25px] rounded-lg top-0 right-0 flex justify-center
                                  items-center border-1 border-solid  border-transparent
                                   ${answerField.value == i ? 'bg-brand_secondary-500 ' : ' !border-gray-300'}`}>
                                {
                                    answerField.value == i ?
                                        <BiCheck></BiCheck>
                                        :
                                        null
                                }
                            </div>
                        </div>
                    </div>
                    <FormikEditorEditable
                        key={i}
                        toolkit={baseToolkitButtons}
                        editorClass="h-[30px]"
                        name={`respuestas.${i}`}
                        label={`Respuesta ${i + 1}`}
                        placeholder="Añade una respuesta"/>

                    {
                        /*
                    <div
                        onClick={() => {
                            const arr = field.value
                            helpers.setValue(arr.slice(0, i).concat(arr.slice(i+1, arr.length)))
                        }}
                        className="min-h-[35px] text-gray-600 cursor-pointer min-w-[35px] rounded-lg top-0 right-0 flex justify-center items-center bg-gray-100 hover:bg-gray-200">
                        <BiTrash></BiTrash>
                    </div>

                         */
                    }
                </div>
            ))
            }
            {
                /*

            <div
                className="mt-2 w-fit px-3 py-2 text-sm hover:bg-gray-300 bg-gray-200 cursor-pointer font-semibold rounded-lg"
                onClick={() => helpers.setValue([...field.value, ""])}>
                Añadir respuesta
            </div>
                 */
            }
        </div>
    );
}

export default FormMultipleAnswerField;