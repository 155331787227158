import React, {useState} from 'react';
import {setIn, useField} from "formik";
import {Simulate} from "react-dom/test-utils";
import input = Simulate.input;

export interface IconContext{
    changeInputType: (f : (state : string) =>  string) => void
    currentInputType : string;
}

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    name: string
}

export default function Input({ ...props} : InputProps) {
    console.log(props.name)
    const [field, meta] = useField(props.name);

    return (
        <input {...field} {...props} />
    )
}
