import { useRef, useEffect } from 'react';
import * as pdfjsLib from 'pdfjs-dist';
import Spinner from '@/components/ui/Spinner';
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.mjs`;

interface FilePageViewProps {
    fileUrl: string;
    pageNumber?: number;
}

export default function FilePageView({ fileUrl, pageNumber = 1 }: FilePageViewProps) {
    const canvasRef = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        const renderPDF = async () => {
            const canvas = canvasRef.current;
            if (canvas) {
                const context = canvas.getContext('2d');
                if (!context) return;

                const pdfDocument = await pdfjsLib.getDocument(fileUrl).promise;
                const page = await pdfDocument.getPage(pageNumber);

                const viewport = page.getViewport({ scale: 0.2 });
                canvas.width = viewport.width;
                canvas.height = viewport.height;

                await page.render({
                    canvasContext: context,
                    viewport: viewport,
                }).promise;
            }
        };
        if (fileUrl) renderPDF();
    }, [fileUrl, pageNumber]);

    return <canvas ref={canvasRef}>
        {fileUrl ? null : <Spinner />}
    </canvas>;
};