import React from 'react';
import {Field} from "formik";
import Form from "@/components/forms/core/Form";
import {Editable, UseEditableReturn} from '@ark-ui/react';
import {useModifySubject} from "@/api/subjects/useModifySubject";
import {queryClient} from "@/api/client";

interface Props{
    name: string,
    value: UseEditableReturn
}

function SubjectEditInlineForm(props: Props) {
    return (
            <Editable.RootProvider value={props.value} >
                <Editable.Area className="text-left">
                    <Editable.Input></Editable.Input>
                    <Editable.Preview className="font-semibold">
                        {props.name}
                    </Editable.Preview>
                </Editable.Area>
            </Editable.RootProvider>
    );
}

export default SubjectEditInlineForm;