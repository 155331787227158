import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { apiClient } from "@/api/client";
import { AxiosError } from "axios";

// Cancel Subscription
export const cancelSubscriptionRequest = async () => {
    const response = await apiClient.put('/pay/cancel_subscription', {}, { withCredentials: true });
    if (response.status !== 200) throw new Error('Error al cancelar la subscripcion');
    return response.data;
};

export const useCancelSubscription = (options: UseMutationOptions<any, AxiosError, void, void>) => {
    return useMutation<any, AxiosError, void, void>({
        mutationFn: cancelSubscriptionRequest,
        ...options
    });
};