import {useState} from "react";
import {useNavigate} from "react-router-dom";
import EntityMenu from "@/pages/App/pages/Library/components/EntityMenu";
import {BiEdit, BiTrash} from "react-icons/bi";
import {TbDots} from "react-icons/tb";
import {Card} from "@/types/db/card";
import {getCardPreview} from "@/utils/cardUtils";
import DeleteCardDialog from './DeleteCardDialog';

interface Props {
    card: Card;
}

function QuestionPreviewCard({card}: Props) {
    const navigate = useNavigate()
    const [dialog, setDialog] = useState<boolean>(false)

    return (<>
        <div 
            className="w-full bg-gray-100 p-5 box-border min-h-[150px] h-full rounded-xl text-gray-700"
        >
            <div className="w-full flex items-end justify-end">
                <EntityMenu items={[
                    {
                        title: "Editar",
                        icon: <BiEdit />,
                        callback: () => navigate(`card/${card.id}`)
                    },
                    {
                        title: "Eliminar",
                        trash: true,
                        icon: <BiTrash />,
                        callback: () => setDialog(true)
                    },
                ]}>
                    <div className={`h-[25px]  w-[25px] bg-gray-200 hover:bg-gray-300 flex justify-center items-center rounded-full `}>
                        <TbDots></TbDots>
                    </div>
                </EntityMenu>
            </div>
            <div className="w-full items-center text-center justify-center flex my-3 !text-gray-600 font-semibold">
                <p dangerouslySetInnerHTML={{__html: getCardPreview(card) || ""}}></p>
            </div>
        </div>
        <DeleteCardDialog open={dialog} onClose={() => setDialog(false)} cardId={card.id} cardType={card.type} />
    </>);
}

export default QuestionPreviewCard;