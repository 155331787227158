import React, { useState } from 'react';
import { useField } from 'formik';
import { tv } from 'tailwind-variants';
import {InputOptions} from "@/components/forms/core/types";

export interface IconContext {
    changeInputType: (f: (state: string) => string) => void;
    currentInputType: string;
}

interface InputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>,'size'>, InputOptions {
    label?: string;
    datalist?: React.ReactNode;
    leftIcon?: (context: IconContext) => React.ReactNode;
    rightIcon?: (context: IconContext) => React.ReactNode;
}

const inputStyles = tv({
    base: 'transition-all ease-in-out duration-150 w-full box-border gap-3 rounded-lg flex items-center text-gray-400 p-4 py-1 text-xs border-transparent',
    variants: {
        size: {
            lg: '!h-10',
            md: '!h-8',
            sm: '!h-7',
            xs: '!h-6',
        },
        variant: {
            primary: 'bg-gray-100 border-solid focus-within:!border-brand_secondary-500 focus-within:text-brand_secondary-500 focus-within:!bg-white hover:bg-gray-200',
            secondary: 'bg-white border-solid focus-within:!border-brand_secondary-500 focus-within:text-brand_secondary-500 focus-within:!bg-gray-100 hover:bg-gray-200',
        },
    },
    defaultVariants: {
        size: 'lg',
        variant: 'primary',
    },
});

const FormInput: React.FC<InputProps> = ({
    label,
    leftIcon,
    rightIcon,
    datalist,
    size = 'lg',
    variant = 'primary',
    ...props
}) => {
    const [field, meta] = useField(props.name ?? '');
    const [inputType, setInputType] = useState<string>(props.type as string);

    const inputContext: IconContext = {
        changeInputType: (f: (state: string) => string) => setInputType(f),
        currentInputType: inputType,
    };

    return (
        <div className="flex flex-col !gap-2">
            {label && <label className="font-semibold text-gray-600 !text-sm !text-left">
                {label}
            </label>}
            <div
                className={`
                    ${inputStyles({ size, variant })}
                `}
            >
                {leftIcon ? leftIcon(inputContext) : null}
                <input
                    {...field}
                    {...props}
                    className={`w-full placeholder:text-gray-400 border-0 bg-transparent outline-none`}
                    type={inputType}
                />
                {rightIcon ? rightIcon(inputContext) : null}
            </div>
            {datalist}
            {meta.touched && meta.error ? (
                <p className="!text-xs text-red-500 !m-0">{meta.error}</p>
            ) : null}
        </div>
    );
};

export default FormInput;
