import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {apiClient} from '@/api/client'
import {AxiosResponse} from 'axios'

interface DeleteDeckRequestData {
    deckId: string
}

interface DeleteDeckResponseData {
    message : string
}

interface DeleteDeckErrorData{
    error: string
}

export const deleteDeckRequest = async (deleteDeckData: DeleteDeckRequestData) => {
    try{
        const response = await apiClient.delete<DeleteDeckResponseData, AxiosResponse<DeleteDeckResponseData, DeleteDeckErrorData>>(
            `/mazo/delete?mazoId=${deleteDeckData.deckId}`
        )
        return response.data
    } catch(error : any){
        throw error.response.data
    }
}

export const useDeleteDeck = (options: UseMutationOptions<DeleteDeckResponseData, DeleteDeckErrorData, DeleteDeckRequestData, null>) =>{
    return useMutation<DeleteDeckResponseData, DeleteDeckErrorData, DeleteDeckRequestData, null>({
        mutationFn: deleteDeckRequest,
        ...options
    })
}
