import { useRegisterWithGoogle } from '@/api/auth/register/useRegisterWithGoogle'
import AuthProviderButton from '@/pages/Auth/components/AuthProviderButton'

export default function GoogleAuthButton({children}: {children: React.ReactNode}) {
    // Esto parece que no solo sirve para register si no para todo en general asi que darle una vuelta
    const {mutateAsync} = useRegisterWithGoogle({
        onSuccess: (data) => {
            if (data) window.location.href = data.url
        }
    })

    return (
        <AuthProviderButton provider='google' onClick={() => mutateAsync(null)}>
            {children}
        </AuthProviderButton>
	)
}