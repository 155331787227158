import { Dialog as ArkDialog } from '@ark-ui/react';
import Dialog, { DialogProps } from '@/components/ui/Dialog';
import Spinner from '@/components/Spinner';

export interface ConfirmationDialogProps extends DialogProps {
  open?: boolean;
  onClose?: () => void;
  title: string;
  description: string;
  confirmText?: string;
  confirmStyle?: 'green' | 'red';
  cancelText?: string;
  isPending?: boolean;
  onConfirm?: () => void;
}

export default function ConfirmationDialog({
  open,
  onClose,
  title,
  description,
  confirmText='Confirmar',
  confirmStyle='red',
  cancelText='Cerrar',
  isPending,
  onConfirm,
  ...props
}: ConfirmationDialogProps) {
  const colorBtClassName = confirmStyle === 'green' ? 'bg-green-500 hover:bg-green-700' : 'bg-red-500 hover:bg-red-700'; 

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={title}
      description={description}
      closeIcon={false}
      className='flex flex-col w-full max-w-[400px] px-12 h-40 justify-between'
      {...props}
    >
      <div className='flex flex-row gap-3 justify-center'>
        <ArkDialog.CloseTrigger
          className='w-32 h-10 bg-gray-100 hover:bg-gray-200 px-5 py-2 rounded-full font-semibold text-gray-700'
          onClick={onClose}
        >
          {cancelText}
        </ArkDialog.CloseTrigger>
        <button
          className={`flex justify-center items-center w-32 h-10 ${colorBtClassName} text-white hover:text-white px-5 py-2 rounded-full font-semibold`}
          onClick={onConfirm}
        >
          {isPending ? <Spinner size={20} color='rgb(239 68 68)' /> : confirmText}
        </button>
      </div>
    </Dialog>
  );
};
