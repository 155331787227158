import { useEffect, useState } from 'react'
import {useNavigate} from "react-router-dom";
import {TbClockHour4} from "react-icons/tb";
import {usePomodoro} from "@/context/ClockContext";

export default function HeaderClock() {
  const navigate = useNavigate();
  const {timeLeft} = usePomodoro()

  const formatTime = (seconds: number): string => {
    const mins = Math.floor(seconds / 60);
    const secs = (seconds % 60).toFixed(0);
    return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  return (
    <div 
      onClick={() => navigate(`schedule/pomodoro`)}
      >
    <button className="flex gap-2 p-1 px-4 bg-brand_primary-700 border-0 hover:bg-brand_primary-800 rounded-xl text-white cursor-pointer">
        <TbClockHour4  size='22' className="text-brand_secondary-500"/>
        <h4 className="text-base font-semibold">{formatTime(timeLeft)}</h4>
    </button>
    </div>
  )
}