import { Editor } from '@tiptap/react'
import { useEffect, useState } from 'react'
import {CardType} from "@/types/db/card";
import {BiBold, BiCode, BiItalic, BiStrikethrough, BiUnderline} from "react-icons/bi";
import {ToolkitButton, allToolkitButtons} from "@/components/forms/core/FormTipTapEditor/utils";

interface ToolkitProps {
  editor: Editor | null,
  toolkit : ToolkitButton[]
}

export default function Toolkit({ editor, toolkit }: ToolkitProps) {
  if(editor == null) return <></>
  return (
    <div className='flex flex-row gap-3 bg-white w-full rounded-b-xl p-2 box-border border-brand_secondary-500 border-[1px] border-t-0 border-solid'>
      {toolkit.map(({name, label, command }, index) => (
        <button
          key={index}
          className={`w-9 h-9 flex justify-center hover:bg-gray-100 items-center rounded-lg p-0 text-gray-600 ${editor.isActive(name) ? '!text-brand_secondary-500 bg-gray-100' : ''}`}
          onClick={() => command(editor)}
        >
          {label}
        </button>
      ))}
    </div>
  )
}
