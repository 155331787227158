import { apiClient } from '@/api/client';

export const existUserWithEmail = async (email: string): Promise<boolean> => {
	try {
	  const response = await apiClient.get(`/user/exists_email`, {
		params: { email }
	  });
	  
	  return response.data.exists === true;
	} catch (error) {
	  return false;
	}
};

export const existUserWithUsername = async (username: string): Promise<boolean> => {
try {
	const response = await apiClient.get(`/user/exists_username`, {
	params: { username },
	});
	
	return response.data.exists === true;
} catch (error) {
	return false;
}
};