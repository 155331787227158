import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {apiClient} from '@/api/client'
import {AxiosResponse} from 'axios'

interface RegisterResponseData {
    url : string
}

interface RegisterErrorData{
    error: string
}

export const registerRequest = async () => {
    try{
        const response = await apiClient.get<RegisterResponseData, AxiosResponse<RegisterResponseData, RegisterErrorData>>(
            '/auth/register_with_google'
        )
        return response.data
    } catch(error : any){
        throw error.response.data
    }
}

export const useRegisterWithGoogle = (options: UseMutationOptions<RegisterResponseData, RegisterErrorData, null, null>) =>{
    return useMutation<RegisterResponseData, RegisterErrorData, null, null>({
        mutationFn: registerRequest,
        ...options
    })
}
