import StudyComponent from "./components/Study";
import PageLayout from "@/components/layout/PageLayout";
import {useStudy} from "@/context/StudyContext";

export default function Study() {
    const {data} = useStudy();

    if (data) return (
        <PageLayout>
            <StudyComponent
                cartas={data.cartas}
                modo={data.modo}
                examen={data.examen}
                time={data.time}
                onClickReturn={data.onClickReturn}
            />
        </PageLayout>
    )
}
