import React from 'react';
import {BiFolder} from "react-icons/bi";
import {IoFolder} from "react-icons/io5";
import Image from '../../../../../../assets/empty_subject.png'

function NoSubjectSelected() {
    return (
        <div className="w-full justify-center flex mt-32">
            <div className="flex justify-center flex-col items-center text-center">
                <div
                    className="w-[180px] text-gray-500 h-[180px] bg-gray-100 rounded-full flex justify-center items-center text-7xl">
                    <img width={320} src={Image}/>
                </div>
                <h1 className="text-xl font-semibold text-gray-600 mb-0 mt-10">
                    Selecciona una asignatura
                </h1>
                <h1 className="text-base font-medium text-gray-400 mt-0 max-w-[500px] mt-2 mb-4">
                    Si no tienes asignaturas aún crea una nueva
                </h1>
            </div>
        </div>
    );
}

export default NoSubjectSelected;