import { useRef } from 'react';
import {BiChevronLeft, BiChevronRight, BiLeftArrow, BiRightArrow} from 'react-icons/bi';

interface HorizontalScrollProps {
  children: React.ReactNode;
  scrollAmount?: number;
}

export default function HorizontalScroll({ scrollAmount=300, children }: HorizontalScrollProps) {
  const ref = useRef<HTMLDivElement>(null);

  const scroll = (direction: 'left' | 'right') => {
    if (ref.current) {
      const scrollOptions: ScrollToOptions = {
        left: direction === 'left' ? ref.current.scrollLeft - scrollAmount : ref.current.scrollLeft + scrollAmount,
        behavior: 'smooth'
      };
      ref.current.scrollTo(scrollOptions);
    }
  };

  return (
    <div className="w-full relative">
      <button 
        className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-100 hover:bg-gray-200 w-[45px] h-[45px] flex justify-center items-center rounded-full"
        onClick={() => scroll('left')}
      >
        <BiChevronLeft />
      </button>
      <div 
        className="mx-16 flex space-x-4 no-scrollbar overflow-hidden"
        ref={ref}
      >
        {children}
      </div>
      <button
        className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-100 hover:bg-gray-200 w-[45px] h-[45px] flex justify-center items-center rounded-full"
        onClick={() => scroll('right')}
      >
        <BiChevronRight />
      </button>
    </div>
  );
};
