export type PricingPlan = {
  id: string | null;
  name: string;
  price: number;
  duration: number;
  description: string;
  features: string[];
}

export const pricingPlans: PricingPlan[] = [
  {
    id: null,
    name: "Gratuita",
    price: 0,
    duration: 1,
    description: "Explora nuestras herramientas básicas sin costo alguno.",
    features: [
      "Estudio mediante nuestro algoritmo de repetición espaciada",
      "Creación de flashcards y preguntas tipo test manuales",
      "Prueba inicial con IA de ≈125 páginas *",
      "Organización con lista de tareas y pomodoro",
    ]
  },
  {
    id: '5041be6a-bdfc-4280-9b59-fe8d9d064fa0',
    name: "Estandar",
    price: 499,
    duration: 1,
    description: "Explora todo lo que necesitas a un precio asequible.",
    features: [
      "Estudio mediante nuestro algoritmo de repetición espaciada",
      "Creación de flashcards y preguntas test con IA, para hasta 500 páginas *",
      "Exámenes con planificación automática del estudio",
      "Organización con lista de tareas y pomodoro",
    ]
  },
  {
    id: '6cd4ee35-e1d1-4929-81bb-425479d6baab',
    name: "Cuatrimestral",
    price: 1999,
    duration: 5,
    description: "La solución definitiva para los estudiantes más exigentes.",
    features: [
      "Estudio mediante nuestro algoritmo de repetición espaciada",
      "Creación de flashcards y preguntas test con IA, para hasta 3000 páginas *",
      "Exámenes con planificación automática del estudio",
      "Organización con lista de tareas y pomodoro",
    ]
  },
  {
    id: '94c94384-e222-4f5f-8e34-e7d7fe86011d',
    name: "Opositor",
    price: 2999,
    duration: 12,
    description: "El plan ideal para planificar y alcanzar objetivos a largo plazo",
    features: [
      "Estudio mediante nuestro algoritmo de repetición espaciada",
      "Creación de flashcards y preguntas test con IA, para hasta 4000 páginas *",
      "Exámenes con planificación automática del estudio",
      "Organización con lista de tareas y pomodoro",
    ]
  }
];