import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { apiClient } from '@/api/client';
import { AxiosError } from 'axios';
import { Task } from '@/types/db/task';

export type UserTasksResponse = Task[]; 

export const getUserTasks = async (): Promise<UserTasksResponse> => {
    const response = await apiClient.get<UserTasksResponse>('/task/get_from_user_id');
    return response.data;
};

export const useUserTasks = (options?: UseQueryOptions<UserTasksResponse, AxiosError>) => {
    return useQuery<UserTasksResponse, AxiosError>({
        queryKey: ['user_tasks'],
        queryFn: getUserTasks,
        ...options,
    });
};
