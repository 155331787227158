import { useState } from 'react'
import { Exam, Mazo } from '@/types/types'
import '../styles/TemarioExamen.css'
import { DragDropContext, Droppable, Draggable, DropResult, DragStart } from 'react-beautiful-dnd'
import Icon from './icons/Icon'
import Button from './ui/Button'


interface TemarioExamenOrdenProps {
    mazos: Mazo[]
    exit: () => void
    salirRefrescar: () => void
    nombreExamen: string
    fechaExamen: Date
    examUpdate: Exam | null 
}

export default function TemarioExamenOrden({ mazos, exit, salirRefrescar, nombreExamen, fechaExamen, examUpdate }: TemarioExamenOrdenProps) {
    const [mazosOrdenados, setMazosOrdenados] = useState<Mazo[]>(mazos)
    const [dragging, setDragging] = useState(false)
    const [actualizando, setActualizando] = useState(false)
    const [draggedItem, setDraggedItem] = useState<Mazo | null>(null); // Estado para el clon
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 }); // Estado para la posición del ratón
  

    const onDragStart = (start: { source: { index: number } }) => {
        setDragging(true);
        const item = mazosOrdenados[start.source.index];
        setDraggedItem(item);
    
        const handleMouseMove = (event: { clientX: any; clientY: any }) => {
          setMousePosition({ x: event.clientX, y: event.clientY });
        };
    
        // Agregar el listener de movimiento del ratón
        window.addEventListener('mousemove', handleMouseMove);
    
        // Remover listeners cuando el mouse se suelta
        const handleMouseUp = () => {
          window.removeEventListener('mousemove', handleMouseMove);
          setDragging(false);
        };
    
        window.addEventListener('mouseup', handleMouseUp, { once: true });
      };
    
      // Función para finalizar el arrastre y reordenar los elementos
      const onDragEnd = (result: DropResult) => {
        setDragging(false);
        setDraggedItem(null);
    
        if (!result.destination) return;
    
        const reorderedItems = Array.from(mazosOrdenados);
        const [removed] = reorderedItems.splice(result.source.index, 1);
        reorderedItems.splice(result.destination.index, 0, removed);
        setMazosOrdenados(reorderedItems);
      };

    const crearExamen = async () => {

        try {
            setActualizando(true)
            if(examUpdate){
                  const localDate = new Date(fechaExamen)
                  const timezoneOffset = localDate.getTimezoneOffset();
                  const utcDate = new Date(localDate.getTime() + timezoneOffset * 60000)
                  const year = utcDate.getUTCFullYear()
                  const month = String(utcDate.getUTCMonth() + 1).padStart(2, '0')
                  const day = String(utcDate.getUTCDate()).padStart(2, '0')
                  const formattedDate = `${year}-${month}-${day}`
                  
                const response = await fetch(  
                    `${import.meta.env.VITE_API_URL}/exam/edit_exam?examId=${examUpdate.id}&fecha_fin=${formattedDate}&name=${nombreExamen}&id_mazos=${mazosOrdenados.map(mazo => mazo.id)}&id_mazos_all=${mazosOrdenados.map(mazo => mazo.id)}`,
                    {
                        method: 'PATCH',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        credentials: 'include'
                    })
                if(!response.ok){
                    console.log(response)
                    throw new Error('Error en editar el examen');
                }
            } else{

                const response = await fetch(  
                    `${import.meta.env.VITE_API_URL}/exam/create`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({name: nombreExamen, fecha_fin: fechaExamen, id_mazos: mazosOrdenados.map(mazo => mazo.id)}),
                            credentials: 'include'
                    })
                if(!response.ok){
                    throw new Error('Error en agregar nuevo examen'); 
                }
            }
            const response = await fetch(
                `${import.meta.env.VITE_API_URL}/exam/sortExam`,  
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', },
                    body: JSON.stringify({}),
                    credentials: 'include'
              }
            )
            if (!response.ok){
                throw new Error('Error al actualizar los examenes');
            }		
            //setActualizando(false)
            salirRefrescar()
        } catch (error) {
            console.error('Error:', error);
            return
        }
    }


    return (
        <>
            <div style={{ textAlign: 'center', marginBottom:'20px' }}>
                <div style={{ fontWeight: '500', fontSize: '22px', padding:'0 28px'}}>Pincha y arrastra los temas</div>
                <div>El orden que selecciones es vital para planificar tu estudio</div>
            </div>
            <div style={{ textAlign: 'center', marginBottom:'5px' }}>
                Estudiar antes
                <Icon iconName='angleUp'/>
            </div>
            <div
        className="scrollable-list"
        style={{ width: '90%', height: '35vh', overflowY: 'auto', position: 'relative', padding: '0px 20px' }}
      >
        <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {mazosOrdenados.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="user-select-none p-[10px] m-[0_auto_8px_0] bg-[var(--color-background)] text-[var(--color-text)]  w-[90%] sm:w-[70%] rounded-[10px] whitespace-nowrap overflow-hidden text-ellipsis"
                        style={{
                          ...provided.draggableProps.style,
                          cursor: dragging ? 'grabbing' : 'grab',
                          visibility: snapshot.isDragging ? 'hidden' : 'visible', // Ocultar el original mientras se arrastra
                        }}
                      >
                        {item.name}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
            <div style={{ textAlign: 'center', marginTop:'5px' }}>
              <Icon iconName='angleDown'/>Estudiar después
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'flex-start', marginBottom: '10px', marginTop:'20px' }}>
              <Button className='bg-delete-color hover:bg-error-color' onClick={exit}>
                  Cancelar
              </Button>
              <Button disabled={actualizando} onClick={() => {crearExamen()}}>
                {!examUpdate? (!actualizando?'Crear Examen':'Creando...') : (!actualizando?'Editar Examen':'Editando...')}
              </Button>
            </div>
        </>
    )
}
