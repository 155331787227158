import Spinner from '@/components/ui/Spinner';
import Button from '@/components/ui/Button';
import { RegisterStatus } from '../types';
import {IoMdClose} from 'react-icons/io';

interface ResultProps {
  status : RegisterStatus,
  goBack : () => void,
} 

export default function RegisterResult({status, goBack} : ResultProps) {
	return (
    <div className='h-full flex flex-col items-center justify-center gap-20'>
      { status === 'pending' && <Spinner size='50px' />}
      { status === 'error' &&
        <>
          <div className='flex flex-col items-center justify-center '>
            <div
                className="w-[150px] h-[150px] bg-red-500 flex justify-center items-center text-white rounded-full">
              <IoMdClose size={80}></IoMdClose>
            </div>
            <h1 className='text-red-500 font-bold m-0 mb-4 text-4xl mt-12'>Algo ha salido mal</h1>
            <h3 className='text-xl text-gray-500'>
              Vuelve a intentarlo o contacta con soporte.
            </h3>
          </div>
          <Button onClick={goBack} className='w-44 bg-gray-200 hover:bg-gray-300 !text-gray-400'>
            Volver
          </Button>
        </>
      }
    </div>
  )
}
