import {useMutation, UseMutationOptions} from "@tanstack/react-query";
import {apiClient} from "@/api/client";
import {AxiosError} from "axios";

// Esta llamada a la API reordena todos los examenes (TODO: hacer que solo reordene un examen especifico)
export const sortExamRequest = async () => {
    try{
        const response = await apiClient.post('exam/sortExam', {});
        return response.data;
    }catch(error : any){
        throw error.response.data
    }
};

export const useSortExams = (options: UseMutationOptions<null, AxiosError, null, null>) =>{
    return useMutation<null, AxiosError, null, null>({
        mutationFn: sortExamRequest,
        ...options
    })
}
