import Icon from '@/components/icons/Icon'
import { useNavigate } from 'react-router-dom'
import Button from './ui/Button'

export default function LockedContentMessage() {

  const navigate = useNavigate()

  return (
    <div className='p-10'>
      <Icon iconName='lock' size='80' className='text-[var(--color-secondary)] pb-3' isClickable={false} />
      <h2>Contenido Premium</h2>
      <h3>Debes mejorar tu plan para acceder a este contenido</h3>
      <div className='flex flex-row justify-center gap-3 mt-5'>
          <Button 
              className='w-[100px] hover:bg-brand_primary-600'
              onClick={() => navigate('/pricing')}
          >
              Mejorar
          </Button>
          <Button 
              className='w-[100px] hover:bg-brand_primary-600 bg-medium-color'
              onClick={() => window.history.back()}
          >
              Volver
          </Button>
      </div>
    </div>
  )
}