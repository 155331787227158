const ODivider = ({className}: {className?: string}) => {
  return (
    <div className={`flex items-center w-full my-5 ${className}`}>
      <div className='flex-grow bg-gray-200 w-full h-[1px]' />
      <span className='mx-4 text-gray-400'>o</span>
      <div className='flex-grow bg-gray-200 w-full h-[1px]' />
    </div>
  )
}

export default ODivider;