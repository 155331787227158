import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {apiClient} from '@/api/client'
import {AxiosResponse} from 'axios'
import {CardInit} from '@/types/db/card'

export type CreateCardRequestData = CardInit

interface CreateCardResponseData {
    message : string
}

interface CreateCardErrorData{
    error: string
}

export const createCardRequest = async (createCardData: CreateCardRequestData) => {
    try{

        const response = await apiClient.post<CreateCardResponseData, AxiosResponse<CreateCardResponseData, CreateCardErrorData>>(
            '/card/create', {perteneceAExamen: 'no', ...createCardData} // Cambiar perteneceAExamen
        )
        return response.data
    } catch(error : any){
        throw error.response.data
    }
}

export const useCreateCard = (options: UseMutationOptions<CreateCardResponseData, CreateCardErrorData, CreateCardRequestData, null>) =>{
    return useMutation<CreateCardResponseData, CreateCardErrorData, CreateCardRequestData, null>({
        mutationFn: createCardRequest,
        ...options
    })
}
