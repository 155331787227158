import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { apiClient } from '@/api/client';
import { AxiosError } from 'axios';

interface UserResponseData {
    usuario: string;
    nombre: string;
    apellidos: string;
    correo: string;
    centro_estudios: string;
    tipo_estudios: string;
    titulacion: string;
    ubicacion: string;
    dias_descanso: boolean[];
    objetivo: string;
    nacimiento: string;
    created_at: string;
    tokens: number;
}

export const getUserData = async (): Promise<UserResponseData> => {
    const response = await apiClient.get<UserResponseData>('/user/data');
    return response.data;
};

export const useUser = (options?: UseQueryOptions<UserResponseData, AxiosError>) => {
    return useQuery<UserResponseData, AxiosError>({
        queryKey: ['user'],
        queryFn: getUserData,
        ...options,
    });
};
