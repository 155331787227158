import React from 'react';
import {useNavigate} from "react-router-dom";

interface Option{
    name: string,
    url : string,
    pathname: string
}


interface Props{
    options: Option[]
}

function TabNavigation({options}: Props) {

    const navigate = useNavigate()

    return (
        <div className="py-4">
            <div className="text-lg text-gray-700 w-full grid grid-cols-2 md:grid-cols-5 lg:grid-cols-8 ">
                {
                    options.map((x) => (
                        <div key={x.name} className={`border-none bg-none cursor-pointer group transition-colors duration-500  pt-2 rounded-t-xl`} onClick={() => navigate(x.url)}>

                            <a
                                className={`border-none bg-none cursor-pointer transition-colors group-hover:text-brand_secondary-500  ${location.pathname.includes(x.pathname) ? ' !text-brand_secondary-500 font-semibold' : ' '}`}
                            >
                                {x.name}
                            </a>
                            <div className={`mt-2 w-full bg-gray-100 h-[2px]${location.pathname.includes(x.pathname) ? ' !bg-brand_secondary-500' : ' '} `}/>
                        </div>
                    ))
                }
            </div>
            <div className={`w-full bg-gray-100 h-[2px] mt-[-2px]`}/>
        </div>
    );
}

export default TabNavigation;