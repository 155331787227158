import { useState } from 'react'
import Dialog from "@/components/ui/Dialog";
import FormInput from "@/components/forms/core/FormInput";
import Form from "@/components/forms/core/Form";
import FormSubmitButton from "@/components/forms/core/FormSubmitButton";
import {useParams} from "react-router-dom";
import {useCreateDeck} from "@/api/decks/useCreateDeck";
import {queryClient} from "@/api/client";
import {useDeck} from "@/api/decks/useDeck";
import {Deck} from "@/types/db/deck";
import {useModifyDeck} from "@/api/decks/useModifyDeck";

interface props {
    deck: Deck,
    open: boolean,
    onClose: () => void
}

export const UpdateDeckDialog = ({open, onClose, deck} : props) => {
    const { subject_id } = useParams<{ subject_id: string }>();
    const {mutateAsync} = useModifyDeck({
        onSuccess:  async () => {
             await queryClient.invalidateQueries({
                queryKey: ['decks', subject_id],
            })
            onClose();
        }
    })

    return (
        <Dialog open={open} onClose={() => onClose()} title={"Editar mazo"} className="max-w-[500px] w-full mx-3">
            <Form onSubmit={async (values) => {
                await mutateAsync(values)
            }} initialValues={{
                    newName: deck.name,
                    deckId : deck.id
                }}>
                <div className="mt-8">
                    <FormInput size="md" name="newName" label={"Nombre:"} placeholder="Nombre para tu nuevo mazo"></FormInput>
                </div>
                <div className="flex items-center justify-center gap-3 mt-12">
                    <button onClick={() => onClose()} className="px-7 bg-gray-100 py-2 text-gray-500 font-semibold rounded-full">Cancelar</button>
                    <FormSubmitButton className="px-7 bg-brand_secondary-500 py-2 text-white font-semibold rounded-full">Actualizar</FormSubmitButton>
                </div>
            </Form>
        </Dialog>
    )
}
