import {ReactNode, HTMLAttributes} from 'react';

export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  children?: ReactNode;
  className?: string;
  type?: 'submit' | 'reset' | 'button'
  disabled?: boolean;
}

const Button = ({ children, className, type, disabled, ...props }: ButtonProps) => {
  return (
    <button 
      type={type}
      disabled={disabled}
      className={`
        ${disabled ? 
          '!bg-gray-200 !text-gray-400 !cursor-default !hover:bg-gray-500' 
        :`
          bg-brand_secondary-500
          hover:bg-brand_secondary-700
          cursor-pointer
        `}
        ${className}
        text-white 
        px-3 py-2
        rounded-xl
        font-semibold
        transition-colors
      `}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
