import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import { queryClient } from '@/api/client'
import {apiClient} from '@/api/client'
import {AxiosResponse} from 'axios'
import {CardType} from '@/types/db/card'

interface ImportWithAIRequestData {
    mazoId: string,
    content: string, 
    cardType: CardType
}

interface ImportWithAIResponseData {
    message : string
}

interface ImportWithAIErrorData{
    error: string
}

export const importWithAIRequest = async (importData: ImportWithAIRequestData) => {
    try{
        const response = await apiClient.post<ImportWithAIRequestData, AxiosResponse<ImportWithAIResponseData, ImportWithAIErrorData>>(
            '/import/ai', importData
        )
        return response.data
    } catch(error : any){
        throw error.response.data
    }
}

export const useImportWithAI = (options: UseMutationOptions<ImportWithAIResponseData, ImportWithAIErrorData, ImportWithAIRequestData, null>) =>{
    return useMutation<ImportWithAIResponseData, ImportWithAIErrorData, ImportWithAIRequestData, null>({
        mutationFn: importWithAIRequest,
        ...options
    })
}
