import React from 'react';
import {BiError} from "react-icons/bi";

interface props{
    goBack: () => void;
}

function UnexpectedError({goBack} : props) {
    return (
        <div className="w-full flex flex-col justify-center items-center py-16">
            <div className="w-[150px] h-[150px] flex justify-center items-center bg-red-500 rounded-full text-white text-6xl">
                <BiError></BiError>
            </div>
            <h1 className="text-xl">Ha ocurrido un error inesperado</h1>
            <h2 className="text-lg font-normal text-gray-600">Intente intentarlo de nuevo o contacte con el soporte</h2>
            <button onClick={goBack} className="py-2 px-4 bg-gray-100 rounded-xl font-semibold text-gray-500 mt-5 cursor-pointer hover:bg-gray-200">
                Volver atrás
            </button>
        </div>
    );
}

export default UnexpectedError;