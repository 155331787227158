import { useState } from 'react'
import FormSubmitButton from '@/components/forms/core/FormSubmitButton'
import Form from '@/components/forms/core/Form'
import FormBackButton from '../../../../../../UserSetup/components/FormBackButton'
import { Step } from '@/pages/App/pages/UserSetup/types'
import FormStepper from '../../../../../../UserSetup/components/FormStepper'
import { FormikValues } from "formik";


interface Props<T> {
    steps : Step[],
    initialValues: T & FormikValues,
    onSubmit: (values: T & FormikValues) => void,
    status : "pending" | "success" | "error" | "idle"
}

export default function CreateFlashcardAiForm<T>({steps, status, initialValues, onSubmit} : Props<T>) {
  const [currentStep, setCurrentStep] = useState<number>(0)
  const step = steps[currentStep]


  const handleBack =() => {
    if(currentStep < 1) return;
    setCurrentStep((step) => step-1)
  }

  const handleSubmit = async (values: any, helpers : any) => {
      if(currentStep >= steps.length ) return;
      if(step.goNextStepWhenSubmit && step.submitForm) setCurrentStep((step) => step+1)
      if(step.submitForm) await onSubmit(values)
      setCurrentStep((step) => step+1)
      helpers.setSubmitting(false)
  }

	return (
    <>
      <Form
        initialValues={initialValues}
        validationSchema={step.validationSchema}
        onSubmit={handleSubmit}
        className='text-left flex flex-col'
      >
        <div className="flex items-center w-full  justify-center">
          <div className="max-w-[500px] w-full">
            <FormStepper currentStep={currentStep} totalSteps={steps.length}></FormStepper>
          </div>
        </div>
        {
          step.component({
            status,
            goBack: handleBack
          })
        }
        {
          step.hideControls ??
          <div className="flex justify-center flex-col items-center gap-4">
            <div className='gap-4  flex'>
              { currentStep > 0 && 
                <FormBackButton className='py-3  font-semibold bg-gray-100 rounded-xl hover:!bg-gray-200 text-sm px-10' onBack={handleBack} />
              }
              <FormSubmitButton className='text-sm !px-10'>
                  {
                      step.submitForm ? "Generar" : "Siguiente"
                  }
              </FormSubmitButton>
            </div>
          </div>
        }
      </Form>
    </>
  )
}
