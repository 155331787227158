import React from 'react';
import {usePomodoro} from "@/context/ClockContext";
import {tv} from "tailwind-variants";
import {BiPause, BiPlay, BiReset, BiSkipNext } from "react-icons/bi";

function PomodoroControls() {
    const {toggleRunning, reset, skip, isRunning} = usePomodoro()
    const roundedButton = tv({
        base: "min-h-[55px] cursor-pointer min-w-[55px] text-4xl  rounded-full bg-brand_secondary-500 " +
            " hover:bg-brand_secondary-700 text-white flex justify-center items-center"
    })

    return (
        <div className="flex gap-3 w-full justify-center">
            <div className={roundedButton()} onClick={() => reset()}>
                <BiReset></BiReset>
            </div>
            <div className={roundedButton()} onClick={() => toggleRunning()}>
                {
                    isRunning ?
                        <BiPause></BiPause>
                        :
                        <BiPlay></BiPlay>
                }

            </div>
            <div className={roundedButton()} onClick={() => skip()}>
                <BiSkipNext></BiSkipNext>
            </div>
        </div>
    );
}

export default PomodoroControls;