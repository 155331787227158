import { HTMLAttributes } from 'react'

interface TextareaProps extends HTMLAttributes<HTMLTextAreaElement> {
	className?: string;
}

export default function Textarea({ className, ...props}: TextareaProps) {

	return (
		<div 
			className={`
				transition-all ease-in-out duration-150
				w-full  box-border gap-3
				bg-gray-100
				border-solid
				focus-within:!border-brand_secondary-500 border-transparent 
				focus-within:text-brand_secondary-500 focus-within:!bg-white
				rounded-lg hover:bg-gray-200 flex items-center text-gray-400
				p-3 text-xs 
				h-20
				${className}
			`}
		>
			<textarea 
				className={`
					w-full
					h-full
					bg-transparent
					border-none
					text-text-color
					resize-none
					outline-none
				`}
				{...props}
			/>
		</div>
	  );
}
