import { useEffect, useState } from "react";
import Spinner from "@/components/Spinner";
import {BiCross, BiError, BiWindowClose} from "react-icons/bi";
import UnexpectedError
    from "@/pages/App/pages/Library/pages/DetailedCard/pages/DetailedCardCreateAi/components/form/CardGenerationLoadingForm/UnexpectedError";
import GenerationInProcess
    from "@/pages/App/pages/Library/pages/DetailedCard/pages/DetailedCardCreateAi/components/form/CardGenerationLoadingForm/GenerationInProcess";


interface CardGenerationLoadingFormProps {
    goBack: () => void;
    status : "pending" | "success" | "error" | "idle"
}

function CardGenerationLoadingForm({goBack, status}: CardGenerationLoadingFormProps) {
    if(status === "pending") return <GenerationInProcess></GenerationInProcess>
    if(status === "error") return <UnexpectedError goBack={goBack}></UnexpectedError>
}

export default CardGenerationLoadingForm;