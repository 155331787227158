import {useSubscription} from './useSubscription';
import {pricingPlans, PricingPlan} from '@/data/pricingPlans';

export const usePricingPlan = (): {
  data: PricingPlan | undefined, 
  status: 'success' | 'error' | 'pending'
} => {
  const {data: subData, status} = useSubscription();
  if (status === 'success') {
    const planId = subData?.plan_id ?? null;
    return { 
      data: pricingPlans.find(plan => plan.id === planId),
      status: 'success'
    };
  }
  else return {data: undefined, status}
}
