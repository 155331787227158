import Button, {ButtonProps} from '@/components/ui/Button'

interface FormBackButtonProps extends ButtonProps {
  onBack: () => void
}

export default function FormBackButton({ onBack, ...props } : FormBackButtonProps) {
  return (
    <Button onClick={() => onBack()} {...props}>
      Volver
    </Button>
  )
}