import { Link, useLocation } from 'react-router-dom'
import { useState, useEffect } from 'react'
import {BiCross} from "react-icons/bi";
import {IoMdClose} from "react-icons/io";

export default function MobileNavbar({onClose}: {onClose: () => void}) {

  const location = useLocation()
  const [currentPath, setCurrentPath] = useState('')

  useEffect(() => {
		setCurrentPath(location.pathname)
	}, [location.pathname])

  const routes = [
    { path: '/home', label: 'Inicio' },
    { path: '/dashboard', label: 'Biblioteca' },
    { path: '/examenes', label: 'Exámenes' },
    { path: '/schedule', label: 'Organización' },
  ]

  return (
    <nav aria-label='Main Navigation gap-4 fixed'
         className="flex flex-col items-center justify-center gap-5 h-[100vh]
          w-full z-50 bg-brand_primary-700 box-border
          text-2xl
           "
    >
      <IoMdClose className="fixed top-0 left-0 p-5 text-white cursor-pointer" onClick={onClose} size={26} />
      {routes.map((route) => (
        <Link
          onClick={() => {onClose()}}
          className={currentPath.startsWith(route.path) ? 'font-semibold text-white' : ''}
          key={route.path}
          to={route.path}
        >
          {route.label}
        </Link>
      ))}
    </nav>
  )

}
