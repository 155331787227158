import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { apiClient } from '@/api/client';
import { Card } from '@/types/db/card';

type CardsResponseData = Card[];

export const getCardsData = async (deckId: string): Promise<CardsResponseData> => {
    const response = await apiClient.get<CardsResponseData>(`/card/get_from_mazo?mazoId=${deckId}`);
    return response.data;
};

export const useCards = (deckId: string, options?: UseQueryOptions<CardsResponseData, AxiosError>) => {
    return useQuery<CardsResponseData, AxiosError>({
        queryKey: ['cards', deckId],
        queryFn: () => getCardsData(deckId),
        ...options,
    });
};