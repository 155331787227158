import React from 'react';
import {BlockLayout} from "@/pages/App/pages/Settings/components/BlockLayout";
import {Formik} from "formik";
import FormSubmitButton from "@/components/forms/core/FormSubmitButton";
import {useDeleteAccount} from "@/api/user/useDeleteUser";
import {useLogout} from "@/api/auth/logout/useLogout";

function DeleteUserForm(props) {

    const {mutateAsync : logOut} = useLogout({})
    const {mutateAsync} = useDeleteAccount({
        onSuccess: async () => {
            await logOut()
        }
    });

    return (
        <BlockLayout title="Otros" className="flex justify-left">
            <Formik initialValues={{current_password: "", confirm_password: "", password: ""}}
                    onSubmit={async (values) => {
                        await mutateAsync()
                    }}
            >
                {
                    () => (
                        <FormSubmitButton
                            className="bg-red-500 hover:bg-red-600"
                            confirmationDialog={{
                                title: "¿Estas seguro que quieres eliminar tu usuario?",
                                description: "Si lo eliminas perderás todos los examenes, tarjetas y asignaturas creadas."
                            }}
                            dialog
                        >
                            Eliminar cuenta
                        </FormSubmitButton>
                    )
                }
            </Formik>
        </BlockLayout>
    );
}

export default DeleteUserForm;