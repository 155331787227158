import React from 'react';

interface Props{
    time: number
}

function Clock({time}: Props) {

    const formatTime = (seconds: number): string => {
        const mins = Math.floor(seconds / 60);
        const secs = (seconds % 60).toFixed(0);
        return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    };

    return (
        <div>
            <div className="w-full py-8 md:py-10 lg:py-12 bg-gray-100 mx-auto rounded-2xl flex justify-center items-center flex-col">
                <p className="text-gray-400 font-semibold py-2">Focus timer</p>
                <h1 className="text-4xl md:text-6xl lg:text-8xl font-bold m-0">
                    {formatTime(time)}
                </h1>
            </div>
        </div>
    );
}

export default Clock;