import { useNavigate, useParams } from "react-router-dom";
import {useCard} from "@/api/cards/useCard";
import {useToast} from "@/components/ui/ToastContext";
import {queryClient} from "@/api/client";
import {useModifyCard} from "@/api/cards/useModifyCard";
import CardEditor from "@/pages/App/pages/Library/pages/DetailedCard/pages/DetailedCardCreate/components/CardEditor";
import PageLayout from "@/components/layout/PageLayout";

function DeailedCardModify() {
    const navigate = useNavigate();
    const {card_id: cardId, deck_id} = useParams();
    const {data: card, status} = useCard(cardId ?? '');
    const {createToast} = useToast();

    const {mutateAsync} = useModifyCard({
        onSuccess: async () => {
            createToast({
                title: 'Carta Actualizada',
                description: 'La carta se ha actualizado correctamente',
                type: 'success'
            })
            await queryClient.refetchQueries({queryKey: ['cards', deck_id]}).then(() => {
                navigate(-1)
            })
        },
        onError: () => {
            createToast({
                title: 'Error',
                description: 'Ha ocurrido un error al intentar modificar la carta',
                type: 'error'
            })
        }
    });

    if(status === "pending") return <div>loading...</div>

    return (
        <PageLayout title="Editar carta" isDetailed>
            <CardEditor initialValues={card} onSubmit={async (val) => mutateAsync(val)}></CardEditor>
        </PageLayout>
    );
}

export default DeailedCardModify;