import {useMutation, UseMutationOptions} from "@tanstack/react-query";
import {apiClient} from "@/api/client";
import {AxiosError} from "axios";

interface DeleteSubjectRequestData {
    subjectId : string
}

interface DeleteSubjectResponseData {
    message : string
}

export const deleteSubjectRequest = async (deleteSubjectData: DeleteSubjectRequestData) => {
    try{
        const response = await apiClient.delete<DeleteSubjectResponseData>(
            `/folder/delete?folderId=${deleteSubjectData.subjectId}`
        );
        return response.data;
    }catch(error : any){
        throw error.response.data
    }
};

export const useDeleteSubject = (options: UseMutationOptions<DeleteSubjectResponseData, AxiosError, DeleteSubjectRequestData, null>) =>{
    return useMutation<DeleteSubjectResponseData, AxiosError, DeleteSubjectRequestData, null>({
        mutationFn: deleteSubjectRequest,
        ...options,
    })
}