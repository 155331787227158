import Form from '@/components/forms/core/Form'
import FormInput from '@/components/forms/core/FormInput'
import FormSubmitButton from '@/components/forms/core/FormSubmitButton'
import {useSendRecoveryEmail} from '@/api/auth/resetPassword/useSendRecoveryEmail'
import {forgetPasswordSchema} from '../schemas'

export default function ForgetPasswordForm({onSuccess}: {onSuccess: (email: string) => void}) {
  
  const {mutateAsync, error} = useSendRecoveryEmail({ 
    onSuccess: (data) => onSuccess(data.email) 
  })

  return (
    <Form
      validationSchema={forgetPasswordSchema}
      fetchErrors={error?.error}
      initialValues={{email: ''}}
      onSubmit={async (values) => { 
        await mutateAsync({email: values.email }) 
      }}
      className='text-left flex flex-col gap-4'
    >
      <FormInput name='email' label='Correo Electrónico' />
      <FormSubmitButton className='w-full bg-brand_secondary-500 border-0 hover:bg-brand_secondary-800 font-semibold'>
        Enviar
      </FormSubmitButton>
    </Form>
  )
}