import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { apiClient } from '@/api/client';
import { Deck } from '@/types/db/deck';

//type DecksResponseData = Deck[];

type DecksResponseData ={
    Array: any[],
    modoEstudio: number,
    numEstudiar: number,
    id: string,
    name: string,
    asignatura_id: string,
}[]

export const getDecksData = async (subjectId: string): Promise<DecksResponseData> => {
    const responseFolders = await apiClient.get<Deck[]>(`/mazo/get_from_folder?folderId=${subjectId}`);
    //AQUI SE HICIERON CRIMENES, HABRA QUE REFACTORIZAR BACKEND
    //PIRULO SERA JUZGADO POR SUS PECADOS
    let response = [];
    for (let i = 0; i < responseFolders.data.length; i++) {
        let responseDeck = await apiClient.get(`/card/get_for_study?mazoId=${responseFolders.data[i].id}`);
        responseDeck.data['id'] = responseFolders.data[i].id;
        responseDeck.data['name'] = responseFolders.data[i].name;
        responseDeck.data['asignatura_id'] = responseFolders.data[i].asignatura_id;
        response.push(responseDeck.data);
    }//AQUI TERMINA EL CRIMEN
    return response;
};

export const useDecks = (subjectId: string, options?: UseQueryOptions<DecksResponseData, AxiosError>) => {
    return useQuery<DecksResponseData, AxiosError>({
        queryKey: ['decks', subjectId],
        queryFn: () => getDecksData(subjectId),
        ...options,
    });
};