import CardEditor from "@/pages/App/pages/Library/pages/DetailedCard/pages/DetailedCardCreate/components/CardEditor";
import PageLayout from "@/components/layout/PageLayout";
import {CreateCardRequestData, useCreateCard} from "@/api/cards/useCreateCard";
import {useParams} from "react-router-dom";
import {useToast} from "@/components/ui/ToastContext";
import {queryClient} from "@/api/client";

function DetailedCardCreate() {
    const {createToast} = useToast();
    const {deck_id, subject_id} = useParams();
    

    const {mutateAsync } = useCreateCard({
        onSuccess: async () => {
            await queryClient.refetchQueries({queryKey: ['cards', deck_id]})
            await queryClient.refetchQueries({queryKey: ['decks', subject_id]})
            await queryClient.refetchQueries({queryKey: ['deck', deck_id]})
            createToast({
                title: 'Carta Creada',
                description: 'La carta se ha creado correctamente',
                type: 'success'
            })
        },
        onError: () => {
            createToast({
                title: 'Error',
                description: 'Ha ocurrido un error al intentar modificar la carta',
                type: 'error'
            })
        }
    });

    return (
        <PageLayout title="Crear carta" isDetailed>
            <CardEditor onSubmit={
                async (val) => mutateAsync({mazo_id: deck_id, ...val} as CreateCardRequestData)
            } />
        </PageLayout>
    );
}

export default DetailedCardCreate;