import Form from '@/components/forms/core/Form'
import FormInput from '@/components/forms/core/FormInput'
import FormSubmitButton from '@/components/forms/core/FormSubmitButton'
import FormPasswordInput from '@/components/forms/core/FormPasswordInput'
import RegisterFailed from './RegisterFailed'
import RegisterSuccess from './RegisterSuccess'
import {useRegisterWithMail} from '@/api/auth/register/useRegisterWithMail'
import {MdLock, MdMail} from 'react-icons/md'
import {registerSchema} from '../schema'

export default function RegisterForm({onSubmit}: {onSubmit: () => void}) {
    const { mutateAsync, status } = useRegisterWithMail({
        onSuccess: () => {
            localStorage.setItem('firstLogin', 'true')
        },
    })

	return (
        <Form
            className='text-left flex flex-col gap-4'
            initialValues={{email: '', password: ''}}
            validationSchema={registerSchema}
            onSubmit={(values) => {
                mutateAsync(values); 
                onSubmit()
            }}
        >
            { 
                status === 'error' ? <RegisterFailed /> :
                status === 'success' ? <RegisterSuccess /> :
                <>
                    <h2 className='text-lg font-semibold text-left'>
                        Registrate con tu correo
                    </h2>
                    <div className='flex flex-col gap-4'>
                        <FormInput
                            label="email"
                            leftIcon={() => <MdMail size={22}/>}
                            name='email' placeholder="Email..."
                        />
                        <FormPasswordInput
                            placeholder='Contraseña...'
                            leftIcon={() => <MdLock size={22}/>}
                            label='Contraseña' name='password' 
                        />
                        <FormPasswordInput
                            placeholder='Repetir contraseña...'
                            leftIcon={() => <MdLock size={22}/>}
                            label='Repetir Contraseña' name='confirmPassword'
                        />
                    </div>
                    <div className="flex justify-center flex-col items-center gap-4">
                        <FormSubmitButton className='w-full'>
                            Continuar
                        </FormSubmitButton>
                    </div>
                </>
            }
        </Form>
    )
}
