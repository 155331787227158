import Button, {ButtonProps} from "@/components/ui/Button";
import Spinner from "@/components/Spinner";
import {useFormikContext} from "formik";
import ConfirmationDialog, {ConfirmationDialogProps} from "@/components/ConfirmationDialog";
import {useState} from "react";

interface SubmitButtonProps extends ButtonProps {
    disabled?: boolean
    confirmationDialog?: {
        title: string,
        description : string
    },
    dialog?: boolean
}

function FormSubmitButton({disabled, confirmationDialog, ...props} : SubmitButtonProps) {
    const {submitForm, isSubmitting, errors} = useFormikContext();
    const hasErrors = errors && Object.keys(errors).length > 0;
    const [openDialog, setOpenDialog] = useState(false);

    return (
        <>
            <input type="submit" hidden/>
            <Button 
                onClick={() => {
                    if(!confirmationDialog) {
                        submitForm()
                        return
                    }
                    setOpenDialog(true)
                }}
                type="submit"
                disabled={hasErrors || isSubmitting}
                {...props}
            >
                <span className='flex w-full justify-center items-center gap-2'>
                    { isSubmitting && !hasErrors &&
                        <Spinner size={20} color="white" bgColor="rgba(1, 1, 1, 0.2)"></Spinner>
                    }
                    {props.children}
                </span>
            </Button>
            {
                confirmationDialog && openDialog && (
                    <ConfirmationDialog
                        {...confirmationDialog}
                        open={openDialog}
                        onClose={() => setOpenDialog(false)}
                        onConfirm={submitForm}
                        isPending={isSubmitting}
                    >
                    </ConfirmationDialog>
                )
            }

        </>
    );
}

export default FormSubmitButton