import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import PageLayout from "@/components/layout/PageLayout";
import TabNavigation from "@/components/navigation/TabNavigation";

const options = [
    {
        name : "Tareas",
        url : "tasks",
        pathname: "/schedule/tasks"
    },
    {
        name : "Pomodoro",
        url : "pomodoro",
        pathname: "/schedule/pomodoro"
    }

]

export default function Organizacion() {
    return (
        <PageLayout title="Panel de Organización">
            <TabNavigation options={options}></TabNavigation>
            <Outlet></Outlet>
        </PageLayout>
    )
}
