import { Task, UserTask, ExamTask } from "@/types/tasks";
import UserTaskCard from "./UserTaskCard";
import ExamTaskCard from "./ExamTaskCard";

interface TaskCardProps {
    task: Task;
    isToday: boolean;
}

function TaskCard({ task, isToday }: TaskCardProps) {
    return (
        <div className="grid grid-cols-1 gap-2 group cursor-pointer">
            {task.type === "user" ?
                <UserTaskCard task={task as UserTask} /> :
                <ExamTaskCard task={task as ExamTask} isToday={isToday}/>
            }
        </div>
    );
}

export default TaskCard;