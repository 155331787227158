import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { apiClient } from '@/api/client';
import { AxiosError } from 'axios';

interface DashboardBarChartResponse {
    racha: number;
    minEstudioTot: number;
    minEstudioHoy: number[];
}

export const getDashboardBarChartData = async (): Promise<DashboardBarChartResponse> => {
    const response = await apiClient.get<DashboardBarChartResponse>('/estadisticas/get_stats');
    return response.data;
};

export const useDashboardBarChart = (options?: UseQueryOptions<DashboardBarChartResponse, AxiosError>) => {
    return useQuery<DashboardBarChartResponse, AxiosError>({
        queryKey: ['dashboardBarChart'],
        queryFn: getDashboardBarChartData,
        ...options,
    });
};
