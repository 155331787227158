import PageLayout from "@/components/layout/PageLayout";
import CreateFlashcardAiForm from "@/pages/App/pages/Library/pages/DetailedCard/pages/DetailedCardCreateAi/components/CreateFlashcardAiForm";
import {Step, StepProps} from "@/pages/App/pages/UserSetup/types";
import {addDocumentsValidationSchema, pickDocumentsPagesValidationSchema} from "./schemas";
import AddDocumentsForm from "@/pages/App/pages/Library/pages/DetailedCard/pages/DetailedCardCreateAi/components/form/AddDocumentsForm";
import PickDocumentsPagesForm from "@/pages/App/pages/Library/pages/DetailedCard/pages/DetailedCardCreateAi/components/form/PickDocumentsPagesForm";
import CardGenerationLoadingForm from "@/pages/App/pages/Library/pages/DetailedCard/pages/DetailedCardCreateAi/components/form/CardGenerationLoadingForm/CardGenerationLoadingForm";
import CardGenerationProcessDoneForm from "@/pages/App/pages/Library/pages/DetailedCard/pages/DetailedCardCreateAi/components/form/CardGenerationProcessDoneForm";
import PickCardTypeForm from "@/pages/App/pages/Library/pages/DetailedCard/pages/DetailedCardCreateAi/components/form/PickCardTypeForm";
import {useParams} from "react-router-dom";
import {useImportWithAI} from "@/api/import/ai/useImportWithAI";
import {CardType} from "@/types/types";
import {getUniqueFileContentString, readFile, readPages} from "@/utils/files";

const steps : Step[] = [
    {
        component: () => <AddDocumentsForm key={0}></AddDocumentsForm>,
        submitForm: false,
        validationSchema: addDocumentsValidationSchema
    },
    {
        component: () => <PickDocumentsPagesForm key={1}></PickDocumentsPagesForm>,
        submitForm: false,
        validationSchema: pickDocumentsPagesValidationSchema
    },
    {
        component: () => <PickCardTypeForm key={2}></PickCardTypeForm>,
        submitForm: true,
        goNextStepWhenSubmit: true
    },
    {
        component: (props) => <CardGenerationLoadingForm goBack={props.goBack} status={props.status} key={3} />,
        hideControls: true
    },
    {
        component: () => <CardGenerationProcessDoneForm  key={4}></CardGenerationProcessDoneForm>,
        hideControls: true
    }
]

function DetailedCardCreateAi() {
    const {deck_id} = useParams()
    const { mutateAsync, status, error } = useImportWithAI({})

    return (
        <PageLayout title="Crear cartas con IA" isDetailed>
            <div className="mt-10">
                <CreateFlashcardAiForm
                    status={status}
                    initialValues={{
                        files : [] as File[],
                        pages : {} as {[key : number] : Set<number>},
                        cardType : "flashcard" as CardType,
                        mazoId:  deck_id
                    }}
                    steps={steps}
                    onSubmit={async (values) => {
                        let content = ""
                        for (let i = 0; i < values.files.length; i++) {
                            const text = await readPages(values.files[i], values.pages[i]);
                            content += text.reduce((x, acc) => acc + x, "")
                        }
                        await mutateAsync({
                            content: content,
                            cardType: values.cardType,
                            mazoId: deck_id ?? ""
                        })
                    }}
                />
            </div>
        </PageLayout>
    );
}

export default DetailedCardCreateAi;