import React from 'react';
import Spinner from "@/components/Spinner";

function DefaultPendingComponent() {
    return (
        <div className="w-full">
            <div className="w-full justify-center items-center flex mt-10 mb-10">
                <Spinner size={50} bgColor="#EFEFEF" color="#4AC886"></Spinner>
            </div>
        </div>
)
    ;
}

export default DefaultPendingComponent;