import {createContext, useContext, useState, ReactNode} from 'react';
import { Exam } from '@/types/types';
import { Card } from '@/types/db/card';

interface StudyProps {
  cartas: Card[] | null
  modo: number | null
  examen?: Exam | null
  time: number | null
  onClickReturn: () => void
}

interface StudyContextType {
  data: StudyProps | undefined;
  setData: (data: StudyProps) => void;
}

const StudyContext = createContext<StudyContextType | undefined>(undefined);

interface PomodoroProviderProps {
  children: ReactNode;
}

export const StudyProvider = ({ children }: PomodoroProviderProps) => {
  const [data, setData] = useState<StudyProps | undefined>(undefined);

  return (
    <StudyContext.Provider value={{data, setData}}>
      {children}
    </StudyContext.Provider>
  );
};

export const useStudy = () => {
  const context = useContext(StudyContext);
  if (!context) throw new Error('usetasks hook is called outside the StudyProvider component');
  return context;
};

export default StudyContext;
