import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {apiClient} from '@/api/client'
import {AxiosResponse} from 'axios'

interface SendRecoveryEmailRequestData {
  email: string
}

interface SendRecoveryEmailResponseData {
  message : string
  email: string
}

interface SendRecoveryEmailErrorData {
  error: string
}

// SIN IMPLEMENTAR
export const sendRecoveryemailRequest = async (requestData: SendRecoveryEmailRequestData) => {
  try{
    const response = await apiClient.post<SendRecoveryEmailResponseData, AxiosResponse<SendRecoveryEmailResponseData, SendRecoveryEmailErrorData>>(
      '/auth/reset_password', requestData
    )
    return { ...response.data, email: requestData.email }
  } catch(error : any){
    throw error.response.data
  }
}

export const useSendRecoveryEmail = (options: UseMutationOptions<SendRecoveryEmailResponseData, SendRecoveryEmailErrorData, SendRecoveryEmailRequestData, null>) =>{
  return useMutation<SendRecoveryEmailResponseData, SendRecoveryEmailErrorData, SendRecoveryEmailRequestData, null>({
    mutationFn: sendRecoveryemailRequest,
    ...options
  })
}
