import {useMutation, UseMutationOptions} from "@tanstack/react-query";
import {queryClient} from "@/api/client";
import {apiClient} from "@/api/client";
import {AxiosResponse} from "axios";


interface LoginRequestData {
    token: string;
    expiresAt: string;
    expiresIn: string;
}

interface LoginResponseData {
    message : string
}

interface LoginErrorData{
    error: string
}

export const loginRequest = async (data: LoginRequestData) => {
    try{
        const response = await apiClient.post<LoginResponseData, AxiosResponse<LoginResponseData, LoginErrorData>>(
          '/auth/login_with_google', data
        );
        queryClient.resetQueries();
        return response.data;
    }catch(error : any){
        throw error.response.data
    }
};

export const useLoginWithGoogle = (options: UseMutationOptions<LoginResponseData, LoginErrorData, LoginRequestData, null>) =>{
    return useMutation<LoginResponseData, LoginErrorData, LoginRequestData, null>({
        mutationFn: loginRequest,
        ...options
    })
}