import React from 'react';

interface Props extends React.HTMLProps<HTMLDivElement>{
    title: string,
    children : React.ReactNode
}

export function BlockLayout({title, children, ...other}: Props){
    return (
        <div className="bg-gray-100 p-6 rounded-xl">
            <h2 className="text-left text-base mb-3">{title}</h2>
            <div {...other}>
                {children}
            </div>
        </div>
    )

}
