import FormInput from '@/components/forms/core/FormInput'
import FormPasswordInput from '@/components/forms/core/FormPasswordInput'
import FormSubmitButton from '@/components/forms/core/FormSubmitButton'
import {useNavigate} from 'react-router-dom'
import {useLogin} from '@/api/auth/login/useLogin'
import Form from '@/components/forms/core/Form'
import {loginSchema} from '@/pages/Auth/pages/Login/schemas'
import {MdLock, MdMail} from 'react-icons/md'

export default function LoginForm() {
    const navigate = useNavigate()
    const {mutateAsync, error} = useLogin({
        onSuccess: () => handleSuccess()
    })

    function handleSuccess() {
        //const firstLogin = localStorage.getItem('firstLogin');
        const qrCodeImport = sessionStorage.getItem('qrCodeImport');
        /*
        if (firstLogin === 'true') navigate('/tutorial')
        else if (sessionStorage.getItem('qrCodeImport')) navigate(`/codigos/${qrCodeImport}`)
        else navigate('/home')
        */
        if (sessionStorage.getItem('qrCodeImport')) navigate(`/codigos/${qrCodeImport}`)
        else navigate('/home')
    }

    return (
        <Form
            validationSchema={loginSchema}
            fetchErrors={error?.error}
            initialValues={{email : "", password : ""}}
            onSubmit={async (values) => { await mutateAsync({email: values.email, password: values.password})}}
            className="text-left flex flex-col gap-4 "
        >
            <FormInput
                size="lg"
                leftIcon={() => <MdMail size={22}/>}
                className='h-10'
                label='Correo / Nombre de usuario'
                name='email'
                placeholder='Tu correo o usuario'
            ></FormInput>
            <FormPasswordInput
                leftIcon={() => <MdLock size={22}/>}
                placeholder='Tu contraseña...'
                className='h-10'
                label='Contraseña'
                name='password'
            />
            <FormSubmitButton className='w-full'>Iniciar sesión</FormSubmitButton>
        </Form>
    )
}
