import { useEffect } from "react";
import Spinner from "@/components/ui/Spinner";
import { useLoginWithGoogle } from "@/api/auth/login/useLoginWithGoogle";

// TODO: mostrar error
// Hacer algo para saber si es registro o no
export default function LoadingGoogleAuth() {
    const {mutateAsync} = useLoginWithGoogle({
        onSuccess: () => {
            window.location.href = '/'
        },
        onError: (error) => {
            console.log(error)
        }
    })

    useEffect(() => {
        const hash = window.location.hash;
        const params = new URLSearchParams(hash.substring(1));
        const token = params.get('access_token');
        const expiresAt = params.get('expires_at');
        const expiresIn = params.get('expires_in');
        if (token && expiresAt && expiresIn)
            mutateAsync({token, expiresAt, expiresIn})
        else
            window.location.href = '/'
    }, [])

    return (
        <Spinner />
    )
}