import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {apiClient} from '@/api/client'
import {AxiosResponse} from 'axios'

interface UpdatePasswordRequestData {
  accessToken: string
  password: string
  email: string
}

interface UpdatePasswordResponseData {
  message : string
}

interface UpdatePasswordErrorData {
  error: string
}

// SIN IMPLEMENTAR
export const updatePasswordRequest = async (registerData: UpdatePasswordRequestData) => {
  try{
    const response = await apiClient.post<UpdatePasswordResponseData, AxiosResponse<UpdatePasswordResponseData, UpdatePasswordErrorData>>(
      '/auth/update_password_from_recovery', registerData
    )
    return response.data
  } catch(error : any){
    throw error.response.data
  }
}

export const useUpdatePassword = (options: UseMutationOptions<UpdatePasswordResponseData, UpdatePasswordErrorData, UpdatePasswordRequestData, null>) =>{
  return useMutation<UpdatePasswordResponseData, UpdatePasswordErrorData, UpdatePasswordRequestData, null>({
    mutationFn: updatePasswordRequest,
    ...options
  })
}
